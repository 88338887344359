/** @format */

import React from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { encrypt } from '../../services/Encriptaciones'
import { UUIDS } from '../../constants'
import AgendaCitas from '../consultas/AgendaCitas'

export default function Home() {
  const permisos = encrypt.getSession('modulos')
  return (
    <React.Fragment>
      <LayoutForm>
        {!permisos.includes(UUIDS.agendaCitas) && (
          <>
            <div className="card-header">Example Card</div>
            <div className="card-body">
              This is a blank page. You can use this page as a boilerplate for creating new pages!
              This page uses the compact page header format, which allows you to create pages with a
              very minimal and slim page header so you can get right to showcasing your content.
            </div>
          </>
        )}
        {permisos.includes(UUIDS.agendaCitas) && <AgendaCitas />}
      </LayoutForm>
    </React.Fragment>
  )
}
