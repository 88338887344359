import React, { useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Alert, Card, CardBody } from 'react-bootstrap'
import { Header } from '../../../components/Containers/Header'
import { Lista, Option } from './components'
import { activarModalGenerico, clearActiveComplication } from '../../../store/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { ACTIONS, modules, PERMISSIONS } from '../../../constants'
import { toastAdapter } from '../../../plugins'
import {
  useAddComplicationMutation,
  useUpdateComplicationMutation
} from '../../../services/rtk-query'
import { ModalComplicaciones } from './components'
import { yupResolver } from '@hookform/resolvers/yup'
import { genericFormSchema } from '../../../utils'
import { usePermissions } from '../../../hooks'
import { useForm } from 'react-hook-form'

export const ComplicacionesPage = () => {
  const { hasPermission } = usePermissions()
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const [addComplication] = useAddComplicationMutation()
  const [updateComplication] = useUpdateComplicationMutation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
    getValues
  } = useForm({
    resolver: yupResolver(genericFormSchema)
  })
  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id'
      },
      {
        header: 'nombre',
        accessorKey: 'nombre'
      },
      {
        header: 'Activo',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Alert
              variant="primary"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              Si
            </Alert>
          ) : (
            <Alert
              variant="danger"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              No
            </Alert>
          )
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => (
          <Option
            hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.complicaciones)}
            cell={original.id}
            setAction={setAction}
            activo={original.activo}
          />
        )
      }
    ],
    []
  )
  const handler = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    console.log(data)
    if (action === ACTIONS.SAVE) return handleSave(data)

    return handleUpdate(data)
  }

  const handleSave = (data) => {
    const { id, ...rest } = data
    toastAdapter.promise({
      promise: addComplication(rest).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)

        return 'Complicacion guardado con éxito'
      },
      errorMessage: 'Error al guardar la Complicacion',
      loadingMessage: 'Guardando Complicacion...'
    })
  }

  const handleUpdate = (data) => {
    toastAdapter.promise({
      promise: updateComplication({ ...data, id: +getValues('id') }).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)
        dispatch(clearActiveComplication())
        return 'Complicacion guardado con éxito'
      },
      errorMessage: 'Error al guardar la Complicacion',
      loadingMessage: 'Guardando Complicacion...'
    })
  }
  const handleReset = () => {
    reset()
    setAction(ACTIONS.SAVE)
    dispatch(clearActiveComplication())
  }

  return (
    <LayoutForm title="Complicaciones">
      <Card className="shadow-none">
        <Header
          title="Complicaciones"
          subtitle="  "
          buttonTitle="Nueva Complicacion"
          handler={handler}
          hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.complicaciones)}
        />
        <CardBody>
          <Lista columns={columns} />
        </CardBody>
      </Card>
      <ModalComplicaciones
        action={action}
        control={control}
        errors={errors}
        handlerAction={handlerAction}
        handleSubmit={handleSubmit}
        reset={handleReset}
        setValue={setValue}
        register={register}
      />
    </LayoutForm>
  )
}
