import React, { Fragment, useMemo } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'

import BontonesAcciones from '../../../components/botones/BontonesAcciones'
import ExportarYAgregar from '../../../components/botones/ExportarYAgregar'
import { TablePlugin } from '../../../plugins/components/TablePlugin'
import { alertConfirm } from '../../../plugins/sweetAlert.plugin'
import { ALERT_ICONS, modules, PERMISSIONS } from '../../../constants'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { usePermissions } from '../../../hooks'
import { useDeleteLocaleMutation, useGetSucursalesQuery } from '../../../services/rtk-query'
import { Badge } from 'react-bootstrap'

export default function Administracion() {
  const { hasPermission } = usePermissions()
  const { data, isFetching } = useGetSucursalesQuery(
    {},
    {
      refetchOnMountOrArgChange: true
    }
  )

  const [deleteLocale] = useDeleteLocaleMutation()

  //eliminar sucursal de la vista
  const handleDeleteSucursal = (data) => {
    try {
      alertConfirm({
        title: '¿Estas seguro?',
        text: 'Estas a punto de desactivar una sucursal',
        confirmButtonText: 'Si, Desactivar / Activar',
        cancelButtonText: 'Cancelar',
        icon: ALERT_ICONS.QUESTION
      }).then((res) => {
        if (res) {
          //hacemos la peticion
          toastAdapter
            .promise({
              loadingMessage: 'Desactivando/activando  sucursal',
              promise: deleteLocale({ id: data.id, activo: data.activo }).unwrap(),
              successMessage: 'Sucursal desactivada/activada',
              errorMessage: 'Error al desactivar/activar sucursal'
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    } catch (error) {
      toastAdapter.error({
        message: 'Algo salio mal'
      })
    }
  }

  //definimos columnas
  const columnDefs = useMemo(
    () => [
      { header: 'CÓDIGO', accessorKey: 'codigo' },
      { header: 'NOMBRE', accessorKey: 'nombre' },
      { header: 'TELEFONO', accessorKey: 'telefono' },
      {
        header: 'ESTADO',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Badge bg="success-soft" pill>
              {' '}
              Activo
            </Badge>
          ) : (
            <Badge bg="danger-soft" pill>
              {' '}
              Inactivo
            </Badge>
          )
      },
      {
        accessorKey: 'id',
        header: 'ACCIONES',
        cell: rankFormatter
      }
    ],
    []
  )

  function rankFormatter({ row: { original } }) {
    return (
      <BontonesAcciones
        ruta={`/empresas/sucursales/update/${original.id}`}
        borrar={() => handleDeleteSucursal(original)}
        buttonMessage="Activar/Desactivar"
        hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.sucursales)}
      />
    )
  }

  return (
    <Fragment>
      <LayoutForm title="Sucursales">
        <div className="card mb-4 shadow-none">
          <div className="card-header">Lista de Sucursales</div>
          <div className="card-body">
            <ExportarYAgregar
              hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.sucursales)}
              nombre={'Agregar nueva sucursal'}
              ruta={'/empresas/sucursales/nuevo'}
            />
            <TablePlugin data={data} columns={columnDefs} isFetching={isFetching} />
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
