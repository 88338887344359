import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { AsyncSelectCustom } from '../../../components/selects/AsyncSelect'
import { useActividadesEconomicasQuery } from '../../../services/rtk-query/clinicalApi'
import { filterItems, loadOptions } from '../../../utils/selects.utils'
import { FormControl } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

export const DatosEmpresas = ({ control, setValue, getValues }) => {
  const { id } = useParams()
  const [filtering, setFiltering] = useState('')
  const {
    data: actividadesEconomicas,
    isFetching,
    isSuccess
  } = useActividadesEconomicasQuery(filtering)

  useLayoutEffect(() => {
    if (id && isSuccess) {
      const actividadEconomicaId =
        getValues('actividadEconomica')?.value ?? getValues('actividadEconomica')
      const actividadEconomica = actividadesEconomicas.find((ae) => ae.id === actividadEconomicaId)

      if (actividadEconomica) {
        setValue('actividadEconomica', {
          value: actividadEconomica.id,
          label: actividadEconomica.descripcion,
          ...actividadEconomica
        })
      }
    }
  }, [actividadesEconomicas, getValues, id, isSuccess, setValue])

  const filterActividadesEconomicas = (inputValue) => {
    return filterItems({
      items:
        actividadesEconomicas?.map((actividadEconomica) => ({
          value: actividadEconomica.id,
          label: actividadEconomica.descripcion,
          ...actividadEconomica
        })) ?? [],
      inputValue,
      key: 'descripcion'
    })
  }

  const loadActividadesEconomicas = async (inputValue) => {
    setFiltering(inputValue)
    return loadOptions({
      isSuccess: isSuccess,
      filterFunction: filterActividadesEconomicas,
      inputValue
    })
  }

  return (
    <Fragment>
      <div className="row gx-3 mb-3">
        <div className="col-md-4 mt-2">
          <label htmlFor="nrc" className="small mb-1">
            NRC Empresas:
          </label>
          <Controller
            name="nrc"
            control={control}
            render={({ field }) => (
              <input
                type="number"
                className="form-control"
                id="nrc"
                placeholder="NRC Empresas..."
                {...field}
              />
            )}
          />
        </div>

        <div className="col-lg-4 mt-2">
          <label htmlFor="nit" className="small mb-1">
            NIT Empresa:
          </label>
          <Controller
            name="nit"
            control={control}
            render={({ field }) => (
              <input
                type="number"
                className="form-control"
                id="nit"
                placeholder="NIT Empresa..."
                {...field}
              />
            )}
          />
        </div>

        <div className="col-lg-4 mt-2">
          <label htmlFor="razon" className="small mb-1">
            Razón Social:
          </label>
          <Controller
            name="razonSocial"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="form-control"
                id="razon"
                placeholder="Razón Social..."
                {...field}
              />
            )}
          />
        </div>

        <div className="col-lg-4 mt-4">
          <label htmlFor="actividadEconomica" className="small mb-1">
            Actividades Económicas:
          </label>
          <Controller
            name="actividadEconomica"
            control={control}
            render={({ field }) => (
              <AsyncSelectCustom
                isClearable={true}
                {...field}
                loadOptions={loadActividadesEconomicas}
                isLoading={isFetching}
                defaultOptions={filterActividadesEconomicas('')}
              />
            )}
          />
        </div>

        <div className="col-lg-4 mt-4">
          <label htmlFor="patronoActual" className="small mb-1">
            Patrono Actual:
          </label>
          <Controller
            name="patronoActual"
            control={control}
            render={({ field }) => <FormControl {...field} />}
          />
        </div>

        <div className="col-lg-4 mt-4 ">
          <div className="col-lg-7 text-center mt-2">
            <label className="small mb-1">Agente percecpcion:</label>
            <div className="boder d-flex justify-content-center align items-center">
              <div className="form-check form-switch ">
                <Controller
                  name="agentePercepcion"
                  control={control}
                  render={({ field }) => (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
