import React, { Fragment, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { encrypt } from '../../../services/Encriptaciones'
import { useResetStore } from '../../../hooks/useResetStore'
import { cleanCacheApi, clearCookies } from '../../../utils'
export default function UserNavBar() {
  const resetStore = useResetStore()
  const [imageUrl, setImageUrl] = useState(
    process.env.PUBLIC_URL + '/assets/img/illustrations/profiles/profile-1.png'
  )

  const updateImageUrl = () => {
    const data = JSON.parse(encrypt.getSession('dataUser'))
    setImageUrl(
      data?.image || process.env.PUBLIC_URL + '/assets/img/illustrations/profiles/profile-1.png'
    )
  }
  useEffect(() => {
    updateImageUrl() // Actualiza la imagen al montar el componente

    const handleStorageChange = (event) => {
      if (event.key === 'dataUser') {
        updateImageUrl()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const logout = () => {
    resetStore()
    encrypt.clearSession()
    clearCookies()
    cleanCacheApi()
    window.location.href = '/login'
  }
  return (
    <Fragment>
      <li className="nav-item dropdown  dropdown-user me-3 me-lg-4">
        <NavLink
          className={'btn  btn-icon btn-outline-primary dropdown-toggle'}
          id="navbarDropdownUserImage"
          to="'javascript:void(0);'"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img className="img-fluid" src={imageUrl} alt="profile-1" />
        </NavLink>
        <div
          className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage"
        >
          <h6 className="dropdown-header d-flex align-items-center">
            <img className="dropdown-user-img" src={imageUrl} alt="profile-1" />
            <div className="dropdown-user-details">
              <div className="dropdown-user-details-name">
                {JSON.parse(encrypt.getSession('dataUser'))?.username}
              </div>
              <div className="dropdown-user-details-email">
                {JSON.parse(encrypt.getSession('dataUser'))?.nombre}
              </div>
            </div>
          </h6>
          <div className="dropdown-divider" />
          <Link className="dropdown-item" to="/configuraciones/perfil">
            <div className="dropdown-item-icon">
              <i data-feather="settings" />
            </div>
            Cuenta
          </Link>
          <button className="dropdown-item" onClick={logout}>
            <div className="dropdown-item-icon">
              <i data-feather="log-out"></i>
            </div>
            Cerrar Sesión
          </button>
        </div>
      </li>
    </Fragment>
  )
}
