import toast from 'react-hot-toast'
import { POSITIONS_TOASTS } from '../constants/position-toasts'
const threeSeconds = 3000
const error = ({
  message = 'Algo Salio Mal ',
  duration = threeSeconds,
  position = POSITIONS_TOASTS.BOTTOM_RIGHT
}) => {
  return toast.error(message, {
    duration: duration,
    position: position
  })
}

const success = ({
  message = 'Operacion Exitosa ',
  duration = threeSeconds,
  position = POSITIONS_TOASTS.BOTTOM_RIGHT
}) => {
  return toast.success(message, {
    duration: duration,
    position: position
  })
}

const loading = ({
  message = 'Cargando ',
  duration = threeSeconds,
  position = POSITIONS_TOASTS.BOTTOM_RIGHT
}) => {
  return toast.loading(message, {
    duration: duration,
    position: position
  })
}

const info = ({
  message = 'Informacion ',
  duration = threeSeconds,
  position = POSITIONS_TOASTS.BOTTOM_RIGHT
}) => {
  return toast(
    (t) => {
      return (
        <span>
          <p className="mx-auto">{message}</p>
          <button className=" btn btn-outline-primary" onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </button>
        </span>
      )
    },
    {
      duration: duration,
      position: position
    }
  )
}

const blank = ({
  message = ' ',
  duration = threeSeconds,
  position = POSITIONS_TOASTS.BOTTOM_RIGHT
}) => {
  toast(message, {
    duration: duration,
    position: position
  })
}

const promise = ({
  promise,
  loadingMessage = 'Cargando...',
  successMessage = 'Operacion Exitosa',
  errorMessage = 'Algo Salio Mal'
}) => {
  return toast.promise(promise, {
    loading: loadingMessage,
    success: successMessage,
    error: errorMessage
  })
}
const dismiss = (toastId) => {
  return toast.dismiss(toastId)
}

const toastAdapter = {
  error,
  success,
  loading,
  info,
  blank,
  promise,
  dismiss
}
export { toastAdapter }
