/** @format */

import React, { Fragment, useContext, useState } from 'react'
import PanelDiagnostico from './PanelDiagnostico'
import { Button } from 'react-bootstrap'
import { BsSave2 } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useSaveNoPathologicalDiseasesMutation } from '../../../../services/rtk-query'
import { toastAdapter } from '../../../../plugins'
import { useParams } from 'react-router-dom'
import { PermissionsContext } from '../../../../context'
import { modules, PERMISSIONS } from '../../../../constants'
import { RenderOptions } from './components/RenderOptions'

export default function FormAlergias() {
  const { hasPermission } = useContext(PermissionsContext)
  const { id = null } = useParams()
  const [isDisable, setIsDisable] = useState(false)
  const [alergias, setAlergias] = useState({
    alergias: false,
    noPatologico: false,
    ginecologicos: false,
    obstericos: false,
    patologico: false,
    heredidatorio: false,
    perenitales: false
  })
  const { noPatologicos: payloadNoPatologico } = useSelector((state) => state.antecedente)
  const [saveNoPathologicalDiseases] = useSaveNoPathologicalDiseasesMutation()

  function noPatologico() {
    setAlergias({
      noPatologico: true,
      alergias: false,
      obstericos: false,
      patologico: false,
      heredidatorio: false,
      perenitales: false
    })
  }

  function alergia() {
    setAlergias({
      alergias: true,
      noPatologico: false,
      obstericos: false,
      patologico: false,
      heredidatorio: false,
      perenitales: false
    })
  }

  function gine() {
    setAlergias({
      ginecologicos: true,
      alergias: false,
      noPatologico: false,
      obstericos: false,
      heredidatorio: false,
      perenitales: false
    })
  }

  function ob() {
    setAlergias({
      obstericos: true,
      alergias: false,
      noPatologico: false,
      ginecologicos: false,
      patologico: false,
      heredidatorio: false
    })
  }

  const pato = () => {
    setAlergias({
      patologico: true,
      obstericos: false,
      alergias: false,
      noPatologico: false,
      ginecologicos: false,
      heredidatorio: false,
      perenitales: false
    })
  }
  const here = () => {
    setAlergias({
      heredidatorio: true,
      patologico: false,
      obstericos: false,
      alergias: false,
      noPatologico: false,
      ginecologicos: false,
      perenitales: false
    })
  }

  const pere = () => {
    setAlergias({
      perenitales: true,
      heredidatorio: false,
      patologico: false,
      obstericos: false,
      alergias: false,
      noPatologico: false,
      ginecologicos: false
    })
  }

  const handleSaveNoPatologico = () => {
    setIsDisable(true)
    toastAdapter
      .promise({
        promise: saveNoPathologicalDiseases({
          ...payloadNoPatologico,
          expedienteId: id
        }).unwrap(),
        successMessage: 'Se guardo correctamente los datos',
        errorMessage: 'Algo salio mal, intente de nuevo mas tarde',
        loadingMessage: 'Guardando datos...'
      })
      .finally(() => setIsDisable(false))
  }
  return (
    <>
      <PanelDiagnostico
        perinatales={pere}
        hereditarios={here}
        obstetricos={ob}
        patologicos={pato}
        ginecologicos={gine}
        alergias={alergia}
        noPatologicos={noPatologico}
      >
        <div className="container">
          <RenderOptions alergias={alergias} />
        </div>
      </PanelDiagnostico>
      {alergias.noPatologico && (
        <Button
          variant="primary"
          onClick={handleSaveNoPatologico}
          className="fab-button"
          disabled={isDisable || !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
        >
          <BsSave2 /> Guardar
        </Button>
      )}
    </>
  )
}
