import React from 'react'

import { IoReturnUpForward } from 'react-icons/io5'
import { formatInTimeZone } from 'date-fns-tz'
import { Badge } from 'react-bootstrap'
import { Menu, Item, useContextMenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import '../styles.module.css'
import { BADGE_COLORS, ZONE } from '../../../constants'
import { useLazyGetFolderByIdQuery } from '../../../services/rtk-query/clinicalApi'
import { useNavigateFolder } from '../../../hooks'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { useDispatch } from 'react-redux'
import { eventClearActiveEvent, eventSetActive } from '../../../store/slices/uiSlice'

const MENU_ID = 'menu-id'

export const CalendarEvent = ({ event }) => {
  const [folderByIdFn] = useLazyGetFolderByIdQuery()
  const { handleFetchAndNavigate } = useNavigateFolder(folderByIdFn)
  const { show } = useContextMenu({
    id: MENU_ID
  })
  const dispatch = useDispatch()
  const { title, start, end, id, estado } = event

  const horaInicio = formatInTimeZone(start, ZONE, 'HH:mm')
  const horaFin = formatInTimeZone(end, ZONE, 'HH:mm')

  const handleClick = async (date) => {
    dispatch(eventSetActive(null))
    if (!date.pacienteId)
      return toastAdapter.info({
        message: 'No se puede crear la consulta, el paciente no tiene expediente médico.'
      })

    await handleFetchAndNavigate(date)
  }

  return (
    <>
      <article
        key={id}
        onContextMenu={show}
        className="p-1"
        style={{
          fontSize: '10px'
        }}
      >
        <div className="d-flex justify-content-space-around flex-wrap ">
          <strong className="text-break">{title}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <small className="px-1">
            hora: {horaInicio} - {horaFin}
          </small>
          <small className="px-1">
            <Badge bg={BADGE_COLORS[estado?.nombre]} className="text-wrap">
              {estado.nombre}
            </Badge>
          </small>
        </div>
      </article>
      <Menu id={MENU_ID} animation={{ enter: 'scale', exit: 'flip' }} theme="light">
        <Item
          onClick={() => {
            handleClick(event)
          }}
        >
          <span className="pr-2">Crear Consulta</span> <IoReturnUpForward size={24} />
        </Item>
      </Menu>
    </>
  )
}
