export const transformedData = (data) => {
  return data.map((item) => ({
    id: item.moduleId,
    permiso: {
      read: item.leer,
      write: item.escribir,
      view: item.ver
    }
  }))
}
