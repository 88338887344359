/** @format */

import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setBallarGenitalesF,
  setBallarGenitalesM,
  setBallarLanugo,
  setBallarMamas,
  setBallarOrejas,
  setBallarPlanta,
  setBallarScore,
  setBallarSkin,
  setBallarTable
} from '../../../../store/slices/antecedentes'
import { Controller, useForm } from 'react-hook-form'
import { mapToLoadPerinatales } from './utils'
import { clinicalApi } from '../../../../services/rtk-query/clinicalApi'
import { useParams } from 'react-router-dom'

const TablaBallard = () => {
  const { id = null } = useParams()
  let [estado, setEstado] = useState(0)
  let [resultado, setResultado] = useState(0)

  const dispatch = useDispatch()
  const { perinatales } = useSelector((state) => state.antecedente)

  const { data, isSuccess, isLoading } =
    clinicalApi.endpoints.getPregnancyHistoryForFile.useQuery(id)

  const [isLoaded, setIsLoaded] = useState(false)
  const { setValue, control, watch, formState, getValues } = useForm({
    defaultValues: {
      ballardSkin: perinatales?.tableBallar?.ballardSkin ?? 0,
      ballardLanugo: perinatales?.tableBallar?.ballardLanugo ?? 0,
      ballardPlanta: perinatales?.tableBallar?.ballardPlanta ?? 0,
      ballardMamas: perinatales?.tableBallar?.ballardMamas ?? 0,
      ballardOrejas: perinatales?.tableBallar?.ballardOrejas ?? 0,
      ballardGenitalesF: perinatales?.tableBallar?.ballardGenitalesF ?? 0,
      ballardGenitalesM: perinatales?.tableBallar?.ballardGenitalesM ?? 0
    }
  })
  useEffect(() => {
    if (isSuccess && !isLoading) {
      if (!data?.length) return
      const [info] = data
      const result = mapToLoadPerinatales(info)
      dispatch(setBallarTable(result.tableBallar))
      dispatch(setBallarScore(+result.puntajeBallar))
    }
  }, [isSuccess, isLoading, dispatch])

  useEffect(() => {
    if (!isLoaded && perinatales?.puntajeApgar !== undefined && perinatales?.tableApgar !== null) {
      setValesTable(perinatales)
    }
  }, [isLoaded, perinatales, setValue])

  useEffect(() => {
    dispatch(setBallarScore(resultado))
    dispatch(setBallarTable(watch()))
  }, [dispatch, resultado, watch])

  const setValesTable = (data) => {
    const parsedScore = parseInt(+data?.puntajeBallar ?? 0)
    setValue('capurroSkinballardSkin', +data?.tableBallar?.ballardSkin ?? 0)
    setValue('ballardLanugo', +data?.tableBallar?.ballardLanugo ?? 0)
    setValue('ballardPlanta', +data?.tableBallar?.ballardPlanta ?? 0)
    setValue('ballardMamas', +data?.tableBallar?.ballardMamas ?? 0)
    setValue('ballardOrejas', +data?.tableBallar?.ballardOrejas ?? 0)
    setValue('ballardGenitalesF', +data?.tableBallar?.ballardGenitalesF ?? 0)
    setValue('ballardGenitalesM', +data?.tableBallar?.ballardGenitalesM ?? 0)
    if (!isNaN(parsedScore)) {
      setResultado(parsedScore)
      setIsLoaded(true)
    }
  }
  const handleChange = (data) => {
    let rating = 0
    let total = 0

    // estado = rating;
    const {
      ballardSkin,
      ballardLanugo,
      ballardPlanta,
      ballardMamas,
      ballardOrejas,
      ballardGenitalesF,
      ballardGenitalesM
    } = getValues()

    rating += parseInt(ballardLanugo)
    rating += parseInt(ballardSkin)
    rating += parseInt(ballardPlanta)
    rating += parseInt(ballardMamas)
    rating += parseInt(ballardOrejas)
    rating += parseInt(ballardGenitalesF)
    rating += parseInt(ballardGenitalesM)

    setEstado(rating)
    if (rating >= 1 && rating <= 5) {
      total = 26
    }
    if (rating >= 6 && rating <= 10) {
      total = 28
    }
    if (rating >= 11 && rating <= 15) {
      total = 30
    }
    if (rating >= 16 && rating <= 25) {
      total = 34
    }
    if (rating >= 26 && rating <= 35) {
      total = 38
    }
    if (rating >= 36 && rating <= 45) {
      total = 42
    }
    if (rating >= 46) {
      total = 44
    }
    setResultado(total)
    // dispatch(setBallarScore(resultado));
  }

  return (
    <Fragment>
      <div id="ballard-formula">
        <div className="row mb-3">
          <div className="col-12">
            <div id="allergy-symptoms-list" className="list-group">
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/1.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Piel
                </div>
                <Controller
                  name="ballardSkin"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      id="ballard-2"
                      className="ballard-select form-select w-25"
                      onChange={(e) => {
                        dispatch(setBallarSkin(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardSkin: e.target.value
                        })
                      }}
                      value={value}
                    >
                      <option value="0">Gelatinosa, roja y transparente</option>
                      <option value="1">Lisa, rosada y venas visibles</option>
                      <option value="2">Descamasion superficil y pocas venas</option>
                      <option value="3">Palida, grietas y raras venas</option>
                      <option value="4">Surcos profundos y no hay venas</option>
                      <option value="5">Gruesa, surcos y arrugas</option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/2.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Lanugo
                </div>
                <Controller
                  name="ballardLanugo"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        dispatch(setBallarLanugo(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardLanugo: e.target.value
                        })
                      }}
                      value={value}
                      id="ballard-1"
                      className="ballard-select form-select w-25"
                    >
                      <option value="0">Nignuno</option>
                      <option value="1">Abundante</option>
                      <option value="2">Mas Fino</option>
                      <option value="3">Areas Lampiñas</option>
                      <option value="4">Casi todo limpio</option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/3.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Surcos Planares
                </div>
                <Controller
                  name="ballardPlanta"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        dispatch(setBallarPlanta(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardPlanta: e.target.value
                        })
                      }}
                      value={value}
                      id="ballardPlanta??ballard-3"
                      className="ballard-select form-select w-25"
                    >
                      <option value="0">Nignuno</option>
                      <option value="1">Liheras marcas rojas</option>
                      <option value="2">Solo surco transverso anterior</option>
                      <option value="3">Surcos en 2/3 anteriores</option>
                      <option value="4">Surcos en toda la planta</option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/4.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Mamas
                </div>
                <Controller
                  name="ballardMamas"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        dispatch(setBallarMamas(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardMamas: e.target.value
                        })
                      }}
                      value={value}
                      id="bballardMamas??allard-4"
                      className="ballard-select form-select w-25"
                    >
                      <option onChange={handleChange} value="0">
                        Apenas perceptibles
                      </option>
                      <option onChange={handleChange} value="1">
                        Areola plana sin relieves
                      </option>
                      <option onChange={handleChange} value="2">
                        Areola punteada relieve: 1-2mm
                      </option>
                      <option onChange={handleChange} value="2">
                        Areola elevada relieve: 3-4mm
                      </option>
                      <option onChange={handleChange} value="2">
                        Areola llena relieve: 5-10mm
                      </option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/5.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Orejas
                </div>
                <Controller
                  name="ballardOrejas"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      onChange={(e) => {
                        dispatch(setBallarOrejas(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardOrejas: e.target.value
                        })
                      }}
                      value={value}
                      id="bballardOrejas??allard-5"
                      className="ballard-select form-select w-25"
                    >
                      <option value="0">Pabellon plano queda plegado</option>
                      <option value="1">Pabellon blando despliegue lento</option>
                      <option value="2">Pabellon incurvado facil de enderezar</option>
                      <option value="3">Formadas libres, siempre enderezables</option>
                      <option value="4">Grueso cartilago oreja rigida</option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/6.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Genitales (Femenino)
                </div>
                <Controller
                  name="ballardGenitalesF"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      id="ballard-6"
                      className="ballard-select form-select w-25"
                      onChange={(e) => {
                        dispatch(setBallarGenitalesF(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardGenitalesF: e.target.value
                        })
                      }}
                      value={value}
                    >
                      <option value="0">Clitoris y labios menores prominentes</option>
                      <option value="2">Labios mayores y menores igual de prominentes</option>
                      <option value="3">Labios mayores grandes y menores pequeños</option>
                      <option value="4">Clitoris y menores cubiertos totalmente</option>
                    </select>
                  )}
                />
              </div>
              <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>
                  <img
                    className="me-2"
                    src={'/assets/img/ballard/7.svg'}
                    width="45px"
                    alt="ballard-point"
                  />
                  Genitales (Masculino)
                </div>
                <Controller
                  name="ballardGenitalesM"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <select
                      id="ballard-7"
                      onChange={(e) => {
                        dispatch(setBallarGenitalesM(e.target.value))
                        onChange(e)
                        handleChange({
                          ballardGenitalesM: e.target.value
                        })
                      }}
                      value={value}
                      className="ballard-select form-select w-25"
                    >
                      <option value="0">Escroto vacio sin arrugas</option>
                      <option value="2">Testiculos en descenso pocas arrugas</option>
                      <option value="3">Testiculos descendidos buenas arrugas</option>
                      <option value="4">Testiculos pendulos produndas arrugas</option>
                    </select>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <p className="fw-bold mb-1">Interpretación del puntaje</p>
        <div className="d-flex align-items-center border border-2 rounded bg-light p-3">
          <img
            className="me-5"
            src={'/assets/img/ballard/8.svg'}
            width="65px"
            alt="ballard-rating"
          />
          <h1 id="ballard-rating-count" className="fs-1 mb-0">
            {resultado} semanas
          </h1>
        </div>
      </div>
    </Fragment>
  )
}

export default TablaBallard
