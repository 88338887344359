import React from 'react'
import { useSelector } from 'react-redux'
import NoPermissions from '../../../../components/ui/NoPermissions'
import { PatientProfile } from './PatientProfile'
import FormAlergias from '../../antecedentes/alergias/FormAlergias'
import FormIncapacidad from '../../incapacidad/FormIncapacidad'
import FormPrincipalExterior from '../../exporacionFisica/FormPrincipalExterior'
import FormPrincipalInterrogatorio from '../../interrogatorio/FormPrincipalInterrogatorio'
import ListadoConsultas from '../../../consultas/ListadoConsultas'
import { SelectTabMessage } from '../../../../components/ui/SelectOption'

export const RenderOptions = () => {
  const { tabsExpediente } = useSelector((state) => state.ui)

  if (!tabsExpediente) return <NoPermissions />

  if (tabsExpediente.paciente) {
    return <PatientProfile />
  }

  if (tabsExpediente.antecedentes) {
    return <FormAlergias />
  }

  if (tabsExpediente.incapacidad) {
    return <FormIncapacidad />
  }

  if (tabsExpediente.exploracionFisica) {
    return <FormPrincipalExterior />
  }

  if (tabsExpediente.interrogatorio) {
    return <FormPrincipalInterrogatorio />
  }

  if (tabsExpediente.consulta) {
    return <ListadoConsultas />
  }

  return <SelectTabMessage />
}
