/** @format */

import React, { useState, useCallback, useMemo } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { LiaAllergiesSolid, LiaBabyCarriageSolid, LiaTransgenderSolid } from 'react-icons/lia'
import { MdOutlineHealthAndSafety } from 'react-icons/md'
import { PiDnaThin, PiTestTube, PiVirusLight } from 'react-icons/pi'
import PropTypes from 'prop-types'
import styles from './../../../../styles.module.css'
import WithPermissions from '../../../../HOCs/WithPermissions'
import { ALL_PERMISSIONS, modules } from '../../../../constants'
export default function PanelDiagnostico({
  perinatales,
  hereditarios,
  patologicos,
  obstetricos,
  noPatologicos,
  alergias,
  ginecologicos,
  children
}) {
  const [activeButton, setActiveButton] = useState('alergias')

  const handleButtonClick = useCallback((id, callback) => {
    setActiveButton(id)
    callback()
  }, [])

  const buttonsData = useMemo(
    () => [
      {
        id: 'alergias',
        label: 'Alergias',
        icon: <LiaAllergiesSolid size={20} />,
        callback: alergias
      },
      {
        id: 'hereditarios',
        label: 'Hereditarios',
        icon: <PiDnaThin size={20} />,
        callback: hereditarios
      },
      {
        id: 'patologias',
        label: 'Patológicos',
        icon: <PiVirusLight size={20} />,
        callback: patologicos
      },
      {
        id: 'no_patologicos',
        label: 'No Patológicos',
        icon: <PiTestTube size={20} />,
        callback: noPatologicos
      },
      {
        id: 'perinatales',
        label: 'Perinatales',
        icon: <LiaBabyCarriageSolid size={20} />,
        callback: perinatales
      },
      {
        id: 'ginecologicos',
        label: 'Ginecológicos',
        icon: <LiaTransgenderSolid size={20} />,
        callback: ginecologicos
      },
      {
        id: 'obstetricos',
        label: 'Obstétricos',
        icon: <MdOutlineHealthAndSafety size={20} />,
        callback: obstetricos
      }
    ],
    [alergias, hereditarios, patologicos, noPatologicos, perinatales, ginecologicos, obstetricos]
  )

  return (
    <Row>
      <Col md={12} className="d-flex justify-content-center">
        <ul className={styles?.['button-list']}>
          {buttonsData.map((button) => (
            <WithPermissions
              key={button.id}
              module={modules?.[`antecedentes_${button.id}`]}
              requiredPermissions={ALL_PERMISSIONS}
            >
              <li key={button.id} className="mx-2">
                <Button
                  variant={activeButton === button.id ? 'primary' : 'outline-primary'}
                  onClick={() => handleButtonClick(button.id, button.callback)}
                  aria-pressed={activeButton === button.id}
                  aria-label={button.label}
                >
                  {button.icon} {button.label}
                </Button>
              </li>
            </WithPermissions>
          ))}
        </ul>
      </Col>
      <Col className="mx-4">{children}</Col>
    </Row>
  )
}

PanelDiagnostico.propTypes = {
  perinatales: PropTypes.func.isRequired,
  hereditarios: PropTypes.func.isRequired,
  patologicos: PropTypes.func.isRequired,
  obstetricos: PropTypes.func.isRequired,
  noPatologicos: PropTypes.func.isRequired,
  alergias: PropTypes.func.isRequired,
  ginecologicos: PropTypes.func.isRequired,
  children: PropTypes.node
}
