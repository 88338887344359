/** @format */

import React from 'react'
import HeaderPage from '../../components/theme/header/Header'
import { Container } from 'react-bootstrap'

export default function LayoutForm(props) {
  return (
    <>
      <HeaderPage title={props.title} tools={props.tools} />
      <Container
        fluid="2lg"
        className="m-2 shadow-md"
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          minHeight: '70vh'
        }}
      >
        {props.children}
      </Container>
    </>
  )
}
