import React from 'react'
import { Seguridad } from './seguridad/seguridad'
import { Componentes } from '../administracion/componentes/Componentes'
import MenuConsultaPaciente from './consultaPaciente/MenuConsultaPaciente'
import packageJson from '../../../../../package.json'
import MenuMedicos from './medicos/MenuMedicos'
import { GeneralesMenu } from './generales/GeneralesMenu'

export default function administracionMenu() {
  return (
    <React.Fragment>
      <div className="sidenav-menu-heading">Version: {packageJson.version}</div>
      <Componentes />
      <MenuConsultaPaciente />
      <MenuMedicos />
      <GeneralesMenu />
      <Seguridad />

      {/* Expediente medico */}
    </React.Fragment>
  )
}
