/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useCallback, useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import { PiTrashSimple } from 'react-icons/pi'

import {
  clinicalApi,
  useDeleteMedicoMutation,
  useDepartamentosQuery,
  useGeneroQuery,
  useMunicipioQuery,
  usePaisesQuery
} from '../../../services/rtk-query/clinicalApi'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { alertConfirm } from '../../../plugins/sweetAlert.plugin'
import { PermissionsContext } from '../../../context'
import { modules, PERMISSIONS } from '../../../constants'
import classNames from 'classnames'

export const Options = ({ cell, setValue, handleShow, setAction }) => {
  const { hasPermission } = useContext(PermissionsContext)
  const [deleteMedico] = useDeleteMedicoMutation()

  const { data: especialidades, isSuccess: isSuccessEspecialidad } =
    clinicalApi.endpoints.especialidades.useQuery()
  const { data: subEspecialidades, isSuccess: isSuccessSubEspecialidades } =
    clinicalApi.endpoints.subEspecialidades.useQuery()
  const { data, isSuccess } = useDepartamentosQuery(cell.paisId)
  const { data: paises, isSuccess: successPaises } = usePaisesQuery()
  const { data: municipios, isSuccess: successMunicipios } = useMunicipioQuery(+cell.estadoId)
  const { data: genders, isSuccess: successGender } = useGeneroQuery()

  const onEvent = useCallback(async () => {
    if (
      isSuccess &&
      successPaises &&
      successMunicipios &&
      successGender &&
      isSuccessEspecialidad &&
      isSuccessSubEspecialidades
    ) {
      const departamento = data.find((depa) => depa.id === cell.estadoId)
      handleShow()

      let newDepa = null
      if (departamento) {
        newDepa = {
          id: departamento?.id,
          label: departamento.value,
          value: departamento.id
        }
      }

      const { value, ...rest } = paises.find((pais) => pais.id === cell.paisId)

      let newPais = null

      if (rest) {
        newPais = {
          label: value,
          value: rest.id,
          ...rest
        }
      }

      const minucipio = municipios.find((municipio) => municipio.id === cell.municipioId)

      const gender = genders.find((gender) => gender.id === cell.genero)
      let newMunicipio = null
      if (minucipio) {
        const { value: muni, ...restM } = minucipio
        newMunicipio = {
          label: muni,
          value: restM.id,
          ...restM
        }
      }
      let newGender = null
      if (gender) {
        const { value: genderValue, ...restG } = gender
        newGender = {
          label: genderValue,
          value: restG.id,
          ...restG
        }
      }

      const espcialidad = especialidades.find(
        (especialidad) => especialidad.id === cell.especialidadId
      )
      const subEspecialidad = subEspecialidades.find(
        (subEspecialidad) => subEspecialidad.id === cell.subEspecialidadId
      )

      if (espcialidad) {
        const newEspecialidad = {
          label: espcialidad?.nombre,
          value: espcialidad?.id
        }
        setValue('especialidad', newEspecialidad)
      }

      if (subEspecialidad) {
        const newSubEspecialidad = {
          label: subEspecialidad.nombre,
          value: subEspecialidad.id
        }
        setValue('subEspecialidad', newSubEspecialidad)
      }
      setValue('nombres', cell.nombres)
      setValue('apellidos', cell.apellidos)
      setValue('genero', newGender)
      setValue('direccion', cell.direccion)
      setValue('pais', newPais)
      setValue('departamento', newDepa)
      setValue('municipio', newMunicipio)
      setValue('telefono', cell.telefono)
      setValue('email', cell.email)
      setValue('jvpm', cell.jvpm)
      setValue('medicoId', cell.id)
      setAction('UPDATE')
    }
  }, [
    cell.paisId,
    cell.estadoId,
    cell.municipioId,
    cell.genero,
    data,
    paises,
    municipios,
    genders,
    isSuccess,
    successPaises,
    successMunicipios,
    successGender,
    setValue,
    handleShow,
    setAction
  ])

  const onDelete = () => {
    alertConfirm({
      title: 'Desea eliminar este Medico?',
      text: 'No podras revertir cosas',
      confirmButtonText: 'Si, Eliminalo',
      cancelButtonText: 'Cancelar'
    }).then((resp) => {
      if (!resp) return

      toastAdapter.promise({
        promise: deleteMedico(cell.id).unwrap(),
        successMessage: 'Medico Eliminado',
        errorMessage: 'Ocurrio un error al intentar eliminar el medico'
      })
    })
  }
  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={onEvent}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.medicos)}
        >
          <FiEdit /> Actualizar
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onDelete}
          className={classNames('text-danger', {
            opacity: !hasPermission([PERMISSIONS.WRITE], modules.medicos)
          })}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.medicos)}
        >
          <PiTrashSimple /> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
