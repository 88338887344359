import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import {
  useDepartamentosQuery,
  useMunicipioQuery,
  usePaisesQuery
} from '../../../services/rtk-query/clinicalApi'
import { filterItems } from '../../../utils/selects.utils'

export const UbicacionEmpresa = ({ control, getValues, watch, setValue }) => {
  const [isPaisSelected, setIsPaisSelected] = useState(!!getValues('pais'))
  const [isDepaSelected, setIsDepaSelected] = useState(!!getValues('departamento'))

  const { isFetching: isFetchingPaises, data: paises } = usePaisesQuery()

  const { isFetching: isFetchingDepa, data: depas } = useDepartamentosQuery(
    getValues('pais')?.value ?? undefined,
    {
      skip: !isPaisSelected
    }
  )

  const { isFetching: isFetchingMuni, data: municipios } = useMunicipioQuery(
    getValues('departamento')?.value ?? undefined,
    {
      skip: !isDepaSelected
    }
  )

  useEffect(() => {
    if (getValues('pais')) {
      setIsPaisSelected(true)
    } else {
      setIsPaisSelected(false)
      setValue('departamento', null)
      setValue('municipio', null)
    }
  }, [watch('pais'), getValues, setValue])

  useEffect(() => {
    if (getValues('departamento')) {
      setIsDepaSelected(true)
    } else {
      setIsDepaSelected(false)
      setValue('municipio', null)
    }
  }, [watch('departamento'), getValues, setValue])

  const loadPaises = (inputValue) =>
    new Promise((resolve) => {
      const filtered = filterItems({
        items: paises,
        inputValue,
        key: 'value'
      })?.map((pais) => ({
        label: pais.value,
        value: pais.id
      }))
      setTimeout(() => {
        resolve(filtered)
      }, 1000)
    })

  const loadDepartamentos = (inputValue) =>
    new Promise((resolve) => {
      const filtered = filterItems({
        items: depas,
        inputValue,
        key: 'value'
      })?.map((depa) => ({
        label: depa.value,
        value: depa.id
      }))
      setTimeout(() => {
        resolve(filtered)
      }, 1000)
    })

  const loadMunicipios = (inputValue) =>
    new Promise((resolve) => {
      const filtered = filterItems({
        items: municipios,
        inputValue,
        key: 'value'
      })?.map((muni) => ({
        label: muni.value,
        value: muni.id
      }))
      setTimeout(() => {
        resolve(filtered)
      }, 1000)
    })

  return (
    <div className="row gx-3 mb-3">
      <div className="col-lg-4 mt-4">
        <label htmlFor="" className="small mb-1">
          País:
        </label>
        <Controller
          name="pais"
          control={control}
          defaultValue={getValues('pais')}
          render={({ field }) => (
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadPaises}
              isLoading={isFetchingPaises}
              onChange={(selected) => {
                field.onChange(selected)
                setIsPaisSelected(!!selected)
                setValue('departamento', null)
                setValue('municipio', null)
              }}
              value={field.value}
            />
          )}
        />
      </div>
      <div className="col-lg-4 mt-4">
        <label htmlFor="" className="small mb-1">
          Departamento:
        </label>
        <Controller
          name="departamento"
          control={control}
          defaultValue={getValues('departamento')}
          render={({ field }) => (
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadDepartamentos}
              isLoading={isFetchingDepa}
              isDisabled={!isPaisSelected}
              onChange={(selected) => {
                field.onChange(selected)
                setIsDepaSelected(!!selected)
                setValue('municipio', null)
              }}
              value={field.value}
            />
          )}
        />
      </div>
      <div className="col-lg-4 mt-4">
        <label htmlFor="" className="small mb-1">
          Municipio:
        </label>
        <Controller
          name="municipio"
          control={control}
          defaultValue={getValues('municipio')}
          render={({ field }) => (
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadMunicipios}
              isLoading={isFetchingMuni}
              isDisabled={!isDepaSelected}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      </div>
    </div>
  )
}
