import React, { useEffect } from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { Alert, Button, Card, CardBody, Col, Row } from 'react-bootstrap'
import { RoleSelected } from './components/RoleSelected'
import { useParams } from 'react-router-dom'
import {
  useAsignPermissionsToRoleMutation,
  useRoleByIdQuery
} from '../../services/rtk-query/clinicalApi'
import { Loader } from '../../components/ui/Loader'
import { Link } from 'react-router-dom'

import { TablePermissions } from './components/TablePermissions'
import { transformedData } from './helpers'
import { useForm } from 'react-hook-form'
import { toastAdapter } from '../../plugins/hot-toast.plugin'

export const RoleManagement = () => {
  const { id } = useParams()
  const [asignPermissions] = useAsignPermissionsToRoleMutation()
  const { data, isLoading, isError, isSuccess } = useRoleByIdQuery(id)

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      rows: []
    }
  })

  useEffect(() => {
    if (data) {
      const formattedData = data.modulos.map((modulo) => ({
        moduleId: modulo.moduleId,
        nombre: modulo.nombre,
        ver: modulo.ver ?? false,
        leer: modulo.leer ?? false,
        escribir: modulo.escribir ?? false
      }))
      reset({ rows: formattedData })
    }
  }, [data, reset])

  const onSubmit = (payload) => {
    const modulos = transformedData(payload.rows)
    const objectDto = { modulo: modulos, rol: data.roles[0].id_rol }

    toastAdapter.promise({
      promise: asignPermissions(objectDto).unwrap(),
      loadingMessage: 'Guardando...',
      successMessage: (data) => {
        reset()
        return 'Permisos asignados correctamente'
      },
      errorMessage: 'Error al asignar permisos'
    })
  }

  return (
    <>
      <LayoutForm title="Administración de Roles">
        <header className="mb-2">
          <h5>Administracion de Roles</h5>
          <p>Seleccionar permisos a los modulos que tendra acceso este rol</p>
        </header>
        <Col md={12}>
          <Card className="m-2 shadow-none">
            <CardBody>
              <RoleSelected data={data} />
            </CardBody>
          </Card>
        </Col>

        <Row>
          {isLoading && <Loader />}
          {isError && <Alert>Error al cargar los Modulos </Alert>}
          <Col md={8}>
            {isSuccess && <TablePermissions control={control} modules={data.modulos} />}
          </Col>
          {/* <DevTool control={control} /> */}
          <Col md={4}>
            <div className="d-grid gap-2">
              <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                Guardar
              </Button>
              <Link to={'/roles'} className="btn btn-outline-secondary" onClick={() => reset()}>
                Cancelar
              </Link>
            </div>
          </Col>
        </Row>
      </LayoutForm>
    </>
  )
}
