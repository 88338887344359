import React, { Suspense, useEffect, useState } from 'react'
import { Form, Button, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { encrypt } from '../../services/Encriptaciones'
import Alertas from '../../services/Alertas'
import HeaderAxios from '../../services/AxiosCabeceras'
import { PASSWORD_REGE } from '../../constants'
import { ProfilePictureUploader } from '../../components/ui'
import clienteAxios from '../../services/Axios'
import { toastAdapter } from '../../plugins'
import { Loader } from '../../components/ui/Loader'
import axios from 'axios'

export default function Perfil() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm()
  const [imageUrl, setImageUrl] = useState(undefined)
  const [changeImage, setChangeImage] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState(false)

  useEffect(() => {
    const data = JSON.parse(encrypt.getSession('dataUser'))?.image
    setImageUrl(data || imageUrl)
  }, [imageUrl])

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  const onSubmit = async (data) => {
    try {
      const usuario = JSON.parse(encrypt.getSession('dataUser')).id_usuario
      const passwords = data.password

      const formData = new FormData()
      formData.append('password', passwords)

      if (data.image) {
        formData.append('imagen', data?.image) // Suponiendo que 'image' es un array de archivos
      }

      Alertas.loading_reload(true, 'Cargando...')

      const response = await clienteAxios.patch(`user/profile/${usuario}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...HeaderAxios.getHeader()
        }
      })

      Alertas.loading_reload(false)

      if (response.status === 200 || response.status === 201) {
        toastAdapter.success({
          message: 'Datos actualizados correctamente'
        })
        reset()
      } else {
        toastAdapter.info({
          message: response.data.message
        })
      }
    } catch (error) {
      Alertas.loading_reload(false)
      toastAdapter.error({
        message: 'Error al actualizar el perfil'
      })
    }
  }

  const handlerImage = (image) => {
    setChangeImage(image)
  }
  const handleChangeImage = () => {
    const usuario = JSON.parse(encrypt.getSession('dataUser'))
    if (!changeImage) {
      return toastAdapter.info({
        message: 'No se ha seleccionado una imagen'
      })
    }

    setIsImageLoading(true)
    const formData = new FormData()
    formData.append('imagen', changeImage)
    toastAdapter
      .promise({
        promise: clienteAxios.patch(`user/profile/${usuario.id_usuario}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...HeaderAxios.getHeader()
          }
        }),
        successMessage: ({ data: { imagen } }) => {
          setChangeImage(null)
          usuario.image = imagen?.url
          encrypt.setSession('dataUser', usuario)
          return 'Imagen actualizada correctamente'
        },
        errorMessage: (error) => {
          if (axios.isAxiosError(error)) {
            return error.response.data.message.join(', ')
          }
          return 'Error al actualizar la imagen'
        }
      })
      .finally(() => {
        setIsImageLoading(false)
      })
  }

  return (
    <Suspense fallback={<Loader />}>
      <Suspense fallback={<Loader />}>
        <LayoutForm title="Informacion del perfil">
          <div className="row">
            <section className="col-md-4">
              <ProfilePictureUploader imageUrl={imageUrl} handler={handlerImage} />
              <Button
                variant="outline-secondary my-2"
                onClick={handleChangeImage}
                disabled={isImageLoading}
              >
                Guardar imagen
              </Button>
            </section>
            <div className="col-md-8">
              <div className="card mb-4 shadow-none">
                <div className="card-header">Informacion de perfil</div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                      <Form.Label className="small mb-1">Nombre de usuario</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Escriba un nombre de usuario"
                        disabled
                        defaultValue={JSON.parse(encrypt.getSession('dataUser'))?.username}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="small mb-1">Nombre completo</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Nombre completo"
                        disabled
                        defaultValue={JSON.parse(encrypt.getSession('dataUser'))?.nombre}
                      />
                    </Form.Group>
                    <Form.Group className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <Form.Label className="small mb-1">Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            {...register('password', {
                              required: 'Password es requerido',
                              pattern: {
                                value: PASSWORD_REGE,
                                message:
                                  'La contraseña debe incluir al menos una mayúscula, un valor numérico y un carácter especial'
                              },
                              minLength: {
                                value: 8,
                                message: 'Minimo 8 caracteres'
                              },
                              maxLength: {
                                value: 20,
                                message: 'La longitud máxima requerida es 20'
                              }
                            })}
                          />
                          <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                          </Button>
                        </InputGroup>
                        {errors.password && (
                          <span style={{ color: '#E77575' }} className="mt-2">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <Form.Label className="small mb-1">Confirmar password</Form.Label>
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Confirm Password"
                          {...register('confirmPassword', {
                            required: 'Se requiere confirmar contraseña',
                            validate: (value) =>
                              value === watch('password') || 'Las contraseñas no coinciden'
                          })}
                        />
                        {errors.confirmPassword && (
                          <span style={{ color: '#E77575' }} className="mt-2">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    <div className="card-footer text-center">
                      <Button
                        className="btn btn-secondary btn-lg mr-2"
                        onClick={() => {
                          navigate('/')
                        }}
                        Guardarstyle={{ marginRight: '10px' }}
                      >
                        <i className="fa fa-arrow-circle-left"></i> SALIR
                      </Button>
                      <Button type="submit" className="btn btn-primary btn-lg">
                        <i className="fa fa-save"></i> Guardar
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </LayoutForm>
      </Suspense>
    </Suspense>
  )
}
