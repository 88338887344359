import React, { useCallback, useEffect, useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Card, CardBody, Col, Row } from 'react-bootstrap'
import { Header } from '../../../components/Containers/Header'
import { ModalFileUpload } from './components'
import { activarModalGenerico } from '../../../store/slices/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ACTIONS, REPORTS_TYPES } from '../../../constants'
import { loadingAlert, selectAlert, toastAdapter } from '../../../plugins'
import { useLazyMedicosQuery } from '../../../services/rtk-query'
import { useForm } from 'react-hook-form'
import { clearType, setType } from '../../../store/slices/reportSlice'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { ReportsDefault } from '../../../components/ui/ReportsDefault'
import { encrypt } from '../../../services/Encriptaciones'
import { DocumenetUser } from './components/DocumenetUser'

const fetchReport = async (doctorId, formdata) => {
  const url = `${process.env.REACT_APP_RUTA}medico/reportes-medicos/${doctorId}`
  const token = localStorage.getItem('token')
  let headers = {}
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return await axios.patch(url, formdata, {
    headers
  })
}
export const ReportsPage = () => {
  const { type } = useSelector((state) => state.report)
  const location = useLocation()
  const [isFetching, setIsFetching] = useState(false)
  const [resetCompo, setResetCompo] = useState(false)
  const [doctor, setDoctor] = useState(null)
  const [doctorsQuery] = useLazyMedicosQuery()
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      id: 0
    }
  })
  useEffect(() => {
    return () => {
      dispatch(clearType())
    }
  }, [location])

  const handler = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    if (!type) return toastAdapter.error({ message: 'Debe seleccionar un tipo de reporte' })
    handleSave(data)
  }

  const toggleFetching = () => {
    setIsFetching((prev) => !prev)
  }

  const toogleReset = () => {
    setResetCompo((prev) => !prev)
  }
  const handleSave = async (data) => {
    const { id, ...rest } = data
    if (!rest.file) return toastAdapter.error({ message: 'Debe seleccionar un archivo' })

    const formData = new FormData()
    formData.append('file', rest.file[0])
    formData.append('tipo', +type)
    toggleFetching()
    toastAdapter
      .promise({
        promise: fetchReport(doctor, formData),
        successMessage: () => {
          handleReset()
          toogleReset()
          return 'Reporte  guardado con éxito'
        },
        errorMessage: 'Error al guardar la Reporte ',
        loadingMessage: 'Guardando Reporte ...'
      })
      .finally(() => {
        toggleFetching()
      })
  }

  const handleReset = () => {
    reset()
    setAction(ACTIONS.SAVE)
    dispatch(clearType())
  }

  const handlerCheckDoctor = useCallback(async () => {
    const doctor = encrypt.getSession('medico')
    if (!doctor?.medico) {
      loadingAlert({
        flag: true,
        text: 'Cargando Medicos',
        title: 'Cargando...'
      })
      const { data, isSuccess, isLoading } = await doctorsQuery()

      if (!isLoading) {
        loadingAlert({ flag: false })
      }
      if (!isSuccess) return

      const inputOptions = data
        ?.map((medico) => ({
          [medico.id]: `${medico.nombres} ${medico.apellidos}`
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})

      const response = await selectAlert({
        title: 'Seleccione un medico',
        text: 'Seleccione un Medico para continuar',
        inputPlaceHolder: 'Seleccione un medico',
        inputOptions
      })
      if (response.isConfirmed) {
        return response.value
      }

      return
    }
    return doctor?.medico?.id
  }, [doctorsQuery])

  const dropdownItems = useMemo(
    () => [
      {
        title: 'Expediente',
        handler: async () => {
          const doctorId = await handlerCheckDoctor()
          if (!doctorId) return
          setDoctor(doctorId)

          dispatch(setType(REPORTS_TYPES.expediente))
        }
      },
      {
        title: 'Incapacidad',
        handler: async () => {
          const doctorId = await handlerCheckDoctor()
          if (!doctorId) return
          setDoctor(doctorId)
          dispatch(setType(REPORTS_TYPES.incapacidad))
        }
      },
      {
        title: 'Prescripcion',
        handler: async () => {
          const doctorId = await handlerCheckDoctor()
          if (!doctorId) return
          setDoctor(doctorId)
          dispatch(setType(REPORTS_TYPES.prescripcion))
        }
      }
    ],
    [dispatch, handlerCheckDoctor]
  )
  return (
    <LayoutForm title="Reportes Medicos">
      <Card className="shadow-none">
        <Header
          dropdownItems={dropdownItems}
          isDropdown={true}
          title="Reportes"
          subtitle="Reportes Medicos"
          buttonTitle="Subir Reporte"
          handler={handler}
          showButton={false}
        />
        <CardBody>
          <Row className="mb-5">
            <Col md={6} className="my-2  text-start">
              <ReportsDefault />
            </Col>
            <Col md={6} className="my-2  text-start">
              <DocumenetUser />
            </Col>
          </Row>

          <ModalFileUpload
            resetComponent={resetCompo}
            isFetching={isFetching}
            dropdownItems={dropdownItems}
            handlerDropdown={handler}
            action={action}
            control={control}
            errors={errors}
            handlerAction={handlerAction}
            handleSubmit={handleSubmit}
            reset={handleReset}
            setValue={setValue}
          />
        </CardBody>
      </Card>
    </LayoutForm>
  )
}
