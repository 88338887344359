import React, { useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { EnfermedadesHereditariasLista } from './components/EnfermedadesHereditariasLista'
import { useDispatch } from 'react-redux'
import { activarModalGenerico } from '../../../store/slices/uiSlice'
import { ModalGenerico } from '../../../components/ui'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { enfermededHereditariaSchema } from '../../../utils'
import {
  useSaveHereditaryDiseaseMutation,
  useUpdateHereditaryDiseaseMutation
} from '../../../services/rtk-query'
import { toastAdapter } from '../../../plugins'
import { Options } from './components/Options'
import { ACTIONS, modules, PERMISSIONS } from '../../../constants'
import { Header } from '../../../components/Containers/Header'
import { usePermissions } from '../../../hooks'

const defaultValues = {
  nombre: '',
  descripcion: '',
  id: 0
}
export const EnfermedadesHereditarias = () => {
  const { hasPermission } = usePermissions()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(enfermededHereditariaSchema),
    defaultValues
  })

  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id'
      },
      {
        header: 'nombre',
        accessorKey: 'nombre'
      },
      {
        header: 'descripcion',
        accessorKey: 'descripcion'
      },
      {
        header: 'Activo',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Alert
              variant="primary"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              Si
            </Alert>
          ) : (
            <Alert
              variant="danger"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              No
            </Alert>
          )
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => (
          <Options
            hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.antecedentes_hereditarios)}
            id={original.id}
            setValue={setValue}
            setAction={setAction}
            activo={original.activo}
          />
        )
      }
    ],
    []
  )
  const dispatch = useDispatch()
  const [saveHereditaryDisease] = useSaveHereditaryDiseaseMutation()
  const [updateHereditaryDisease] = useUpdateHereditaryDiseaseMutation()

  const handleNewEnfermedad = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    if (action === ACTIONS.SAVE) return handleSave(data)

    return handleUpdate(data)
  }

  const handleSave = ({ id, ...rest }) => {
    toastAdapter.promise({
      promise: saveHereditaryDisease(rest).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)

        return 'Enfermedad guardada con éxito'
      },
      errorMessage: 'Error al guardar la enfermedad',
      loadingMessage: 'Guardando enfermedad...'
    })
  }

  const handleUpdate = (data) => {
    toastAdapter.promise({
      promise: updateHereditaryDisease({ ...data, id: +getValues('id') }).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)
        return 'Enfermedad guardada con éxito'
      },
      errorMessage: 'Error al guardar la enfermedad',
      loadingMessage: 'Guardando enfermedad...'
    })
  }

  return (
    <LayoutForm title="Enfermedades Hereditarias">
      <Card className="shadow-none">
        <Header
          handler={handleNewEnfermedad}
          hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.antecedentes_hereditarios)}
        />
        <Card.Body>
          <EnfermedadesHereditariasLista columns={columns} />
        </Card.Body>
      </Card>
      <ModalGenerico
        title="Enfermedades Hereditarias"
        buttonCustom={() => (
          <Button
            variant="primary "
            size="md"
            style={{
              minWidth: '15%'
            }}
            onClick={handleSubmit(handlerAction)}
          >
            {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
          </Button>
        )}
        closeButtonAction={reset}
      >
        <Form>
          <Form.Group>
            <Form.Label>Nombre de la enfermedad</Form.Label>
            <Controller
              control={control}
              name="nombre"
              render={({ field }) => (
                <Form.Control type="text" placeholder="Nombre de la enfermedad..." {...field} />
              )}
            />
            {errors.nombre && (
              <Form.Text className="text-danger">{errors?.nombre?.message}</Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripcion de la enfermedad</Form.Label>
            <Controller
              control={control}
              name="descripcion"
              render={({ field }) => (
                <Form.Control as={'textarea'} placeholder="enfermedad..." {...field} />
              )}
            />
            {errors.descripcion && (
              <Form.Text className="text-danger">{errors?.descripcion?.message}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </ModalGenerico>
    </LayoutForm>
  )
}
