import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import classNames from 'classnames'
import {
  useDeleteHereditaryDiseaseMutation,
  useLazyHereditaryByIdQuery
} from '../../../../services/rtk-query'
import { useDispatch } from 'react-redux'
import { activarModalGenerico } from '../../../../store/slices/uiSlice'
import { ACTIONS } from '../../../../constants'
import { alertConfirm, toastAdapter } from '../../../../plugins'

export const Options = ({ id, setValue, setAction, activo, ...rest }) => {
  const [hereditaryById] = useLazyHereditaryByIdQuery()
  const [deleteHereditartDisease] = useDeleteHereditaryDiseaseMutation()
  const dispatch = useDispatch()
  const onEvent = async () => {
    const { data, isSuccess } = await hereditaryById(id)

    if (isSuccess && data) {
      setValue('nombre', data.nombre)
      setValue('descripcion', data.descripcion)
      setValue('id', data.id)
      setAction(ACTIONS.UPDATE)
      dispatch(activarModalGenerico())
    }
  }

  const onDelete = () => {
    alertConfirm({
      title: activo ? 'Deshabilitar' : 'Habilitar',
      text: `¿Estas seguro de ${!activo ? 'Habilitar' : 'Deshabilitar'} este registro?`,
      icon: 'warning',
      confirmButtonText: `Si, ${!activo ? 'Habilitar' : 'Deshabilitar'}`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result) {
        toastAdapter.promise({
          promise: deleteHereditartDisease({ id, activo }).unwrap(),
          successMessage: `Enfermedad hereditaria ${!activo ? 'Habilitada' : 'Deshabilitada'} con éxito`,
          errorMessage: `Error al ${!activo ? 'Habilitar' : 'Deshabilitar'} enfermedad hereditaria`
        })
      }
    })
  }

  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onEvent} disabled={rest.hasPermission}>
          <FiEdit /> Actualizar
        </Dropdown.Item>

        <Dropdown.Item
          onClick={onDelete}
          className={classNames('text-danger', {
            opacity: rest.hasPermission
          })}
          disabled={rest.hasPermission}
        >
          Habilitar/Deshabilitar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
PropTypes.Options = {
  onEvent: PropTypes.func.isRequired
}
