import axios from 'axios'
import { PERMISSIONS } from '../constants'
import { toastAdapter } from '../plugins'
import { clinicalApi } from '../services/rtk-query/clinicalApi'
import { add, addDays, isValid, set } from 'date-fns'

const updateNestedItemsActives = ({ items, actives, idKey = 'id', nestedKey = 'familiares' }) => {
  return checkItemActive({ items, actives, idKey, nestedKey })
}

const findElement = ({ arr, idKey, item }) => arr?.find((element) => element[idKey] === item[idKey])

const checkItemActive = ({ items, actives, idKey = 'id', nestedKey = 'familiares' }) => {
  return items?.map((item) => {
    const itemActiveFound = findElement({
      arr: actives,
      idKey,
      item
    })

    if (!itemActiveFound?.[nestedKey]?.length) {
      const descripcion = itemActiveFound?.descripcion ?? item?.descripcion ?? null

      return {
        ...item,
        descripcion
      }
    }

    const modifiedActiveItem = item?.[nestedKey].map((nestedItem) => {
      const resultItem = findElement({
        arr: itemActiveFound?.[nestedKey],
        idKey,
        item: nestedItem
      })

      return resultItem
        ? {
            ...nestedItem,
            isChecked: true,
            descripcion: resultItem?.descripcion ?? nestedItem.descripcion
          }
        : nestedItem
    })

    return {
      ...itemActiveFound,
      [nestedKey]: modifiedActiveItem.length ? modifiedActiveItem : itemActiveFound[nestedKey]
    }
  })
}

function obtenerURLBlob(blob) {
  return URL.createObjectURL(blob)
}
function saveOnCookies(propName, data) {
  document.cookie = `${propName}=${JSON.stringify(data)};path=/`
  return
}

const getDataCookie = (propname) => {
  const nombreCookie = `${propname}=`
  const cookies = decodeURIComponent(document.cookie).split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim()
    if (cookie.indexOf(nombreCookie) === 0) {
      console.log({ cookie })
      return JSON.parse(cookie.substring(nombreCookie.length, cookie.length))
    }
  }
  return null
}

function clearCookies(propName) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    const cookieName = cookie.split('=')[0]
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`
  }
  return
}
const makeArray = (num) => Array.from({ length: num }, (_, index) => index)

function cleanCacheApi() {
  clinicalApi.util.resetApiState()
}

// Función para calcular la fecha de hace 'daysAgo' días a partir de la fecha actual
const calculateDays = (currentDate = new Date(), daysAgo = 1) => {
  return addDays(currentDate, +daysAgo + 1)
}
const isDateValid = (date) => {
  const dateToValidate = new Date(date)
  return isValid(dateToValidate)
}

/**
 * Ajusta los minutos y segundos a 0, y añade las horas especificadas.
 * @param hoursToAdd - Número de horas a añadir a la hora actual.
 * @returns La nueva fecha y hora con las horas añadidas.
 */
function adjustTimeAndAddHours(hoursToAdd = 1) {
  // Obtén la fecha y hora actual en la zona horaria local
  let now = new Date()

  now = set(now, { minutes: 0, seconds: 0, milliseconds: 0 })

  const newTime = add(now, { hours: hoursToAdd })

  return newTime
}
function processErrorMessages({ error, toast = toastAdapter }) {
  if (!error?.data) return 'Ocurrió un error al guardar'

  if (!Array.isArray(error?.data?.message)) return error?.data?.message

  console.log(error?.data?.message)
  if (axios.isAxiosError(error)) {
    return error?.response?.data?.message.join(', ')
  }

  // return error?.data?.message?.join(', ');
}
function removeAccents(str = '') {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
function replaceSpacesAndSymbols(str = '') {
  return str.replace(/[ \-_\\/]/g, '_')
}
function formatUserData({ nombre, username, id_usuario, permisos, ...rest }) {
  if (!permisos.length) return { nombre, username, id_usuario, permiso: {} }

  if (!nombre || !username || !id_usuario)
    return { nombre: '', username: '', id_usuario: '', permiso: {} }

  return permisos.reduce(
    (estructura, permiso) => {
      const moduloNombre = removeAccents(
        replaceSpacesAndSymbols(permiso.modulo.nombre.toLowerCase())
      )

      if (!estructura.permisos[moduloNombre]) {
        estructura.permisos[moduloNombre] = []
      }

      if (permiso.view) {
        estructura.permisos[moduloNombre].push(PERMISSIONS.VIEW)
      }
      if (permiso.read) {
        estructura.permisos[moduloNombre].push(PERMISSIONS.READ)
      }
      if (permiso.write) {
        estructura.permisos[moduloNombre].push(PERMISSIONS.WRITE)
      }

      return estructura
    },
    {
      ...rest,
      nombre: nombre,
      username: username,
      id_usuario: id_usuario,

      permisos: {}
    }
  )
}

function transformDataResponse(inputData = []) {
  if (!inputData.length) throw new Error('No data to transform')

  return inputData.map((item) => {
    return {
      id_roles_modulos: item.id,
      moduleId: item.moduloId,
      nombre: item.modulo.nombre,
      ver: item.view,
      leer: item.read,
      escribir: item.write
    }
  })
}
const checkFileExists = async (url) => {
  try {
    const response = await fetch(url)
    if (response.ok) {
      const blob = await response.blob()
      return blob.size > 0
    }
    return false
  } catch (err) {
    console.error('Error fetching the file:', err)
    return false
  }
}
export {
  formatUserData,
  updateNestedItemsActives,
  processErrorMessages,
  obtenerURLBlob,
  saveOnCookies,
  getDataCookie,
  makeArray,
  clearCookies,
  cleanCacheApi,
  calculateDays,
  isDateValid,
  adjustTimeAndAddHours,
  transformDataResponse,
  checkFileExists
}

export * from './form-scshemas'

export * from './calendar-messages-es'
