import React, { Fragment, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { AsyncSelectCustom } from '../../../components/selects/AsyncSelect'
import { useGetSucursalesQuery } from '../../../services/rtk-query/clinicalApi'
import { loadOptions as loadOptionsUtil } from '../../../utils/selects.utils'

export const RegistroEmpresa = ({ control, handleEmpresa, select }) => {
  const [filtering, setFiltering] = useState('')
  const {
    data: sucursales,
    isError,
    isFetching,
    isLoading
  } = useGetSucursalesQuery({ search: filtering })
  const loadOptions = (inputValue, callback) => {
    setFiltering(inputValue)
    return loadOptionsUtil({
      isSuccess: !isError,
      filterFunction: () =>
        sucursales?.data?.map((item) => ({ value: item.id, label: item.nombre })),
      inputValue,
      callback
    })
  }

  return (
    <Fragment>
      <div className="row gx-3 mb-5">
        <div className="form-group col-lg-5 mt-3">
          <label htmlFor="" className="small mb-1">
            Empresa:
          </label>
          <Controller
            name="nombre"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="form-control"
                id="nombre"
                placeholder="Nombre Empresa..."
                {...field}
              />
            )}
          />
        </div>

        <div className="form-group col-lg-5 mt-3">
          <label htmlFor="" className="small mb-1">
            Sucursales:
          </label>
          <Controller
            name="sucursal"
            control={control}
            defaultValue={select}
            render={({ field }) => (
              <AsyncSelectCustom
                {...field}
                placeholder="Sucursal"
                loadOptions={loadOptions}
                isLoading={isLoading}
              />
            )}
          />
        </div>
      </div>
    </Fragment>
  )
}
