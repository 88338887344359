/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import Select from 'react-select'
import { Link, useNavigate, useParams } from 'react-router-dom'
import peticionesAxios from '../../services/peticionesAxios'
import Alertas from '../../services/Alertas'
import { useForm, Controller } from 'react-hook-form'
import AxiosCabeceras from '../../services/AxiosCabeceras'
import AsyncSelect from 'react-select/async'
import { TablePlugin } from '../../plugins/components/TablePlugin'
import { Button, Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { PiTrashSimple } from 'react-icons/pi'
import { toastAdapter } from '../../plugins'

export default function AddRoles() {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nombre: '',
      modulo: null,
      hijos: []
    }
  })
  const [originalModules, setOriginalModules] = useState([])

  const [tabla, setTabla] = useState([])
  const [repetido, setRepetido] = useState()
  const [moduloOptions, setModuloOptions] = useState([])
  const [hijosOptions, setHijosOptions] = useState([])
  const disable = watch('modulo') === null

  useEffect(() => {
    if (id) {
      loadRoles(id)
    }
    loadOptions()
  }, [id])

  const onSubmit = async (data) => {
    const { nombre, modulo, hijos } = data
    const selectedModulos = tabla.map((modulo) => ({ modulo: modulo.value }))
    const selectedHijos = hijos.map((hijo) => ({ modulo: hijo.value }))

    const newModulos = [...selectedModulos, ...selectedHijos].filter((mod) => {
      return !originalModules.some((origMod) => origMod.value === mod.modulo)
    })
    const uniqueModulos = Array.from(new Set(newModulos.map((a) => a.modulo))).map((modulo) => {
      return newModulos.find((a) => a.modulo === modulo)
    })

    const payload = {
      nombre,
      modulos: uniqueModulos
    }

    Alertas.loading_reload(true, id ? 'Actualizando Rol' : 'Guardando Rol')
    const response = id
      ? await peticionesAxios.update2(`roles/${id}`, payload)
      : await peticionesAxios.POST('roles', payload)

    Alertas.loading_reload(false)
    if (response !== false && (response.status === 200 || response.status === 201)) {
      Alertas.alertEmpty(
        id ? 'Rol actualizado con éxito!' : 'Rol guardado con éxito!',
        'Administración de Suyanet',
        'success'
      ).then((resp) => {
        if (resp) {
          navigate('/roles')
        }
      })
    }
  }

  const loadRoles = async (id) => {
    const { data } = await peticionesAxios.find(`roles/${id}`, {
      header: AxiosCabeceras.getHeader()
    })
    const rol = data?.roles[0]
    setValue('nombre', rol?.nombre)
    const modules = data?.modulos?.map((modulo) => ({
      value: modulo.id_modulo,
      label: modulo.nombre,
      estado: 1
    }))

    setTabla(modules ?? [])
    setOriginalModules(modules ?? [])
  }

  const handleDelete = (toDeleteRolId) => {
    console.log({ toDeleteRolId })
    Alertas.QuestionYesNo('¿Deseas eliminar este modulo?', 'Administración de Suyanet').then(
      (resp) => {
        if (resp) {
          Alertas.loading_reload(true)

          peticionesAxios.borrar(`roles/${toDeleteRolId}`).then((result) => {
            Alertas.loading_reload(false)
            if (result !== false && result.status === 200) {
              toastAdapter.success({ message: 'Rol eliminado con éxito' })
              if (id) loadRoles(id)

              const updatedTabla = tabla.filter((item) => item.value !== toDeleteRolId)
              setTabla(updatedTabla)
            }
          })
        }
      }
    )
  }

  const activo = ({ row: { original } }) => (
    <div>
      <label>
        {original.estado ? (
          <span className="badge bg-green-soft text-green">Activado</span>
        ) : (
          <span className="badge bg-red-soft text-red">Desactivado</span>
        )}
      </label>
    </div>
  )

  const columnsDef = [
    { header: 'CÓDIGO', accessorKey: 'value' },
    { header: 'NOMBRE MÓDULOS', accessorKey: 'label' },
    { header: 'ESTADO', accessorKey: 'estado', cell: activo },
    {
      accessorKey: 'actions',
      header: 'ACCIONES',
      cell: ({ row: { original } }) => (
        <Dropdown flip drop="left">
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            <CgMenuLeft />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDelete(original.value)} className="text-danger">
              <PiTrashSimple className="mx-1" /> Eliminar
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  ]

  const handleInputChange = (value) => {
    // console.log(value);
  }

  const handleChange = async (value) => {
    if (value) {
      const response = await peticionesAxios.find(`modulo/hijos/${value.value}`)
      const hijos = response.data?.map((modulo) => ({
        value: modulo.id,
        label: modulo.nombre
      }))
      // setValue('hijos', hijos);
      setHijosOptions(hijos)
    } else {
      // setValue('hijos', []);
      setHijosOptions([])
    }
  }

  const loadOptions = async (inputValue) => {
    const response = await peticionesAxios.find(`modulo/principales?filter=${inputValue}`)

    const options = response.data?.map((modulo) => ({
      value: modulo.id,
      label: modulo.nombre
    }))
    setModuloOptions(options)
    setValue('modulo', options)
    return options
  }

  const tableModulo = () => {
    const { modulo, hijos } = watch()
    const combinedModulos = [...(modulo ? [modulo] : []), ...hijos]
    const existingLabels = tabla?.map((item) => item.label)

    let newEntries = []
    let repeatedEntries = []

    // Check if the main module is in the table
    if (modulo) {
      const isModuloInTable = existingLabels.includes(modulo.label)

      if (isModuloInTable) {
        // Check if the children are in the table
        hijos.forEach((hijo) => {
          if (!existingLabels.includes(hijo.label)) {
            newEntries.push(hijo)
          } else {
            repeatedEntries.push(hijo)
          }
        })
      } else {
        // If main module is not in the table, add it and its children
        newEntries.push(modulo)
        hijos.forEach((hijo) => {
          if (!existingLabels.includes(hijo.label)) {
            newEntries.push(hijo)
          } else {
            repeatedEntries.push(hijo)
          }
        })
      }
    }

    // Update the repeated entries state
    if (repeatedEntries.length > 0) {
      setRepetido(repeatedEntries.map((item) => item.label))
    } else {
      setRepetido(null)
    }

    // Update the table with new entries
    if (newEntries.length > 0) {
      setTabla([...tabla, ...newEntries])
    }
  }

  return (
    <Fragment>
      <LayoutForm
        tools={
          <Link className="btn btn-sm btn-light text-primary" to={'/roles'}>
            <i className="fa fa-arrow-left" /> Volver a la lista de roles
          </Link>
        }
        title={id ? 'Editar Rol' : 'Administracion de roles'}
      >
        <div className="card mb-4 shadow-none">
          <div className="card-header">{id ? 'Editar rol' : 'Agregar rol'}</div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row gx-3 mb-3">
                <div className="border mt-h-75">
                  <div className="row gx-3 mb-3">
                    <div className="col-sm-4">
                      <label className="small mb-1" htmlFor="inputFirstName">
                        Nombre rol:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Nombre"
                        id="nombre"
                        {...register('nombre', { required: true })}
                      />
                      {errors.nombre && (
                        <b style={{ color: '#D14A3F' }} className="mt-2">
                          El nombre es requerido
                        </b>
                      )}
                    </div>
                    <div className="col-sm-4">
                      <label htmlFor="inputFirstName" className="small mb-1">
                        Módulos Principales
                      </label>
                      <Controller
                        name="modulo"
                        control={control}
                        render={({ field }) => (
                          <AsyncSelect
                            {...field}
                            placeholder="Seleccione una opción"
                            cacheOptions
                            defaultOptions
                            loadOptions={loadOptions}
                            onInputChange={handleInputChange}
                            onChange={(value) => {
                              field.onChange(value)
                              handleChange(value)
                            }}
                            isClearable
                          />
                        )}
                      />
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="inputFirstName" className="small mb-1">
                          Módulos Hijos
                        </label>
                        <Controller
                          name="hijos"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              placeholder="Seleccione una opción"
                              options={hijosOptions}
                              closeMenuOnSelect={false}
                              isMulti
                              isDisabled={disable}
                              onChange={(values) => field.onChange(values)}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {repetido &&
                      repetido.map((data) => (
                        <div className="mt-3 col-md-5" key={data}>
                          <li className="alert alert-warning">Módulos asignados {data}</li>
                        </div>
                      ))}
                    <div className="mt-5 col-md-10 text-center">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        onClick={tableModulo}
                      >
                        <span className="fas fa-plus" style={{ marginRight: '10px' }} />
                        AGREGAR
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border">
                  <div className="row gx-3 mb-3">
                    <div className="form-group">
                      <div className="row p-t-20"></div>
                      <div className="card-header border mt-3">Listado de Módulos</div>
                      <div className="border">
                        <div className="row mb-3">
                          <div className="col-lg-13 mt-4">
                            <TablePlugin data={tabla} columns={columnsDef} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-center">
                <button
                  type="button"
                  className="btn btn-secondary btn-lg mr-2"
                  onClick={() => navigate('/roles')}
                  style={{ marginRight: '10px' }}
                >
                  <i className="fa fa-arrow-circle-left"></i> SALIR
                </button>
                <button type="submit" className="btn btn-primary btn-lg">
                  <i className="fa fa-save"></i> Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
