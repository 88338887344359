import React from 'react'
import FormPrincipalInterrogatorio from '../../../registroMedicos/interrogatorio/FormPrincipalInterrogatorio'
import FormPrincipalExterior from '../../../registroMedicos/exporacionFisica/FormPrincipalExterior'
import ListEnfermedades from '../../enfermedades/ListEnfermedades'
import { PreEscripcion } from '../../../persona/components/PreEscripcion'
import NoPermissions from '../../../../components/ui/NoPermissions'
import { SelectTabMessage } from '../../../../components/ui'

export const RenderComponents = ({
  interrogatorio,
  exploracionFisica,
  enfermedadesCIE,
  prescripciones,
  activeTab,
  consultaId
}) => {
  if (!activeTab && consultaId) {
    return <SelectTabMessage />
  }

  if (!interrogatorio && !exploracionFisica && !enfermedadesCIE && !prescripciones) {
    return <NoPermissions /> // Mostrar el componente NoPermissions
  }

  if (interrogatorio) {
    return <FormPrincipalInterrogatorio />
  }
  if (exploracionFisica) {
    return <FormPrincipalExterior />
  }
  if (enfermedadesCIE) {
    return <ListEnfermedades />
  }
  if (prescripciones) {
    return <PreEscripcion />
  }
}
