import React, { useEffect, useState } from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { Alert, Button, Card, CardBody, Col, Row } from 'react-bootstrap'
import { RoleSelected } from './components/RoleSelected'
import { useParams } from 'react-router-dom'
import {
  useAsignPermissionUpdateMutation,
  usePermissionsByRoleIdQuery,
  useRoleByIdQuery
} from '../../services/rtk-query/clinicalApi'
import { Loader } from '../../components/ui/Loader'
import { Link } from 'react-router-dom'

import { TablePermissions } from './components/TablePermissions'
import { transformedData } from './helpers'
import { useForm } from 'react-hook-form'
import { toastAdapter } from '../../plugins/hot-toast.plugin'
import { useNavigate } from 'react-router-dom'

export const RoleManagementChange = () => {
  const [finalData, setFinalData] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()
  const [asignPermissions] = useAsignPermissionUpdateMutation()

  const {
    data: permissions,
    isSuccess: permissionIsSuccess,
    isError,
    isLoading
  } = usePermissionsByRoleIdQuery(id)
  const { data, isError: roleError, isSuccess: roleSuccess } = useRoleByIdQuery(id)

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      rows: []
    }
  })

  useEffect(() => {
    if (permissions && data) {
      const permissionModuleIds = new Set(permissions.map(({ moduleId }) => moduleId))

      const formattedData = permissions.map(({ moduleId, escribir, leer, ver, nombre }) => {
        return {
          moduleId,
          nombre,
          escribir,
          leer,
          ver
        }
      })
      const additionalModules = data.modulos
        .filter((module) => {
          return !permissionModuleIds.has(module.moduleId)
        })
        .map((module) => ({
          moduleId: module.moduleId,
          nombre: module.nombre,
          escribir: false,
          leer: false,
          ver: false
        }))

      const finalData = [...formattedData, ...additionalModules]

      setFinalData(finalData)
      reset({ rows: finalData })
    }
  }, [permissions, data, reset])

  const onSubmit = (payload) => {
    const modulos = transformedData(payload.rows)
    const objectDto = {
      modulo: modulos,
      rol: data.roles[0].id_rol,
      id: data.roles[0].id_rol
    }

    toastAdapter.promise({
      promise: asignPermissions(objectDto).unwrap(),
      loadingMessage: 'Guardando...',
      successMessage: (data) => {
        reset()
        navigate('/roles')
        return 'Permisos asignados correctamente'
      },
      errorMessage: 'Error al asignar permisos'
    })
  }

  return (
    <>
      <LayoutForm title="Administración de Roles">
        <header className="mb-2">
          <h5>Administracion de Roles</h5>
          <p>Seleccionar permisos a los modulos que tendra acceso este rol</p>
        </header>
        <Col md={12}>
          <Card className="m-2 shadow-none">
            <CardBody>
              {roleError && <Alert>Error al cargar el Rol </Alert>}
              {roleSuccess && <RoleSelected data={data} />}
            </CardBody>
          </Card>
        </Col>

        <Row>
          {isLoading && <Loader />}
          {isError && <Alert>Error al cargar los Modulos </Alert>}
          <Col md={8}>
            {permissionIsSuccess && <TablePermissions control={control} modules={finalData} />}
          </Col>
          {/* <DevTool control={control} /> */}
          <Col md={4}>
            <div className="d-grid gap-2">
              <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                Guardar
              </Button>
              <Link to={'/roles'} className="btn btn-outline-secondary" onClick={() => reset()}>
                Cancelar
              </Link>
            </div>
          </Col>
        </Row>
      </LayoutForm>
    </>
  )
}
