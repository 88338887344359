import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ModalGenerico } from '../../../../components/ui'
import { ACTIONS } from '../../../../constants'
import { useSelector } from 'react-redux'

export const ModalComplicaciones = ({
  handleSubmit,
  handlerAction,
  action,
  reset,
  control,
  errors,
  setValue,
  register
}) => {
  const { activeComplication } = useSelector((state) => state.ui)

  useEffect(() => {
    if (activeComplication) {
      setValue('nombre', activeComplication.nombre)
      setValue('id', activeComplication.id)
    }
  }, [activeComplication])

  return (
    <ModalGenerico title="Complicaciones" closeButtonAction={reset}>
      <Form onSubmit={handleSubmit(handlerAction)}>
        <Form.Group className="mb-2">
          <Form.Label>Nombre de la complicacion</Form.Label>
          {/* <Controller
            control={control}
            name='nombre'
            render={({ field }) => ( */}
          <Form.Control
            type="text"
            placeholder="Nombre de la Complicacion..."
            {...register('nombre')}
          />
          {/* )}
          /> */}
          {errors.nombre && (
            <Form.Text className="text-danger">{errors?.nombre?.message}</Form.Text>
          )}
        </Form.Group>
        <Button
          variant="primary "
          size="md"
          style={{
            minWidth: '15%'
          }}
          type="submit"
        >
          {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
        </Button>
      </Form>
    </ModalGenerico>
  )
}
