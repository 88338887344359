import React, { Fragment, useEffect } from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { Link, useParams, useNavigate } from 'react-router-dom'
import peticionesAxios from '../../services/peticionesAxios'
import Alertas from '../../services/Alertas'
import { useForm, Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

export default function AddModulos() {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
      nombre: '',
      es_principal: false,
      principal: null
    }
  })

  useEffect(() => {
    if (id) {
      handleLoadModul(id)
    }
  }, [id])

  const onSubmit = (modulo) => {
    const principal = modulo.principal?.value ?? 0
    let data = {
      nombre: modulo.nombre,
      es_principal: modulo.es_principal,
      principal: !modulo.es_principal ? principal : 0
    }

    if (id) {
      Alertas.loading_reload(true, 'Actualizando módulo')
      peticionesAxios.update2(`modulo/${id}`, data).then((result) => {
        Alertas.loading_reload(false)
        if (result !== false) {
          if (result.status === 200) {
            reset()
            Alertas.alertEmpty(
              'Módulo actualizado con éxito!',
              'Administración de suyanet',
              'success'
            ).then((resp) => {
              if (resp) {
                navigate('/modulos')
              }
            })
          }
        }
      })
    } else {
      Alertas.loading_reload(true, 'Guardando Modulo')
      peticionesAxios.POST('modulo', data).then((result) => {
        Alertas.loading_reload(false)

        if (result !== false) {
          if (result.status === 201 || result.status === 200) {
            reset()
            Alertas.alertEmpty(
              'Módulo guardado con éxito!',
              'Administración de suyanet',
              'success'
            ).then((resp) => {
              if (resp) {
                navigate('/modulos')
              }
            })
          }
        } else {
          Alertas.alertEmpty('Error al guardar el módulo', 'Administración de suyanet', 'error')
        }
      })
    }
  }

  const moduloPrincipales = async (inputValue) => {
    try {
      const esPrincipal = await peticionesAxios.find(`modulo/principales?filter=${inputValue}`)
      const data = esPrincipal.data?.map((modulo) => ({
        value: modulo.id,
        label: modulo.nombre
      }))
      return data
    } catch (error) {
      return error
    }
  }

  const handleLoadModul = async (id) => {
    try {
      const modul = await peticionesAxios.find(`modulo/${id}`)
      const result = {
        nombre: modul.data.nombre,
        es_principal: modul.data.esPrincipal,
        principal: modul.data.principal
          ? {
              value: modul.data.principal?.id_modulo,
              label: modul.data.principal?.nombre
            }
          : null
      }

      // if (result.es_principal === true) {
      //   document.getElementById('es_principal').checked = true;
      // }
      setValue('nombre', result.nombre)
      setValue('es_principal', result.es_principal)
      setValue('principal', result.principal)
    } catch (error) {
      return error
    }
  }

  return (
    <Fragment>
      <LayoutForm
        tools={
          <Link className="btn btn-sm btn-light text-primary" to={'/modulos'}>
            <i className="fa fa-arrow-left" />
            Regresar a la lista de módulo
          </Link>
        }
        title={id ? 'Edito de módulo' : 'Administracion de módulo'}
      >
        <div className="card mb-4 shadow-none">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-body">
                <h3 className="card-title">Informacion del módulos</h3>
                <hr />
                <div className="row p-t-20">
                  <div className="col-md-5 my-2">
                    <div className="form-group">
                      <label className="control-label">Nombre del modulo:</label>
                      <input
                        type="text"
                        id="nombre"
                        className="form-control"
                        {...register('nombre', {
                          required: true
                        })}
                        placeholder="Nombre del módulos"
                      />
                      {errors.nombre?.type === 'required' && (
                        <b style={{ color: '#D14A3F' }} className="mt-2">
                          El nombre es requerido
                        </b>
                      )}
                    </div>
                  </div>

                  <div className="col-md-2 my-2 mt-2">
                    <div className="form-group has-danger">
                      <div className="checkbox checkbox-success">
                        <label className="small mb-1">módulo principal:</label>
                        <div className="boder ">
                          <div className=" form-check form-switch">
                            <Controller
                              name="es_principal"
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setValue('principal', null)
                                  }}
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="es_principal"
                                  name="es_principal"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 my-2 mt-2">
                    <div className="form-group has-danger">
                      <label className="small mb-1">Módulo Principales:</label>
                      <div className="boder ">
                        <Controller
                          name="principal"
                          control={control}
                          render={({ field }) => (
                            <AsyncSelect
                              {...field}
                              isDisabled={watch('es_principal')}
                              cacheOptions
                              defaultOptions
                              placeholder="Seleccione una opción"
                              closeMenuOnSelect
                              loadOptions={moduloPrincipales}
                              onChange={(selected) => field.onChange(selected)}
                              isClearable
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-center">
                <button
                  className="btn btn-secondary btn-lg mr-2"
                  onClick={() => {
                    navigate('/modulos')
                  }}
                  style={{ marginRight: '10px' }}
                  type="button"
                >
                  <i className="fa fa-arrow-circle-left"></i> SALIR
                </button>
                <button className="btn btn-primary btn-lg" type="submit">
                  <i className="fa fa-save"></i> Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
