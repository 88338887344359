import React, { useState } from 'react'
import { TablePlugin } from '../../plugins/components/TablePlugin'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa6'
import { useConsultasPorExpedienteQuery } from '../../services/rtk-query/clinicalApi'
import { SanitizeRichText } from '../../components/ui/SanitizeRichText'
import { OptionsColumnConsultas } from './enfermedades/components/OptionsColumnConsultas'
import { useDispatch } from 'react-redux'
import { setInterrogatorioTab } from '../../store/slices/uiSlice'
import { ModalPreviewReport } from '../../components/modals/ModalPreviewReport'
import WithPermissions from '../../HOCs/WithPermissions'
import { ALL_PERMISSIONS, modules } from '../../constants'
const columns = [
  { header: 'id', accessorKey: 'id' },
  {
    header: 'estudios',
    accessorKey: 'estudios',
    cell: ({ row: { original } }) => <SanitizeRichText html={original.estudios} />
  },
  {
    header: 'observaciones',
    accessorKey: 'observaciones',
    cell: ({ row: { original } }) => <SanitizeRichText html={original.observaciones} />
  },
  {
    header: 'recetas',
    accessorKey: 'recetas',
    cell: ({ row: { original } }) => <SanitizeRichText html={original.recetas} />
  },
  {
    header: 'Opciones',
    accessorKey: 'id',
    cell: ({ row: { original } }) => <OptionsColumnConsultas cell={original?.id} />
  }
]
export default function ListadoConsultas() {
  const [filtering, setFiltering] = useState('')
  const { id = null } = useParams()
  const { data, isFetching } = useConsultasPorExpedienteQuery(id)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(setInterrogatorioTab())

    navigate(`/expedientes-medicos/${id}/consultas/nueva`, {})
  }

  return (
    <>
      <Container fluid>
        <WithPermissions requiredPermissions={ALL_PERMISSIONS} module={modules.expediente_medicos}>
          <Row className="justify-content-end">
            <Col className="text-end">
              <Button onClick={handleClick} size="lg" className="ml-auto mb-3 ">
                <FaPlus /> Nueva Consulta
              </Button>
            </Col>
          </Row>
        </WithPermissions>
        <Row>
          <Col>
            <TablePlugin
              columns={columns}
              data={data}
              filtering={filtering}
              onFilteringChange={(data) => setFiltering(data)}
              isFetching={isFetching}
              isInternalFiltering={false}
            />
          </Col>
        </Row>
      </Container>
      <ModalPreviewReport />
    </>
  )
}
