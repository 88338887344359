import React from 'react'
import Alergias from '../Alergias'
import FormTipoSangre from '../../tipoSangre/TipoSangre'
import FormGinecológicos from '../../Ginecologicos/FormGinecológicos'
import FormPrincipa from '../../Obstetricos/FormPrincipa'
import FormPrincipalPatologico from '../../patologico/FormPricnipalPatologico'
import FormPrincipalHereditario from '../../hereditarios/FormPrinciaplHer'
import FormPerinatales from '../../perinatales/FormPerinatales'
import { SelectTabMessage } from '../../../../../components/ui/SelectOption'
import { usePermissions } from '../../../../../hooks/usePermissions'
import { ALL_PERMISSIONS, modules } from '../../../../../constants'
import NoPermissions from '../../../../../components/ui/NoPermissions'

export const RenderOptions = ({ alergias = {} }) => {
  const { hasPermission } = usePermissions()
  const notHasPermissions =
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_alergias) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_hereditarios) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_patologias) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_no_patologicos) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_perinatales) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_ginecologicos) &&
    !hasPermission(ALL_PERMISSIONS, modules.antecedentes_obstetricos)

  if (notHasPermissions) {
    return <NoPermissions showReturnButton={false} />
  }

  if (alergias.alergias) {
    return <Alergias />
  }
  if (alergias.noPatologico) {
    return <FormTipoSangre />
  }
  if (alergias.ginecologicos) {
    return <FormGinecológicos />
  }
  if (alergias.obstericos) {
    return <FormPrincipa />
  }
  if (alergias.patologico) {
    return <FormPrincipalPatologico />
  }
  if (alergias.heredidatorio) {
    return <FormPrincipalHereditario />
  }
  if (alergias.perenitales) {
    return <FormPerinatales />
  }
  return <SelectTabMessage />
}
