import React from 'react'
import { TablePlugin } from '../../../../plugins/components/TablePlugin'
import { useGetAlteracionMenstrualQuery } from '../../../../services/rtk-query/clinicalApi'

export const Lista = ({ columns }) => {
  const { data, isFetching } = useGetAlteracionMenstrualQuery()
  return (
    <>
      <TablePlugin data={data ?? []} columns={columns} isFetching={isFetching} />
    </>
  )
}
