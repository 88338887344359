const formatCreatePatient = (data) => {
  const {
    paciente: { value: pacienteId },
    profesion,
    // descripcion,
    responsable
  } = data

  const { nombre, parentesco, telefono, tipoDocumento, numeroDocumento } = responsable
  return {
    personaId: pacienteId,
    nombreResponsable: nombre,
    direccionTrabajo: '',
    profesion,
    telefonoResponsable: telefono,
    parentescoPersona: parentesco?.value,
    tipoDocumento: tipoDocumento?.value ?? null,
    numeroDocumento,
    requiredUpdate: true
  }
}
export { formatCreatePatient }
