import PropTypes from 'prop-types'
import React from 'react'
import { TbFileReport } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { setPacienteTab, setShowToastLoader, setUri } from '../../../../store/slices/uiSlice'
import { toastAdapter } from '../../../../plugins/hot-toast.plugin'
import { useReports } from '../../../../hooks/useReports'
import { obtenerURLBlob } from '../../../../utils'
import { useSearchParams } from 'react-router-dom'
import { handlerFetcherDoctor } from '../../../../helpers'
import { useChangeDoctorMutation, useLazyMedicosQuery } from '../../../../services/rtk-query'
import { usePermissions } from '../../../../hooks'
import { modules, PERMISSIONS } from '../../../../constants'
import { TfiExchangeVertical } from 'react-icons/tfi'

export const OptionsColumns = ({ id, medicoId }) => {
  const { hasPermission } = usePermissions()
  const [doctorQuery] = useLazyMedicosQuery()
  const [changeDoctorMutation] = useChangeDoctorMutation()
  const { handleFetch } = useReports()
  const [searchParam] = useSearchParams({ activeTab: 'paciente' })
  const navigator = useNavigate()
  const dispatch = useDispatch()
  const onEvent = () => {
    dispatch(setPacienteTab())
    navigator({
      pathname: `/expedientes-medicos/${id}`,
      search: searchParam.toString()
    })
  }

  const generarReporte = async () => {
    toastAdapter.promise({
      promise: handleFetch({
        url: `consultas/reporte/${id}/medico/${medicoId}`
      }),
      successMessage: (data) => {
        const uriBlog = obtenerURLBlob(data)
        dispatch(setUri(uriBlog))
        dispatch(setShowToastLoader())
        return 'Reporte generado'
      },
      errorMessage: 'Error al generar reporte...',
      loadingMessage: 'Generando Reporte...'
    })
  }

  const handleChangeDoctor = async () => {
    const doctor = await handlerFetcherDoctor(doctorQuery)
    if (!doctor) return

    toastAdapter.promise({
      promise: changeDoctorMutation({
        id,
        doctorId: +doctor
      }).unwrap(),
      loadingMessage: 'Actualizando Medico...',
      successMessage: 'Medico actualizado exitosamente',
      errorMessage: (error) => {
        if (error.message) {
          return error.message.join(', ')
        }

        return 'Error al actualizar medico'
      }
    })
  }

  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onEvent}>
          <FiEdit /> Actualizar
        </Dropdown.Item>

        <Dropdown.Item onClick={generarReporte}>
          <TbFileReport /> Generar Reporte
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleChangeDoctor}
          className={classNames('text-center ', {
            'opacity ': !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)
          })}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
        >
          <TfiExchangeVertical /> <small className="mx-1">Cambiar Medico</small>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
PropTypes.OptionsColumns = {
  onEvent: PropTypes.func.isRequired
}
