/** @format */

import React, { Fragment, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import { useGetIdentityCardQuery, useGetRelationshipsQuery } from '../../../services/rtk-query'
import { useParams } from 'react-router-dom'

export default function FormInfoResponsanle({ errors, control, setValue, getValues }) {
  const { id = null } = useParams()
  const { data, isSuccess, isError, isLoading, isFetching } = useGetIdentityCardQuery()
  const {
    data: relationShips,
    isError: isRelationError,
    isLoading: isRelationLoading,
    isSuccess: isRelationSuccess
  } = useGetRelationshipsQuery()
  const { activePatient } = useSelector((state) => state.informacionBasicaPaciente)

  useEffect(() => {
    if (activePatient) {
      if (!isFetching) {
        const document = data?.find(
          (document) => document.id === activePatient?.tipoDocumentoParentesco
        )
        if (!document) return

        setValue('responsable.tipoDocumento', {
          label: document.value,
          value: document.id
        })
        return
      }
    }
  }, [activePatient, data, isFetching, setValue])

  useEffect(() => {
    if (activePatient) {
      if (!activePatient?.parentesco) {
        setValue('responsable.parentesco', null)
        return
      }
      setValue('responsable.parentesco', {
        label: activePatient?.parentesco?.nombre,
        value: activePatient?.parentesco?.id
      })
      return
    }
  }, [activePatient, setValue])

  useEffect(() => {
    if (activePatient) {
      setValue('responsable.nombre', activePatient?.responsable ?? activePatient.nombreCompleto)
    }
  }, [activePatient, setValue])

  useEffect(() => {
    if (activePatient) {
      setValue('responsable.telefono', activePatient?.telefonoParentesco)
    }
  }, [activePatient, setValue])

  useEffect(() => {
    if (activePatient) {
      setValue('responsable.numeroDocumento', activePatient?.numeroDocumentoParentesco)
    }
  }, [activePatient, setValue])

  return (
    <Fragment>
      <Form as={'article'} className="px-4">
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre Completo</Form.Label>
              <Controller
                name="responsable.nombre"
                control={control}
                render={({ field }) => (
                  <Form.Control {...field} placeholder="Ingrese el nombre del responsable" />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Telefono</Form.Label>
              <Controller
                name="responsable.telefono"
                control={control}
                render={({ field }) => (
                  <Form.Control {...field} placeholder="Ingrese el telefono del responsable" />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Parentesco con Paciente</Form.Label>
              <Controller
                name="responsable.parentesco"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Seleccione un parentesco"
                    isClearable
                    isLoading={isRelationLoading}
                    options={
                      isRelationError
                        ? [{ label: 'Error al cargar Parentescos', value: -2 }]
                        : isRelationLoading
                          ? [{ label: 'cargando...', value: -2 }]
                          : isRelationSuccess &&
                            relationShips?.map((parentesco) => {
                              return {
                                label: parentesco.value,
                                value: parentesco.id
                              }
                            })
                    }
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Tipo de Documento</Form.Label>
              <Controller
                name="responsable.tipoDocumento"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Seleccione un tipo de documento"
                    isLoading={isLoading}
                    options={
                      isError
                        ? [{ label: 'Error al cargar Documentos', value: -2 }]
                        : isLoading
                          ? [{ label: 'cargando...', value: -2 }]
                          : isSuccess &&
                            data?.map((documentos) => ({
                              label: documentos.value,
                              value: documentos.id
                            }))
                    }
                    className={errors?.responsisErrorable?.tipoDocumento && 'is-invalid'}
                    isClearable
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Numero de Documento</Form.Label>
              <Controller
                name="responsable.numeroDocumento"
                control={control}
                render={({ field }) => (
                  <Form.Control {...field} placeholder="Ingrese el numero de documento" />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}
