/** @format */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import 'jspdf-autotable'
import { useDispatch } from 'react-redux'
import { setPacienteTab } from '../../store/slices/uiSlice'

export default function ExportarYAgregar({ ruta, nombre, search, ...rest }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
        <button
          onClick={() => {
            dispatch(setPacienteTab())
            navigate({
              pathname: ruta,
              search
            })
          }}
          className={'btn btn-primary'}
          disabled={rest.hasPermission}
          type="button"
        >
          <span className="fa fa-plus" style={{ marginRight: '10px' }}></span>
          {nombre}
        </button>
      </div>
    </React.Fragment>
  )
}
