import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarModalGenerico } from '../../store/slices/uiSlice'
const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const ModalGenerico = ({
  children,
  title,
  buttonCustom: ButtonCustom,
  closeButtonAction,
  style = {}
}) => {
  const { modalGenerico } = useSelector((state) => state.ui)
  const dispatch = useDispatch()
  const handleHideModal = () => {
    dispatch(cerrarModalGenerico())
    closeButtonAction()
  }
  return (
    <Modal
      scrollable
      centered
      size="lg"
      show={modalGenerico}
      backdrop="static"
      keyboard={false}
      style={modalStyle}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={style}>{children}</Modal.Body>
      <Modal.Footer>
        {ButtonCustom && <ButtonCustom />}
        <Button
          type="button"
          variant="outline-primary"
          size="md"
          style={{
            minWidth: '15%'
          }}
          onClick={handleHideModal}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
