import axios from 'axios'

const getToken = () => {
  const token = localStorage.getItem('token')
  if (!token) return
  return token
}
const privateUrl = process.env.REACT_APP_RUTA
export const useReports = () => {
  const handleFetch = async ({ url = undefined, queryParams = {} }) => {
    if (!url) throw new Error('Url is required')

    if (Object.keys(queryParams).length) {
      url = `${url}?${new URLSearchParams(queryParams).toString()}`
    }

    const completeUrl = `${privateUrl}${url}`
    const { data } = await axios.get(completeUrl, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      },
      responseType: 'blob'
    })
    return data
  }
  return {
    handleFetch
  }
}
