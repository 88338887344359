import React, { Fragment, useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  setEnfermedadesCIETab,
  setExploracionFisicaTab,
  setInterrogatorioTab,
  setPrescripcionTab,
  toggleConsultasTab
} from '../../../store/slices/uiSlice'
import { TbUserQuestion } from 'react-icons/tb'
import { MdTravelExplore } from 'react-icons/md'
import style from './style.module.css'
import { useSearchParams } from 'react-router-dom'
import { consultaTabs, modules, PERMISSIONS } from '../../../constants'
import { useParams } from 'react-router-dom'
import WithPermissions from '../../../HOCs/WithPermissions'
const tabsConfig = [
  {
    id: consultaTabs.INTERROGATORIO,
    subId: 'consulta_interrogatorio',
    label: 'Interrogatorio',
    icon: <TbUserQuestion />,
    action: setInterrogatorioTab(),
    active: (tabsExpediente) => tabsExpediente.interrogatorio
  },
  {
    id: consultaTabs.EXPLORACION_FISICA,
    subId: 'consulta_exploracion_fisica',
    label: 'Exploración Física',
    icon: <MdTravelExplore />,
    action: setExploracionFisicaTab(),
    active: (tabsExpediente) => tabsExpediente.exploracionFisica
  },
  {
    id: consultaTabs.ENFERMEDADES_CIE,
    subId: 'consulta_enfermedad_cie',
    label: 'Enfermedades CIE',
    icon: <MdTravelExplore />,
    action: setEnfermedadesCIETab(),
    active: (tabsExpediente) => tabsExpediente.enfermedadesCIE
  },
  {
    id: consultaTabs.PRESCRIPCION,
    subId: 'consulta_prescripciones',
    label: 'Prescripciones',

    icon: <MdTravelExplore />,
    action: setPrescripcionTab(),
    active: (tabsExpediente) => tabsExpediente.prescripciones
  }
]
export default function HeaderConsulta(props) {
  const { tabsExpediente, disableTabsConsulta } = useSelector((state) => state.ui)
  const { consultaId } = useParams()
  const [, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  const handleChangeTab = (tab, tabName) => {
    if (!disableTabsConsulta) {
      dispatch(tab)
      setSearchParams({ activeTab: tabName })
    }
  }
  useEffect(() => {
    if (!consultaId) {
      dispatch(toggleConsultasTab())
    }
  }, [])

  return (
    <Fragment>
      <Nav variant="pills">
        {tabsConfig.map((tab) => (
          <WithPermissions
            module={modules?.[tab.subId]}
            requiredPermissions={[PERMISSIONS.VIEW, PERMISSIONS.READ]}
          >
            <Nav.Item
              key={tab.id}
              className={disableTabsConsulta && style.disabled}
              onClick={() => handleChangeTab(tab.action, tab.id)}
            >
              <Nav.Link active={tab.active(tabsExpediente)}>
                {tab.icon} {tab.label}
              </Nav.Link>
            </Nav.Item>
          </WithPermissions>
        ))}
      </Nav>
    </Fragment>
  )
}
