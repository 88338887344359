import React, { useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Alert, Card, CardBody } from 'react-bootstrap'
import { Header } from '../../../components/Containers/Header'
import { Lista, Options } from './components'
import { activarModalGenerico, clearActiveAllergic } from '../../../store/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { ACTIONS, modules, PERMISSIONS } from '../../../constants'
import { toastAdapter } from '../../../plugins'
import { useCreateAllergicsMutation, useUpdateAllergicsMutation } from '../../../services/rtk-query'
import { useForm } from 'react-hook-form'
import { ModalAlergias } from './components/ModalAlergias'
import { yupResolver } from '@hookform/resolvers/yup'
import { enfermededHereditariaSchema } from '../../../utils'
import { usePermissions } from '../../../hooks'

export const AlergiasPage = () => {
  const { hasPermission } = usePermissions()
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const [createAllergics] = useCreateAllergicsMutation()
  const [updateAllercsMutation] = useUpdateAllergicsMutation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(enfermededHereditariaSchema),
    defaultValues: {
      nombre: '',
      descripcion: '',
      id: 0
    }
  })
  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id'
      },
      {
        header: 'nombre',
        accessorKey: 'nombre'
      },
      {
        header: 'Activo',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Alert
              variant="primary"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              Si
            </Alert>
          ) : (
            <Alert
              variant="danger"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              No
            </Alert>
          )
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => (
          <Options
            hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.alergias)}
            cell={original.id}
            setAction={setAction}
            activo={original.activo}
          />
        )
      }
    ],
    []
  )
  const handler = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    if (action === ACTIONS.SAVE) return handleSave(data)

    return handleUpdate(data)
  }

  const handleSave = (data) => {
    const { id, ...rest } = data
    toastAdapter.promise({
      promise: createAllergics(rest).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)
        dispatch(clearActiveAllergic())
        return 'Alergia guardada con éxito'
      },
      errorMessage: 'Error al guardar la Alergia',
      loadingMessage: 'Guardando Alergia...'
    })
  }

  const handleUpdate = (data) => {
    toastAdapter.promise({
      promise: updateAllercsMutation({ ...data, id: +getValues('id') }).unwrap(),
      successMessage: () => {
        handleReset()
        return 'Alergia guardada con éxito'
      },
      errorMessage: 'Error al guardar la Alergia',
      loadingMessage: 'Guardando Alergia...'
    })
  }

  const handleReset = () => {
    reset()
    setAction(ACTIONS.SAVE)
    dispatch(clearActiveAllergic())
  }

  return (
    <LayoutForm title="Alergias">
      <Card className="shadow-none">
        <Header
          title="Alergias"
          subtitle=""
          buttonTitle="Nueva Alergia"
          handler={handler}
          hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.alergias)}
        />
        <CardBody>
          <Lista columns={columns} />
        </CardBody>
      </Card>
      <ModalAlergias
        action={action}
        control={control}
        errors={errors}
        handlerAction={handlerAction}
        handleSubmit={handleSubmit}
        reset={handleReset}
        setValue={setValue}
      />
    </LayoutForm>
  )
}
