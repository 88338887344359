import { PermissionsContext } from '../context/permissionsContext'
import React, { useContext } from 'react'

const WithPermissions = ({ requiredPermissions, children, module }) => {
  const context = useContext(PermissionsContext)
  const { user, hasPermission } = context

  if (!user || !user.permisos) {
    return <p>Missing permissions data. Please contact your administrator.</p> //? Handle missing permissions data gracefully
  }

  const hasViewPermission = hasPermission(requiredPermissions, module) // Check for 'view' first

  if (!hasViewPermission) {
    return null
  }

  const hasAllRequiredPermissions = hasPermission(requiredPermissions, module) // Check all required permissions

  return hasAllRequiredPermissions ? children : <p>Insufficient permissions.</p> // Conditional rendering
}

export default WithPermissions
