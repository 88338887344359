/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import { PiTrashSimple } from 'react-icons/pi'
import {
  useConsultaDiagnosticoQuery,
  useDeleteDiagnosticoMutation
} from '../../../../services/rtk-query'
import { useDispatch } from 'react-redux'
import { setDiagnosticoActivo, toggleModal } from '../../../../store/slices/uiSlice'
import { toastAdapter } from '../../../../plugins/hot-toast.plugin'
import { alertConfirm } from '../../../../plugins'
import { PermissionsContext } from '../../../../context/permissionsContext'
import { modules, PERMISSIONS } from '../../../../constants'
import classNames from 'classnames'
export const OptionsEnfermedades = ({ cell }) => {
  const { hasPermission } = useContext(PermissionsContext)
  const dispatch = useDispatch()
  const [skip, setSkip] = useState(true)

  const { data, isSuccess, isFetching } = useConsultaDiagnosticoQuery(cell, {
    skip,
    refetchOnMountOrArgChange: true
  })
  const [deleteDiagnostico] = useDeleteDiagnosticoMutation()

  const onEvent = () => {
    setSkip(false)
    // setSkip(true);
  }

  const onDelete = () => {
    alertConfirm({
      title: 'Desea eliminar este diagnostico?',
      text: 'No podra eliminar revertir esto',
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((resp) => {
      if (!resp) return
      toastAdapter
        .promise({
          promise: deleteDiagnostico({ id: cell }).unwrap(),
          loadingMessage: 'Eliminando diagnostico',
          successMessage: 'Diagnostico eliminado',
          errorMessage: 'Error al intentar eliminar diagnostico'
        })
        .finally(() => {
          setSkip(true)
        })
    })
  }

  // Actualizar el estado con los datos de la consulta cuando la consulta tenga éxito
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setDiagnosticoActivo(data))
        dispatch(toggleModal())
        setSkip(true)
      }, 300)
    }
  }, [isSuccess, data, dispatch, isFetching])

  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onEvent}>
          <FiEdit /> Actualizar
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onDelete}
          className={classNames('text-danger', {
            opacity: !hasPermission([PERMISSIONS.WRITE], modules.consulta_enfermedad_cie)
          })}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.consulta_enfermedad_cie)}
        >
          <PiTrashSimple /> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
