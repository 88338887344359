import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { encrypt } from '../../../../../services/Encriptaciones'
import { useResetStore } from '../../../../../hooks/useResetStore'
import { UUIDS } from '../../../../../constants'
import { TbReport } from 'react-icons/tb'

export function GeneralesMenu() {
  const permisos = encrypt.getSession('modulos')

  const resetStore = useResetStore()

  return (
    <Fragment>
      {permisos.includes(UUIDS.generales) && (
        <>
          <NavLink
            className={'nav-link collapsed'}
            to={'"javascript:void(0);"'}
            data-bs-toggle="collapse"
            data-bs-target="#collapseGenerales"
            aria-expanded="false"
            aria-controls="collapseGenerales"
          >
            <article className="nav-link-icon">
              <TbReport size={28} />
            </article>
            Registros
            <div className="sidenav-collapse-arrow">
              <i className="fas fa-angle-down" />
            </div>
          </NavLink>
          <div className={'collapse'} id="collapseGenerales" data-bs-parent="#accordionSidenav">
            <nav className="sidenav-menu-nested nav">
              {permisos.includes(UUIDS.generales) && (
                <>
                  {permisos.includes(UUIDS.hereditarios) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/enfermedades-hereditarias"
                      onClick={resetStore}
                    >
                      Enfermedades Hereditarias
                    </Link>
                  )}
                  {permisos.includes(UUIDS.alergias) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/alergias"
                      onClick={resetStore}
                    >
                      Alergias
                    </Link>
                  )}
                  {permisos.includes(UUIDS.complicaciones) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/complicaciones"
                      onClick={resetStore}
                    >
                      Complicaciones
                    </Link>
                  )}

                  {permisos.includes(UUIDS.menstruacion) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/alteraciones-menstruales"
                      onClick={resetStore}
                    >
                      Alteraciones Menstruales
                    </Link>
                  )}
                  {permisos.includes(UUIDS.enfermedadesTransmisionSexual) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/enfermedades-de-transmision-sexual"
                      onClick={resetStore}
                    >
                      Enfermedades de Transmisión Sexual
                    </Link>
                  )}
                  {permisos.includes(UUIDS.anticonceptivos) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/anticonceptivos"
                      onClick={resetStore}
                    >
                      Anticonceptivos
                    </Link>
                  )}
                  {permisos.includes(UUIDS.sintomasAlergias) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/sintomas-de-alergias"
                      onClick={resetStore}
                    >
                      Sintomas de Alergias
                    </Link>
                  )}
                  {permisos.includes(UUIDS.patologias) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/patologias"
                      onClick={resetStore}
                    >
                      Patologias
                    </Link>
                  )}
                  {permisos.includes(UUIDS.enfermedadesPatologicas) && (
                    <Link
                      style={{
                        fontSize: '0.8rem'
                      }}
                      className="nav-link "
                      to="/registros/enfermedades-patologicas"
                      onClick={resetStore}
                    >
                      Enfermedades Patologicas
                    </Link>
                  )}
                </>
              )}
            </nav>
          </div>
        </>
      )}
    </Fragment>
  )
}
