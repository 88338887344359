import React from 'react'
import PropTypes from 'prop-types'
import { Card, Button } from 'react-bootstrap'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { GrDocumentWord } from 'react-icons/gr'
const DocumentCard = ({ documentNameDefault, onDelete }) => {
  return (
    <Card className="mb-3">
      <Card.Body className="d-inline-flex ">
        <GrDocumentWord size={25} className="text-muted" />
        <Card.Title className="mb-0 text-break px-3 ">{documentNameDefault}</Card.Title>
        {onDelete && (
          <Button variant="outline-danger" onClick={onDelete}>
            <IoCloseCircleOutline size={20} />
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}

DocumentCard.propTypes = {
  documentName: PropTypes.string.isRequired,
  onDelete: PropTypes.func
}

export default DocumentCard
