import React from 'react'
import { Form } from 'react-bootstrap'

export const AllergyCheckbox = ({
  sintoma,
  selectedAlergiaId,
  tipoAlergias,
  handleSymptomToggle
}) => {
  const isChecked = tipoAlergias
    .find((alergia) => alergia.id === selectedAlergiaId)
    ?.selectedSymptoms.some((symptom) => symptom.id === sintoma.id)

  return (
    <div className="m-2" key={sintoma.id}>
      <Form.Check // prettier-ignore
        type={'checkbox'}
        id={`checkbox-${sintoma.id}-${selectedAlergiaId}`}
        label={sintoma.nombre}
        key={sintoma.id}
        value={sintoma.id}
        checked={isChecked}
        name="allergy-symptom"
        data-name={sintoma.nombre}
        onChange={(e) => handleSymptomToggle(e, sintoma.id, sintoma.nombre, selectedAlergiaId)}
      />
      {/* <Form.Check
        key={sintoma.id}
        id={`checkbox-${sintoma.id}-${selectedAlergiaId}`}
        className='form-check-input me-1 mt-2 d-flex flex-wrap flex-grow-1'
        type='checkbox'
        value={sintoma.id}
        checked={isChecked}
        name='allergy-symptom'
        data-name={sintoma.nombre}
        onChange={(e) =>
          handleSymptomToggle(e, sintoma.id, sintoma.nombre, selectedAlergiaId)
        }
        label={sintoma.nombre}
      /> */}
    </div>
  )
}
