/** @format */

import React from 'react'
import { Link } from 'react-router-dom'
import UserNavBar from './UserNavBar'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
export default function NavBar() {
  return (
    <nav
      className=" navbar navbar-expand justify-content-between justify-content-sm-start navbar-light bg-light"
      id="sidenavAccordion"
    >
      <div>
        <Link
          className=" text-break p-2 font-weight-bold"
          style={{
            fontSize: '1.5rem',
            color: '#000',
            textDecoration: 'none'
          }}
          to="/"
        >
          {process.env.REACT_APP_TITLE ?? 'CLINICA DEFAULT'}
        </Link>
        <button
          className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0"
          id="sidebarToggle"
        >
          <HiOutlineMenuAlt1 size={32} />
        </button>
      </div>

      {/* Navbar Items*/}
      <ul className="navbar-nav align-items-center ms-auto">
        {/* * * Note: * * Visible only below the lg breakpoint*/}
        <UserNavBar />
      </ul>
    </nav>
  )
}
