import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import { RiMenu2Line } from 'react-icons/ri'
import { Dropdown, DropdownButton } from 'react-bootstrap'

export function OptionActions({ ruta, borrar, buttonMessage = 'Eliminar', roleId }) {
  const navigate = useNavigate()

  return (
    <DropdownButton
      drop="start"
      id="dropdown-basic-button"
      title={<RiMenu2Line />}
      variant="outline-primary"
    >
      <Dropdown.Item
        onClick={() => {
          navigate(ruta)
        }}
      >
        <MdOutlineAssignmentTurnedIn size={20} />
        <span className="mx-2">Asignar Permisos</span>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          navigate(`/roles/asignar-modulos/${roleId}`)
        }}
      >
        <MdOutlineAssignmentTurnedIn size={20} />
        <span className="mx-2">Asignar Modulos</span>
      </Dropdown.Item>
      <Dropdown.Item onClick={borrar}>
        <span className="far fa-trash-alt" style={{ marginRight: '10px' }}></span>
        <span>{buttonMessage}</span>
      </Dropdown.Item>
    </DropdownButton>
  )
}
