import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'

export default function Responsable({
  errror,
  register,
  personaState,
  personasSet,
  responsable,
  dui,
  onChangesPersonas,
  control
}) {
  const handleChangesPersonaResponsables = (value) => {
    personasSet({ ...personaState, parentesco: value })
  }
  return (
    <Fragment>
      <div className="row ">
        <div className="form-group col-md-12 col-sm-12 col-lg-4 my-2 ">
          <label htmlFor="" className="small mb-1">
            Tipo de parentesco:
          </label>
          <Select
            isClearable
            placeholder="Parentesco"
            id="parentesco"
            options={responsable}
            onChange={handleChangesPersonaResponsables}
            // defaultValue={personaState?.idParentescoResponsable}
            value={personaState?.parentesco}
          />
        </div>
        <div className="form-group col-md-12 col-sm-12 col-lg-4 my-2 ">
          <label htmlFor="" className="small mb-1">
            Nombre completo responsable: :
          </label>
          <input
            type="text  "
            className="form-control"
            placeholder="Nombre completo"
            id="responsable"
            {...register('responsable', {})}
            onChange={onChangesPersonas}
          />
        </div>
        <div className="form-group col-md-12 col-sm-12 col-lg-4 my-2  ">
          <label htmlFor="" className="small mb-1">
            Tipo documento:
          </label>
          <Controller
            name="idTipoDocumentoResponsable"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable
                closeMenuOnSelect={true}
                options={dui}
                placeholder="Tipo documento"
                defaultValue={{ label: 'DUI', value: 1 }}
              />
            )}
          />
        </div>
        <div className="form-group col-md-12 col-sm-12 col-lg-4 my-2 ">
          <label htmlFor="" className="small mb-1">
            Numero Documento
          </label>
          <Controller
            name="duiParentesco"
            control={control}
            render={({ field }) => (
              <input {...field} type="text" className="form-control" placeholder="000000" />
            )}
          />
        </div>
        <div className="form-group col-md-12 col-sm-12 col-lg-4 my-2 ">
          <label htmlFor="" className="small mb-1">
            Telefono
          </label>
          <Controller
            name="telefonoResponsable"
            control={control}
            render={({ field }) => (
              <PhoneInput {...field} id="telefonoCasa" country={'sv'} enableSearch={true} />
            )}
          />
        </div>
      </div>
    </Fragment>
  )
}
