import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeReport: null,
  type: null
}

export const reporSlice = createSlice({
  name: 'repor',
  initialState,
  reducers: {
    activarModalGenerico: (state, action) => {
      state.activeReport = action.payload
    },
    clearActiveReport: (state) => {
      state.activeReport = null
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    clearType: (state) => {
      state.type = initialState.type
    }
  }
})

export const { activarModalGenerico, clearActiveReport, clearType, setType } = reporSlice.actions

export default reporSlice.reducer
