/** @format */

import { FiChevronLeft } from 'react-icons/fi'
import React, { useEffect } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import HeaderExpediente from './HeaderExpediente'
import FormAlergias from '../antecedentes/alergias/FormAlergias'
import FormIncapacidad from '../incapacidad/FormIncapacidad'
import { Link } from 'react-router-dom'
import FormPrincipalExterior from '../exporacionFisica/FormPrincipalExterior'
import FormPrincipalInterrogatorio from '../interrogatorio/FormPrincipalInterrogatorio'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetFisicalExplorationForFileQuery,
  useGetFolderByIdQuery
} from '../../../services/rtk-query'
import { useResetStore } from '../../../hooks/useResetStore'
import { PatientProfile } from './components/PatientProfile'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import ListadoConsultas from '../../consultas/ListadoConsultas'

import { Profile } from '../../../components/registroMedicos/expedienteClinico/Profile'
import { useSearchParams } from 'react-router-dom'
import { ALL_PERMISSIONS, expienteTabs } from '../../../constants'
import {
  setAntecedentesTab,
  setConsultaTab,
  setIncapacidadTab,
  setPacienteTab
} from '../../../store/slices/uiSlice'
import { activeTabs } from '../../../store/slices/expedienteSlice'
import { PermissionsProvider } from '../../../context'
import { encrypt } from '../../../services/Encriptaciones'
import { RenderOptions } from './components/RenderOptions'
export default function Paciente() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  const { id = null, consultaId } = useParams()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  useGetFisicalExplorationForFileQuery(consultaId)
  useGetFolderByIdQuery(id)

  const resetStore = useResetStore()
  const { tabsExpediente } = useSelector((state) => state.ui)

  useEffect(() => {
    if (id) {
      dispatch(activeTabs())
    }
    if (expienteTabs[searchParams.get('activeTab')?.toUpperCase()]) {
      if (searchParams.get('activeTab') === expienteTabs.PACIENTE) dispatch(setPacienteTab())

      if (searchParams.get('activeTab') === expienteTabs.ANTECEDENTES) {
        return dispatch(setAntecedentesTab())
      }

      if (searchParams.get('activeTab') === expienteTabs.INCAPACIDAD) dispatch(setIncapacidadTab())

      if (searchParams.get('activeTab') === expienteTabs.CONSULTA) dispatch(setConsultaTab())
    }
  }, [searchParams])

  return (
    <LayoutForm
      title="Nuevo Expediente Clínico"
      tools={
        <Link
          className="btn btn-sm btn-light text-primary"
          to={'/expedientes-medicos'}
          onClick={resetStore}
        >
          <FiChevronLeft />
          Volver a la lista de pacientes
        </Link>
      }
    >
      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
        <Profile />
        <Card className=" shadow-none">
          <div className="card-body">
            <div className="card-subtitle ">
              <HeaderExpediente />
            </div>
            <hr />
            <div className="tab-content " id="pills-tabContent">
              <div
                className="tab-pane-fade show-active "
                id="pills-1"
                role="tabpanel"
                aria-labelledby="pills-1-tab"
              >
                <RenderOptions />
              </div>
            </div>
          </div>
        </Card>
      </PermissionsProvider>
    </LayoutForm>
  )
}
