import React from 'react'
import { Card } from 'react-bootstrap'
import { HeaderButton } from './HeaderButton'

export const Header = ({
  title = 'Enfermedades',
  subtitle = 'Hereditarias',
  handler,
  buttonTitle = 'Nueva Enfermedad',
  dropdownItems = [],
  isDropdown = false,
  showButton = true,
  ...rest
}) => {
  return (
    <Card.Header>
      <div className="d-flex flex-row  justify-content-between">
        <article>
          <p className="py-0 my-0">{title}</p>
          <small>{subtitle}</small>
        </article>
        {showButton && (
          <HeaderButton
            hasPermission={rest.hasPermission}
            buttonTitle={buttonTitle}
            handler={handler}
            isDropdown={isDropdown}
            dropdownItems={dropdownItems}
          />
        )}
      </div>
    </Card.Header>
  )
}
