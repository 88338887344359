/** @format */

import React from 'react'
import packageJson from '../../../../package.json'
export const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center p-3">
      <p>Copyright © SuyaNet: {packageJson.version}</p>
    </footer>
  )
}
