import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { IoCreateOutline } from 'react-icons/io5'

export default function BtnModal({ nombre, agregar, isDisabled }) {
  return (
    <Fragment>
      <div className="d-grid gap-2 d-md-flex mt-h-auto justify-content-md-end">
        <Button disabled={isDisabled} onClick={agregar} variant="primary" size="lg">
          <IoCreateOutline size={30} /> {nombre}
        </Button>
      </div>
    </Fragment>
  )
}
