import React, { useEffect, useState } from 'react'
import { useLazyDoctorByIdQuery } from '../../../../services/rtk-query'
import { encrypt } from '../../../../services/Encriptaciones'
import { Button } from 'react-bootstrap'
import { toastAdapter } from '../../../../plugins'

export const DocumenetUser = () => {
  const [doctorById] = useLazyDoctorByIdQuery()
  const [doctor, setDoctor] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const doctor = JSON.parse(encrypt.getSession('medico'))
      if (doctor) {
        const { data } = await doctorById(doctor?.medico?.id)
        if (data) {
          setDoctor(data)
        }
      }
    }

    fetchData()

    return () => {
      setDoctor(null)
    }
  }, [doctorById])

  if (!doctor) return null

  return (
    <>
      <h3 className="mb-3">Documentos De Medico:</h3>
      <article className="d-flex flex-wrap gap-3">
        <Button
          variant="outline-primary"
          onClick={() => {
            if (!doctor.expedienteUrl?.location) {
              return toastAdapter.info({
                message: 'No hay expediente'
              })
            }
            window.open(doctor.expedienteUrl?.location, '_blank')
          }}
        >
          Expediente
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            if (!doctor?.incapacidadUrl?.location) {
              return toastAdapter.info({
                message: 'No hay incapacidad'
              })
            }
            window.open(doctor?.incapacidadUrl?.location, '_blank')
          }}
        >
          Incapacidad
        </Button>

        <Button
          variant="outline-primary"
          onClick={() => {
            if (!doctor?.prescripcionUrl?.location) {
              return toastAdapter.info({
                message: 'No hay prescripcion'
              })
            }
            window.open(doctor?.prescripcionUrl?.location, '_blank')
          }}
        >
          Prescripcion
        </Button>
      </article>
    </>
  )
}
