import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import classNames from 'classnames'
import {
  useDeleteAllergicSymptomMutation,
  useGetAllergicSymptomsByIdQuery
} from '../../../../services/rtk-query'
import { useDispatch } from 'react-redux'
import { activarModalGenerico, setActiveAllergicSymptom } from '../../../../store/slices/uiSlice'
import { ACTIONS } from '../../../../constants'
import { alertConfirm, toastAdapter } from '../../../../plugins'

export const Option = ({ cell, setAction, activo, ...rest }) => {
  const [deleteAllergicSymptom] = useDeleteAllergicSymptomMutation()
  const dispatch = useDispatch()
  const [skip, setSkip] = useState(true)

  const { data, isSuccess, isFetching } = useGetAllergicSymptomsByIdQuery(cell, {
    skip,
    refetchOnMountOrArgChange: true
  })
  const onEvent = () => {
    setSkip(false)
  }

  const onDelete = () => {
    alertConfirm({
      title: 'Habilitar o Deshabilitar registro',
      text: '¿Estas seguro de Habilitar o Deshabilitar este registro?',
      icon: 'warning',
      confirmButtonText: 'Si, Habilitar o Deshabilitar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result) {
        toastAdapter.promise({
          promise: deleteAllergicSymptom({ id: cell, activo }).unwrap(),
          success: 'Registro habilitado o deshabilitado con éxito',
          error: 'Error al habilitar o deshabilitar el registro'
        })
      }
    })
  }

  // Actualizar el estado con los datos de la consulta cuando la consulta tenga éxito
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setAction(ACTIONS.UPDATE)
        dispatch(setActiveAllergicSymptom(data))
        dispatch(activarModalGenerico())

        setSkip(true)
      }, 300)
    }
  }, [isSuccess, data, dispatch, isFetching])
  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onEvent} disabled={rest.hasPermission}>
          <FiEdit /> Actualizar
        </Dropdown.Item>
        <Dropdown.Item
          className={classNames('text-danger', {
            opacity: rest.hasPermission
          })}
          onClick={onDelete}
          disabled={rest.hasPermission}
        >
          Habilitar / Deshabilitar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
