import React from 'react'

export const RoleSelected = ({ data }) => {
  return (
    <div>
      {data && (
        <div>
          <h5>Rol:</h5>
          <h6>{data.roles[0].nombre}</h6>
        </div>
      )}
    </div>
  )
}
