/** @format */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormDiagnostico from '../../registroMedicos/incapacidad/FormDiagnostico'
export const PrescriptionInput = ({ sliceName, propertySliceName, title = 'Someone Label' }) => {
  const { [propertySliceName]: content } = useSelector((state) => state?.[sliceName])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [editorContent, setEditorContent] = useState('')

  useEffect(() => {
    if (!isLoading && content) {
      setEditorContent(content)
      setIsLoading(true)
    }
  }, [dispatch, isLoading, content])

  return (
    <FormDiagnostico
      title={title}
      sliceName={sliceName}
      trans={editorContent}
      propertySliceName={propertySliceName}
    />
  )
}
