/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Button, Container, Form } from 'react-bootstrap'

import { toastAdapter } from '../../../plugins'
import {
  useConsultaByIdQuery,
  useLazyConsultaByIdQuery,
  useUpdateConsultaMutation
} from '../../../services/rtk-query'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  setConsulta,
  setConsultaTabFalse,
  setShowToastLoader,
  setUri
} from '../../../store/slices/uiSlice'
import { PermissionsContext } from '../../../context/permissionsContext'
import { modules, PERMISSIONS, PRE_ESCRIPCIONES, SLICES_NAMES } from '../../../constants'
import { useReports } from '../../../hooks/useReports'
import { obtenerURLBlob } from '../../../utils'
import { ModalPreviewReport } from '../../../components/modals/ModalPreviewReport'
import { PrescriptionInput } from './PrescriptionInput'
import { setObservations, setRecipes, setStudies } from '../../../store/slices/pre-escripciones'

export const PreEscripcion = () => {
  const { hasPermission } = useContext(PermissionsContext)
  const { id = null, consultaId = null } = useParams()
  const [updateConsulta] = useUpdateConsultaMutation()
  const { isSuccess, data } = useConsultaByIdQuery(consultaId)
  const prescriptionState = useSelector((state) => state.preEscripcion)
  const [trigger] = useLazyConsultaByIdQuery()
  const dispatch = useDispatch()
  const { handleFetch } = useReports()

  useEffect(() => {
    if (+consultaId && isSuccess) {
      console.dir({ data })
      const { estudios, observaciones, recetas } = data
      dispatch(setConsultaTabFalse())
      dispatch(setConsulta(consultaId))

      dispatch(setObservations(observaciones))
      dispatch(setRecipes(recetas))
      dispatch(setStudies(estudios))
    }
  }, [consultaId, isSuccess])

  const isInValid = () => {
    const minLength = 7 // longitud mínima que deseas validar
    return (
      prescriptionState.recipes.trim().length <= minLength ||
      prescriptionState.studies.trim().length <= minLength ||
      prescriptionState.observations.trim().length <= minLength
    )
  }
  const onSavePreEscripcion = () => {
    const payload = {
      recetas: prescriptionState.recipes,
      estudios: prescriptionState.studies,
      observaciones: prescriptionState.observations,
      idExpediente: +id
    }
    console.log({
      payload
    })
    if (isInValid()) return toastAdapter.info({ message: 'No hay cambios para guardar' })

    toastAdapter.promise({
      promise: updateConsulta({ body: payload, consultaId }).unwrap(),
      errorMessage: (error) => {
        return 'Error al crear la prescripcion'
      },
      loadingMessage: 'Guardando Prescripcion',
      successMessage: ({ id }) => {
        return 'Prescripcion Guardada'
      }
    })
  }

  const onGenerateReportPrescription = async () => {
    const { data, isError } = await trigger(consultaId)

    if (isError) {
      return toastAdapter.error({
        message: 'Error al intentar generar la prescripcion'
      })
    }

    if (!data?.medico?.id)
      return toastAdapter.info({ message: 'No se ha asignado un medico a la consulta' })

    toastAdapter.promise({
      promise: handleFetch({
        url: `consultas/prescripcion/${consultaId}/medico/${data?.medico?.id}`
      }),
      successMessage: (data) => {
        const uriBlog = obtenerURLBlob(data)
        console.log({ uriBlog })
        dispatch(setUri(uriBlog))
        dispatch(setShowToastLoader())
        return 'Reporte generado'
      },
      errorMessage: 'Error al generar reporte...',
      loadingMessage: 'Generando Reporte...'
    })
  }

  return (
    <Container
      style={{
        overflowX: 'clip'
      }}
    >
      <Form>
        <Form.Group className="py-4">
          <Form.Label>Prescripción de medicamentos</Form.Label>

          <PrescriptionInput
            title=""
            sliceName={SLICES_NAMES.PRE_ESCRIPICONES}
            propertySliceName={PRE_ESCRIPCIONES.RECETAS}
          />
        </Form.Group>
        <Form.Group className="py-4">
          <Form.Label>Prescripción de laboratorio clinico o rayos x</Form.Label>
          <PrescriptionInput
            title=""
            sliceName={SLICES_NAMES.PRE_ESCRIPICONES}
            propertySliceName={PRE_ESCRIPCIONES.ESTUDIOS}
          />
        </Form.Group>
        <Form.Group className="pb-4">
          <Form.Label>Observaciones</Form.Label>
          <PrescriptionInput
            title=""
            sliceName={SLICES_NAMES.PRE_ESCRIPICONES}
            propertySliceName={PRE_ESCRIPCIONES.OBSERVACIONES}
          />
        </Form.Group>

        <section className="d-flex flex-wrap gap-2">
          <Button
            size="lg"
            variant="primary"
            disabled={!hasPermission([PERMISSIONS.WRITE], modules.consulta_prescripciones)}
            onClick={onSavePreEscripcion}
          >
            Guardar Prescripción
          </Button>
          <Button size="lg" variant="outline-secondary" onClick={onGenerateReportPrescription}>
            Generar Prescripción
          </Button>
        </section>
      </Form>
      <ModalPreviewReport />
    </Container>
  )
}
