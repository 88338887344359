import React from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'

export const SelectTabMessage = () => {
  return (
    <Container className="text-center py-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <FaCheckCircle size={48} color="blue" />
          <h2 className="mt-4 mb-3">Por favor selecciona una sección para continuar</h2>
          <Alert variant="secondary">
            No has seleccionado ninguna sección. Por favor, selecciona una sección del menú para
            continuar.
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}
