/** @format */

import React, { useEffect, useState } from 'react'
import HeaderConsulta from './HeaderConsulta'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetFisicalExplorationForFileQuery,
  useGetInterrogationForFileQuery
} from '../../../services/rtk-query'
import {
  setEnfermedadesCIETab,
  setExploracionFisicaTab,
  setInterrogatorioTab,
  setPacienteTab,
  setPrescripcionTab
} from '../../../store/slices/uiSlice'
import { Profile } from '../../../components/registroMedicos/expedienteClinico/Profile'
import { useResetStore } from '../../../hooks/useResetStore'
import { FaArrowLeft } from 'react-icons/fa6'
import { useSearchParams } from 'react-router-dom'
import { activeTabs } from '../../../store/slices/expedienteSlice'
import { encrypt } from '../../../services/Encriptaciones'
import { RenderComponents } from './views/RenderComponents'
import { consultaTabs, PERMISSIONS } from '../../../constants'

export default function ConsultaPanel() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  const [searchParams] = useSearchParams()
  const resetStore = useResetStore()
  const { id = null, consultaId = null } = useParams()
  const [activeTab, setActiveTab] = useState(null)
  useGetFisicalExplorationForFileQuery(consultaId)
  useGetInterrogationForFileQuery(id)
  const dispatch = useDispatch()
  const {
    tabsExpediente: { interrogatorio, exploracionFisica, enfermedadesCIE, prescripciones }
  } = useSelector((state) => state.ui)

  useEffect(() => {
    if (id) {
      dispatch(activeTabs())
    }
    const activeTabParam = searchParams.get('activeTab')

    if (consultaId) {
      setActiveTab(activeTabParam)
    }

    if (
      searchParams.get('activeTab') === consultaTabs.INTERROGATORIO &&
      user.permisos?.consulta_interrogatorio.includes(PERMISSIONS.READ)
    )
      dispatch(setInterrogatorioTab())

    if (
      searchParams.get('activeTab') === consultaTabs.EXPLORACION_FISICA &&
      user.permisos?.consulta_exploracion_fisica.includes(PERMISSIONS.READ)
    ) {
      return dispatch(setExploracionFisicaTab())
    }

    if (
      searchParams.get('activeTab') === consultaTabs.ENFERMEDADES_CIE &&
      user.permisos?.consulta_enfermedades_cie.includes(PERMISSIONS.READ)
    )
      dispatch(setEnfermedadesCIETab())

    if (
      searchParams.get('activeTab') === consultaTabs.PRESCRIPCION &&
      user.permisos?.consulta_prescripciones.includes(PERMISSIONS.READ)
    ) {
      return dispatch(setPrescripcionTab())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <LayoutForm
      title="Nuevo Consulta"
      tools={
        <Link
          className="btn btn-sm btn-light text-primary"
          to={`/expedientes-medicos/${id}`}
          onClick={() => {
            dispatch(setPacienteTab())
            resetStore()
          }}
        >
          <FaArrowLeft />
          Volver a expediente clinico
        </Link>
      }
    >
      <Profile />
      <div className="card mb-4 shadow-sm">
        <form id="medical-records-form">
          <div className="card-header">
            <HeaderConsulta />
          </div>

          <div className="card-body">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane-fade show-active"
                id="pills-1"
                role="tabpanel"
                aria-labelledby="pills-1-tab"
              >
                <RenderComponents
                  enfermedadesCIE={enfermedadesCIE}
                  prescripciones={prescripciones}
                  exploracionFisica={exploracionFisica}
                  interrogatorio={interrogatorio}
                  activeTab={activeTab}
                  consultaId={consultaId}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </LayoutForm>
  )
}
