import React, { useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Alert, Card, CardBody } from 'react-bootstrap'
import { Header } from '../../../components/Containers/Header'
import { Lista, Option, ModalEnfermedadesTransmicionSexual } from './components'
import { activarModalGenerico, clearActiveAlterationMenstrual } from '../../../store/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { ACTIONS, modules, PERMISSIONS } from '../../../constants'
import { toastAdapter } from '../../../plugins'
import { useAddEtsMutation, useUpdateEtsMutation } from '../../../services/rtk-query'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { genericFormSchema } from '../../../utils'
import { usePermissions } from '../../../hooks'

export const EnfermedadesEtsPage = () => {
  const { hasPermission } = usePermissions()
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const [addMenstruacion] = useAddEtsMutation()
  const [updateMenstruacion] = useUpdateEtsMutation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(genericFormSchema),
    defaultValues: {
      nombre: '',

      id: 0
    }
  })
  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id'
      },
      {
        header: 'nombre',
        accessorKey: 'nombre'
      },
      {
        header: 'Activo',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Alert
              variant="primary"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              Si
            </Alert>
          ) : (
            <Alert
              variant="danger"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              No
            </Alert>
          )
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => (
          <Option
            cell={original.id}
            setAction={setAction}
            activo={original.activo}
            hasPermission={
              !hasPermission([PERMISSIONS.WRITE], modules.enfermedades_de_transmision_sexual)
            }
          />
        )
      }
    ],
    []
  )
  const handler = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    if (action === ACTIONS.SAVE) return handleSave(data)

    return handleUpdate(data)
  }

  const handleSave = (data) => {
    const { id, ...rest } = data
    toastAdapter.promise({
      promise: addMenstruacion(rest).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)

        return 'Enfermedad de transmicion sexual guardado con éxito'
      },
      errorMessage: 'Error al guardar la Enfermedad de transmicion sexual',
      loadingMessage: 'Guardando Enfermedad de transmicion sexual...'
    })
  }

  const handleUpdate = (data) => {
    toastAdapter.promise({
      promise: updateMenstruacion({ ...data, id: +getValues('id') }).unwrap(),
      successMessage: () => {
        reset()
        setAction(ACTIONS.SAVE)
        dispatch(clearActiveAlterationMenstrual())
        return 'Enfermedad de transmicion sexual guardado con éxito'
      },
      errorMessage: 'Error al guardar la Enfermedad de transmicion sexual',
      loadingMessage: 'Guardando Enfermedad de transmicion sexual...'
    })
  }
  const handleReset = () => {
    reset()
    setAction(ACTIONS.SAVE)
    dispatch(clearActiveAlterationMenstrual())
  }

  return (
    <LayoutForm title="Enfermedades de transmicion sexual">
      <Card className="shadow-none">
        <Header
          title="Enfermedades"
          subtitle="Enfermedades de transmicion sexual"
          buttonTitle="Nueva Enfermedad de transmicion sexual"
          handler={handler}
          hasPermission={
            !hasPermission([PERMISSIONS.WRITE], modules.enfermedades_de_transmision_sexual)
          }
        />
        <CardBody>
          <Lista columns={columns} />
        </CardBody>
      </Card>
      <ModalEnfermedadesTransmicionSexual
        Menstruales
        action={action}
        control={control}
        errors={errors}
        handlerAction={handlerAction}
        handleSubmit={handleSubmit}
        reset={handleReset}
        setValue={setValue}
      />
    </LayoutForm>
  )
}
