import React from 'react'
import { Form } from 'react-bootstrap'

export const HereditaryDescription = ({ enfermedadId, description, onChangeInput }) => (
  <td className="align-middle">
    <Form.Control
      as="textarea"
      onChange={(e) => onChangeInput(e, enfermedadId)}
      value={description}
      type="text"
    />
  </td>
)
