/* eslint-disable no-useless-escape */
/** @format */
// Define un objeto que mapee las vistas con los campos correspondientes en el estado Redux
const viewFieldsMapping = {
  incapacidad: 'setIncapacityNotes',
  antecedentes: {
    perinatales: 'setPerinatalNotes',
    anticoncepcion: 'setObservaciones',
    noPatologicos: 'setNoPatologicosNotes',
    drugs: 'setDrugsNotes',
    eatingHabits: 'setEatingHabits',
    physicalActivity: 'setPhysicalActivityAndSleep',
    roomTypeAndOverCrowding: 'setRoomTypeAndOvercrowding',
    hygieneHabits: 'setHygieneHabits',
    exposureToToxics: 'setExposureToToxics'
  },
  obstetrico: {
    datosGenerales: 'setObstetriciansNotes',
    embarazoActual: 'setCurrentPregnancyNotes'
  },
  interrogatorio: {
    analisis: 'setAnalyzes',
    sintomas: 'setSymptoms',
    descripcion: 'setDescriptions',
    digestivo: 'setDigestive',
    endocrino: 'setEndocrine',
    hermolinfatico: 'setHermoLinfatico',
    mamas: 'setMamas',
    musculoEsqueletico: 'setSqueletalMuscle',
    skin: 'setSkin',
    reproductivo: 'setReproductivo',
    respiratorio: 'setRespiratorio',
    sistemaNervioso: 'setSistemaNervioso',
    sistemasGenerales: 'setSistemasGenerales',
    urinario: 'setUrinario',
    cardioVascular: 'setCardioVascular'
  },
  exploracionFisica: {
    habitusExterior: 'setHabitusExterior',
    cabeza: 'setCabeza',
    ojos: 'setOjos',
    otorrinoLarigologia: 'setOtorrinoLarigologia',
    cuello: 'setCuello',
    torax: 'setTorax',
    abdomen: 'setAbdomen',
    exploracionGinecologica: 'setExploracionGinecologica',
    genitales: 'setGenitales',
    columnaVertebral: 'setColumnaVertebral',
    extremidades: 'setExtremidades',
    exploracionNeurologica: 'setExploracionNeurologica'
  },
  // Añadir más vistas y campos según sea necesario
  preEscripcion: {
    observations: 'setObservations',
    recipes: 'setRecipes',
    studies: 'setStudies'
  }
}
const ANTECEDENTES_VIEWS = {
  PERINATALES: 'perinatales',
  ANTICONCEPCION: 'anticoncepcion',
  NO_PATOLOGICOS: 'noPatologicos',
  EATING_HABITS: 'eatingHabits',
  PHYSICAL_ACTIVITY: 'physicalActivity',
  ROOM_TYPE_AND_OVERCROWDING: 'roomTypeAndOverCrowding',
  HYGIENE_HABITS: 'hygieneHabits',
  EXPOSURE_TO_TOXICS: 'exposureToToxics',
  DRUGS: 'drugs'
}
const OBSTETRICOS = {
  DATOS_GENERALES: 'datosGenerales',
  EMBARAZO_ACTUAL: 'embarazoActual'
}
const SLICES_NAMES = {
  ANTECEDENTES: 'antecedentes',
  INCAPACIDAD: 'incapacidad',
  OBSTETRICO: 'obstetrico',
  INTERROGATORIO: 'interrogatorio',
  EXPLORACION_FISICA: 'exploracionFisica',
  PRE_ESCRIPICONES: 'preEscripcion'
}

const INCAPACIDAD = 'setIncapacityNotes'
const INTERROGATORIO = {
  ANALISIS: 'analisis',
  SINTOMAS: 'sintomas',
  DESCRIPCION: 'descripcion',
  DIGESTIVO: 'digestivo',
  ENDOCRINO: 'endocrino',
  HERMOLINFATICO: 'hermolinfatico',
  MAMAS: 'mamas',
  MUSCULO_ESQUELETICO: 'musculoEsqueletico',
  SKIN: 'skin',
  REPRODUCTIVO: 'reproductivo',
  RESPIRATORIO: 'respiratorio',
  SISTEMA_NERVIOSO: 'sistemaNervioso',
  SISTEMAS_GENERALES: 'sistemasGenerales',
  URINARIO: 'urinario',
  CARDIO_VASCULAR: 'cardioVascular'
}
const EXPLORACION_FISICA = {
  HABITUS_EXTERIOR: 'habitusExterior',
  CABEZA: 'cabeza',
  OJOS: 'ojos',
  OTO_LARINGOLOGIA: 'otorrinoLarigologia',
  CUELLO: 'cuello',
  TORAX: 'torax',
  ABDOMEN: 'abdomen',
  EXPLORACION_GINECOLOGICA: 'exploracionGinecologica',
  GENITALES: 'genitales',
  COLUMNA_VERTEBRAL: 'columnaVertebral',
  EXTREMIDADES: 'extremidades',
  EXPLORACION_NEUROLOGICA: 'exploracionNeurologica'
}

const PRE_ESCRIPCIONES = {
  OBSERVACIONES: 'observations',
  RECETAS: 'recipes',
  ESTUDIOS: 'studies'
}
const permissions = {
  expedientesMedicos: ['a9b741b3-80a4-43d3-88b2-fbce12e41e35'],
  seguridad: ['855c38cd-adc4-4a4f-85e2-9d0cf9078594', '7db77ba8-1766-4c94-8357-48121205e43f']
}

const ACTIONS = {
  SAVE: 'save',
  UPDATE: 'update',
  CANCEL: 'cancel'
}

const VIEWS_TO_REDIRECT = {
  paciente: 'setPacienteTab',
  antecedentes: 'setAntecedentesTab',
  incapacidad: 'setIncapacidadTab',
  exploracionFisica: 'setExploracionFisicaTab',
  interrogatorio: 'setInterrogatorioTab',
  enfermedadesCIE: 'setEnfermedadesCIETab',
  consulta: 'setConsultaTab'
}
const PROVIDES_AND_INVALIDATE_TAGS = {
  ALLERGIES: 'Allergies',
  HEREDITARIES: 'Hereditaries',
  PATHOLOGICAL_DISEASES: 'PathologicalDiseases',
  NO_PATHOLOGICAL_DISEASES: 'NoPathologicalDiseases',
  PERINATAL_DATA: 'PerinatalData',
  GINECOLOGICAL: 'Ginecological',
  OBSTETRIC: 'Obstetric',
  FISICAL_EXPLORATION: 'FisicalExploration',
  INTERROGATION: 'Interrogation',
  CLINICAL_RECORDS: 'ClinicalRecords',
  INCAPACITIES: 'Incapacity',
  PRE_ESCRIPCIONES: 'preEscripciones',
  DIAGNOSTICOS: 'diagnosticos',
  CONSULTAS: 'consultas',
  MEDICOS: 'medicos',
  DATES: 'dates',
  ESPECIALIDADES: 'especialidades',
  SUB_ESPECIALIDAD: 'subEspecialidad',
  COMPLICATIONS: 'complications',
  MENSTRUATION: 'menstruation',
  ETS: 'ets',
  ANTICONCEPTION: 'anticonception',
  ALLERGIC_SYMPTOMS: 'allergicSymptoms',
  ROLES: 'roles',
  SUCURSALES: 'sucursales',
  EMPRESAS: 'empresas',
  PEOPLE: 'people'
}

export * from './position-toasts'

const ARR_TAGS = Object.values(PROVIDES_AND_INVALIDATE_TAGS)

const BLOOD_TYPES = {
  'O−': ['O−', 'O+', 'A−', 'A+', 'B−', 'B+', 'AB−', 'AB+'],
  'O+': ['O+', 'A+', 'B+', 'AB+'],
  'A−': ['A−', 'A+', 'AB−', 'AB+'],
  'A+': ['A+', 'AB+'],
  'B−': ['B−', 'B+', 'AB−', 'AB+'],
  'B+': ['B+', 'AB+'],
  'AB−': ['AB−', 'AB+'],
  'AB+': ['AB+']
  // Ninguno: ['Ninguno'],
}

const COOKIES_NAMES = {
  TOKEN: 'token',
  USER: 'user',
  MODULES: 'modules'
}

const NIT_REGEX = /^\d{4}-\d{6}-\d{3}-\d$/
const ZONE = 'America/El_Salvador'
const PASSWORD_REGE =
  /^(\S)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{10,16}$/
const STATUS_DATES = {
  PENDIENTE: '#868686',
  CONFIRMADA: '#3174ad',
  CANCELADA: '#E74C3C',
  REPROGRAMADA: '#AF7AC5',
  FINALIZADA: '#005E3A'
}

const STATUS_BORDER_DATES = {
  PENDIENTE: '#818E91',
  CONFIRMADA: '#31AD97',
  CANCELADA: '#E81500',
  REPROGRAMADA: '#6900C7',
  FINALIZADA: '#005E3A'
}
const BADGE_COLORS = {
  PENDIENTE: 'dark',
  CONFIRMADA: 'confirm',
  CANCELADA: 'danger',
  REPROGRAMADA: 'purple',
  FINALIZADA: 'success'
}

const modules = {
  seguridad: 'seguridad',
  modulos: 'modulos',
  roles: 'roles',
  usuarios: 'usuarios',
  administracion: 'administracion',
  sucursales: 'sucursales',
  empresas: 'empresas',
  persona: 'persona',
  expediente_medicos: 'expediente_medicos',
  listado_de_expediente: 'listado_de_expediente',
  lab_clinico: 'lab_clinico',
  registros: 'registros',
  alergias: 'alergias',
  sintomas: 'sintomas',
  medicos: 'medicos',
  listado_de_medicos: 'listado_de_medicos',
  sub_especialidades: 'sub_especialidades',
  agenda_citas: 'agenda_citas',
  hereditarios: 'hereditarios',
  complicaciones: 'complicaciones',
  alteraciones_menstruales: 'alteraciones_menstruales',
  enfermedades_de_transmision_sexual: 'enfermedades_de_transmision_sexual',
  anticonceptivos: 'anticonceptivos',
  enfermedades_patologicas: 'enfermedades_patologicas',
  patologias: 'patologias',
  antecedentes_alergias: 'antecedentes_alergias',
  antecedentes_hereditarios: 'antecedentes_hereditarios',
  antecedentes_patologias: 'antecedentes_patologias',
  antecedentes_no_patologicos: 'antecedentes_no_patologicos',
  antecedentes_perinatales: 'antecedentes_perinatales',
  antecedentes_ginecologicos: 'antecedentes_ginecologicos',
  antecedentes_obstetricos: 'antecedentes_obstetricos',
  consulta_interrogatorio: 'consulta_interrogatorio',
  consulta_exploracion_fisica: 'consulta_exploracion_fisica',
  consulta_enfermedad_cie: 'consulta_enfermedades_cie',
  consulta_prescripciones: 'consulta_prescripciones'
}
const PERMISSIONS = {
  WRITE: 'write',
  READ: 'read',
  VIEW: 'view'
}

const ALL_PERMISSIONS = Object.values(PERMISSIONS)

const UUIDS = {
  expedientesMedicos: 'ee949b41-2a99-49a9-84d6-5af4b33518a4',
  listaExpediente: 'a9b741b3-80a4-43d3-88b2-fbce12e41e35',
  medicos: 'c3ee826f-6c9b-4809-9f4c-47ce8b183f9e',
  listaMedicos: 'f7eb2102-5fc5-4bff-af08-bb70dff5f62f',
  especialidades: '21f7f8e5-054e-4ba0-9590-f94429ae8bb8',
  subEspecialidads: 'a096a663-5d1b-4271-bf17-7bf7ed475fc0',
  agendaCitas: '4dc4f1ef-25ae-4ceb-aaf3-e137de2195d4',
  generales: '287fe066-4c6e-4e74-87c6-9237a4ae454f',
  hereditarios: 'b19a1506-7e02-49f8-95a4-484cd6dfe349',
  alergias: '4ad2dd22-49fc-4be2-bfac-83df2017d414',
  complicaciones: '7a4dcf75-a807-43aa-ad34-5a16c13417a8',
  menstruacion: 'f9bbce61-cfa6-4f4c-b035-1d54bff761ee',
  enfermedadesTransmisionSexual: '5707798c-9e00-4c37-aa82-382125c14ebc',
  anticonceptivos: 'bfe955b2-9b83-42bd-884b-eca0af13ee44',
  sintomasAlergias: '42ccb469-85e9-48b7-bae4-09e30fe3d1b3',
  enfermedadesPatologicas: '30c66d54-8c86-4bba-9156-fd2a0ee6568e',
  patologias: '08c5e7f4-d1a6-45bf-aef7-e5cf5451e424',
  personas: '3b8ea17e-6840-4919-aee6-a2617002a6e4',
  reportes: '4e535a83-dc19-4009-890c-b77afa64a2a6',
  seguridad: 'bed80dff-870e-45c1-b326-025a002ff123',
  modulos: '75bcf8a7-3eb2-4b07-b893-05fa7f6bae12',
  usuarios: '855c38cd-adc4-4a4f-85e2-9d0cf9078594',
  roles: '7db77ba8-1766-4c94-8357-48121205e43f',
  administracion: '29d9531e-0337-4bc4-926e-17b92cb5b3e0',
  sucursales: '9efd33fb-9890-4e2d-82cd-035c3c0c8b9b',
  empresas: '7c46f530-24f1-4b42-a62b-162028f3eb34',
  antecedentes_alergias: '0f05446b-ef31-4c5b-b2ea-9e6fc77e2b1f',
  antecedentes_hereditarios: '730b91a7-e0f7-4927-b332-7715dfa3a50d',
  antecedentes_patologias: 'd0c33eb9-667a-46e7-84e5-3eff8b61b259',
  antecedentes_no_patologicos: '18241b98-0f41-45df-841e-f606cd27bd4a',
  antecedentes_perinatales: '2f467615-0362-4b2d-9af3-8e5208784fde',
  antecedentes_ginecologicos: '4ad5e4db-7aae-4f0a-99a6-83467a559e96',
  antecedentes_obstetricos: '06053f2b-6722-457b-a7d5-d411f24dcedd',
  consulta_interrogatorio: 'f44538f0-7148-4763-a8e7-edf8dc7b872a',
  consulta_exploracion_fisica: '9db27f7c-1c06-4b72-bac7-0a2f3a1d60bb',
  consulta_enfermedad_cie: 'aadb8fa1-3f2b-4e85-b410-fdfab6216f9f',
  consulta_prescripciones: 'a9c7cea6-500c-4bf0-88d9-78f56a814213'
}

const ALERT_ICONS = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  QUESTION: 'question'
}

const expienteTabs = {
  PACIENTE: 'paciente',
  ANTECEDENTES: 'antecedentes',
  INCAPACIDAD: 'incapacidad',
  EXPLORACION_FISICA: 'exploracionFisica',
  INTERROGATORIO: 'interrogatorio',
  ENFERMEDADES_CIE: 'enfermedadesCIE',
  CONSULTA: 'consulta'
}
const consultaTabs = {
  INTERROGATORIO: 'interrogatorio',
  EXPLORACION_FISICA: 'exploracionFisica',
  ENFERMEDADES_CIE: 'enfermedadesCIE',
  PRESCRIPCION: 'prescripciones'
}
const actions = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
}
const validExtension = /\.(doc|docx|odt)$/i
export {
  ZONE,
  PASSWORD_REGE,
  BADGE_COLORS,
  STATUS_BORDER_DATES,
  STATUS_DATES,
  NIT_REGEX,
  viewFieldsMapping,
  SLICES_NAMES,
  ANTECEDENTES_VIEWS,
  OBSTETRICOS,
  INTERROGATORIO,
  EXPLORACION_FISICA,
  permissions,
  ACTIONS,
  VIEWS_TO_REDIRECT,
  PROVIDES_AND_INVALIDATE_TAGS,
  ARR_TAGS,
  BLOOD_TYPES,
  PRE_ESCRIPCIONES,
  INCAPACIDAD,
  COOKIES_NAMES,

  // ? UUID's
  UUIDS,
  ALERT_ICONS,
  expienteTabs,
  consultaTabs,
  actions,
  validExtension,
  modules,
  PERMISSIONS,
  ALL_PERMISSIONS
}
export * from './reportsTypes'
