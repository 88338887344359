/* eslint-disable react-hooks/exhaustive-deps */
/** @format */

import React, { Fragment, useState } from 'react'
import FormDiagnostico from '../../incapacidad/FormDiagnostico'
import { Alert, Col, FormCheck, Row } from 'react-bootstrap'

import { viewTypes } from '../../../../types'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ANTECEDENTES_VIEWS } from '../../../../constants'
import { useGetComplicationsQuery } from '../../../../services/rtk-query/clinicalApi'
import { Loader } from '../../../../components/ui/Loader'
import { setComplications } from '../../../../store/slices/antecedentes'

const Complicaciones = ({ tempData }) => {
  const { data, isError, isSuccess, isLoading } = useGetComplicationsQuery()
  const { perinatales } = useSelector((state) => state.antecedente)
  const [isLoaded, setIsLoaded] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [selectedDiseases, setSelectedDiseases] = useState([])
  const dispatch = useDispatch()
  const [editorContent, setEditorContent] = useState('')

  useEffect(() => {
    if (tempData?.length) return setSelectedDiseases(tempData)
  }, [tempData])

  useEffect(() => {
    if (!isLoaded && perinatales?.notas) {
      setEditorContent(perinatales.notas)
      setIsLoaded(true)
    }
  }, [dispatch, perinatales.notas])

  const handleDiseaseToggle = (diseaseId) => {
    if (selectedDiseases.includes(diseaseId)) {
      setSelectedDiseases(selectedDiseases.filter((id) => id !== diseaseId))
    } else {
      setSelectedDiseases([...selectedDiseases, diseaseId])
    }
  }
  useEffect(() => {
    setSelectedDiseases(perinatales?.complicaciones)
  }, [])

  useEffect(() => {
    if (!loaded && perinatales?.complicaciones) {
      setSelectedDiseases(perinatales?.complicaciones)
      setLoaded(true)
    }
  }, [loaded, perinatales?.complicaciones])

  useEffect(() => {
    dispatch(setComplications(selectedDiseases))
  }, [dispatch, selectedDiseases])

  return (
    <Fragment>
      <FormDiagnostico
        title="Complicaciones"
        sliceName={viewTypes.ANTECEDENTE}
        propertySliceName={ANTECEDENTES_VIEWS.PERINATALES}
        trans={editorContent}
      />
      <Row className="my-5">
        {isLoading && <Loader />}
        {isError && (
          <Alert variant="danger" className="my-3">
            {' '}
            Ocurrio un error al cargar las Complicaciones
          </Alert>
        )}

        {isSuccess &&
          data?.map((complicacion) => (
            <Col md={4} className="py-1" key={complicacion.id}>
              <FormCheck>
                <FormCheck.Input
                  type="checkbox"
                  value={complicacion.id}
                  data-model="perinatal_newborn_diseases"
                  id={'newborn-disease' + complicacion.id}
                  checked={selectedDiseases.includes(complicacion.id)}
                  onChange={() => handleDiseaseToggle(complicacion.id)}
                />
                <FormCheck.Label
                  className="form-check-label"
                  htmlFor={'newborn-disease' + complicacion.id}
                >
                  {complicacion.nombre}
                </FormCheck.Label>
              </FormCheck>
            </Col>
          ))}
      </Row>
    </Fragment>
  )
}

export default Complicaciones
