/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import { PiTrashSimple } from 'react-icons/pi'
import {
  clinicalApi,
  useDeleteIncapcityMutation,
  useLazyMedicosQuery
} from '../../../../services/rtk-query'
import { useDispatch } from 'react-redux'
import { setActiveIncapacity } from '../../../../store/slices/incapacidad'
import { useParams } from 'react-router-dom'
import { alertConfirm, loadingAlert, selectAlert, toastAdapter } from '../../../../plugins'
import { TbFileReport } from 'react-icons/tb'
import { useReports } from '../../../../hooks/useReports'
import { obtenerURLBlob } from '../../../../utils'
import { setShowToastLoader, setUri } from '../../../../store/slices/uiSlice'
import { PermissionsContext } from '../../../../context/permissionsContext'
import { modules, PERMISSIONS } from '../../../../constants'
import classNames from 'classnames'
import { encrypt } from '../../../../services/Encriptaciones'
export const OptionsColumn = ({ cell }) => {
  const { hasPermission } = useContext(PermissionsContext)
  const { id = null } = useParams()
  const [deleteIncapacity] = useDeleteIncapcityMutation()
  const { handleFetch } = useReports()
  const [doctorsQuery] = useLazyMedicosQuery()
  const {
    data: current,
    isSuccess,
    isLoading
  } = clinicalApi.endpoints.getIncapacities.useQuery({
    id
  })
  const dispatch = useDispatch()
  const onEvent = () => {
    const incapacity = current?.find((incapacity) => incapacity.id === cell)

    if (!incapacity) return

    dispatch(setActiveIncapacity(incapacity))
  }
  const handlerCheckDoctor = async () => {
    const doctor = JSON.parse(encrypt.getSession('medico'))
    if (!doctor?.medico) {
      loadingAlert({
        flag: true,
        text: 'Cargando Medicos',
        title: 'Cargando...'
      })
      const { data, isSuccess, isLoading } = await doctorsQuery()

      if (!isLoading) {
        loadingAlert({ flag: false })
      }
      if (!isSuccess) return

      const inputOptions = data
        ?.map((medico) => ({
          [medico.id]: `${medico.nombres} ${medico.apellidos}`
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})

      const response = await selectAlert({
        title: 'Seleccione un medico',
        text: 'Seleccione un Medico para continuar',
        inputPlaceHolder: 'Seleccione un medico',
        inputOptions
      })
      if (response.isConfirmed) {
        return response.value
      }

      return
    }
    return doctor?.medico?.id
  }
  const onGenerateIncapacity = async () => {
    const medicoId = await handlerCheckDoctor()
    const incapacity = current?.find((incapacity) => incapacity.id === cell)
    toastAdapter.promise({
      promise: handleFetch({
        url: `consultas/reporte/incapacidad/${incapacity.id}/medico/${
          medicoId ?? incapacity.idMedico
        }`
      }),
      loadingMessage: 'Generando Reporte',
      successMessage: (data) => {
        const uriBlog = obtenerURLBlob(data)
        dispatch(setUri(uriBlog))
        dispatch(setShowToastLoader())
        return 'Reporte Generado'
      },
      errorMessage: 'Ocurrio un error al intentar generar el reporte'
    })
  }

  const onDelete = () => {
    alertConfirm({
      title: 'Desea Eliminar esta incapacidad?',
      text: 'no podra revertir esto',
      icon: 'question',
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((resp) => {
      if (resp) {
        toastAdapter.promise({
          promise: deleteIncapacity(cell).unwrap(),
          successMessage: 'Incapacidad Eliminada',
          errorMessage: 'Ocurrio un error al intentar eliminar una incapacidad'
        })
      }
    })
  }
  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={onEvent}
          className={classNames('text-dar', {
            opacity: !hasPermission([PERMISSIONS.READ], modules.expediente_medicos)
          })}
          disabled={
            (isLoading && !isSuccess) ||
            !hasPermission([PERMISSIONS.READ], modules.expediente_medicos)
          }
        >
          <FiEdit /> Actualizar
        </Dropdown.Item>
        <Dropdown.Item onClick={onGenerateIncapacity} disabled={isLoading && !isSuccess}>
          <TbFileReport /> Generar Reporte
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onDelete}
          className={classNames('text-danger', {
            opacity: !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)
          })}
          disabled={
            (isLoading && !isSuccess) ||
            !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)
          }
        >
          <PiTrashSimple /> <span className="">Eliminar</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
