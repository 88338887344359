import React from 'react'

export const DownloadButton = ({ filePath, fileName }) => {
  const handleDownload = () => {
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch((error) => console.error('Error al descargar el archivo:', error))
  }

  return (
    <button className="btn btn-outline-primary" onClick={handleDownload}>
      {fileName.split('.').shift()}
    </button>
  )
}
