import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles/index.module.css'
import { IoCloudUploadOutline } from 'react-icons/io5'
import { validExtension } from '../../constants'
import { toastAdapter } from '../../plugins'
const FileUpload = ({ handler, accept = '.doc, .docx, .odt', pattern = validExtension }) => {
  const [dragging, setDragging] = useState(false)

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    const files = e.dataTransfer.files

    if (files.length !== 1) {
      return toastAdapter.error({
        message: 'Por favor solo arrastre solo un archivo'
      })
    }

    const file = files[0]

    if (!validExtension.test(file.name))
      return toastAdapter.error({
        message: 'Archivo no valido solo se permiten .doc, .docx, .odt'
      })

    handler(files)
  }

  const isValidFile = (file) => {
    if (!validExtension.test(file.name)) {
      toastAdapter.error({
        message: 'Archivo no válido. Las extensiones permitidas son .doc, .docx, .odt'
      })
      return false
    }
    return true
  }

  const handleClick = (e) => {
    document.getElementById('file-upload')?.click()
  }
  const handleChange = (e) => {
    const files = e.target.files

    if (files.length !== 1) {
      toastAdapter.error({
        message: 'Por favor seleccione solo un archivo'
      })
      return
    }

    const file = files[0]

    if (!isValidFile(file)) {
      return
    }

    // Llamar al manejador de archivos válidos
    handler([file])
  }
  return (
    <article
      className={classNames(
        styles['file-upload-wrapper'],
        {
          [styles['dragging']]: dragging
        },
        'text-center',
        'm-3'
      )}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        type="file"
        id="file-upload"
        className={styles['file-upload']}
        accept={accept}
        pattern={pattern}
        onChange={handleChange}
      />
      <label
        htmlFor="file-upload"
        className={`${styles['file-upload-label']} ${styles['another-class']} ${styles['third-class']} d-flex flex-column`}
      >
        <IoCloudUploadOutline size={40} />
        <small>Subir Archivo o arrástralo y suéltalo</small>
      </label>
    </article>
  )
}
FileUpload.propTypes = {
  handler: PropTypes.func.isRequired
}
export default FileUpload
