import React from 'react'
import { PermissionsContext } from './permissionsContext'
import { PERMISSIONS } from '../constants'
import NoPermissions from '../components/ui/NoPermissions'

export const PermissionsProvider = ({ children, user, permissions }) => {
  //? Handle potential missing user data gracefully
  if (!user || !user.permisos) {
    return <NoPermissions />
  }

  //? Check if the user has the required permissions for a given module
  const hasPermission = (requiredPermissions, providedModule) => {
    const modulePermissions = user.permisos[providedModule] || []

    if (!modulePermissions.includes(PERMISSIONS.VIEW)) {
      return false
    }
    //? Check if all required permissions exist in the module's permissions
    return requiredPermissions.every((perm) => modulePermissions.includes(perm))
  }

  return (
    <PermissionsContext.Provider value={{ user, hasPermission }}>
      {children}
    </PermissionsContext.Provider>
  )
}
