import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RiMenu2Line } from 'react-icons/ri'
import { Dropdown, Button } from 'react-bootstrap'

export default function ButtonsACtionsFactories({ ruta, cell, handler, ...rest }) {
  const navigate = useNavigate()

  const handleInhabilitar = () => {
    handler()
  }

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle as={Button} variant="outline-primary" id="dropdownMenuButton">
        <RiMenu2Line />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ zIndex: 10 }} aria-labelledby="dropdownMenuButton">
        <Dropdown.Item onClick={() => navigate(ruta)} disabled={rest.hasPermission}>
          <span className="fas fa-edit" style={{ marginRight: '10px' }}></span>
          Editar
        </Dropdown.Item>
        <Dropdown.Item onClick={handleInhabilitar} disabled={rest.hasPermission}>
          <span className="far fa-ban" style={{ marginRight: '10px' }}></span>
          <span>{cell?.activo ? 'Inhabilitar' : 'Habilitar'}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
