import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LayoutForm from '../../containers/layouts/LayoutForm'
import Alertas from '../../services/Alertas'
import AxiosCabeceras from '../../services/AxiosCabeceras'
import peticionesAxios from '../../services/peticionesAxios.js'
import { Controller, useForm } from 'react-hook-form'
import { AsyncSelectCustom } from '../../components/selects/AsyncSelect.jsx'
import { useMedicosQuery } from '../../services/rtk-query/clinicalApi.js'

export default function AddUsers() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const { data: doctors, isFetching, isSuccess } = useMedicosQuery(search)

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nombre: '',
      usuario: '',
      rol: '',
      doctor: null
    }
  })

  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (id) {
      loadUser(id)
    }
    fetchRoles()
  }, [id])

  const onSubmit = async (dataUser) => {
    const payload = {
      nombre: dataUser.nombre,
      usuario: dataUser.usuario,
      rol: +dataUser.rol?.value,
      medicoId: dataUser.doctor?.value
    }

    if (id) {
      Alertas.loading_reload(true, 'Actualizando Usuario')
      const result = await peticionesAxios.update2(`user/${id}`, payload)
      Alertas.loading_reload(false)
      if (result?.status === 200) {
        Alertas.alertEmpty('Usuario actualizado con éxito!', 'Administración de Suyanet', 'success')
        navigate('/usuarios')
      }
    } else {
      Alertas.loading_reload(true, 'Guardando Usuario')
      const result = await peticionesAxios.POST('user', payload)
      Alertas.loading_reload(false)
      if (result?.status === 201) {
        Alertas.alertEmpty('Usuario guardado con éxito!', 'Administración de Suyanet', 'success')
        navigate('/usuarios')
      } else {
        Alertas.alertEmpty('Error al guardar el usuario!', 'Este usuario ya existe', 'info')
      }
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await peticionesAxios.find('/roles', {
        headers: AxiosCabeceras.getHeader()
      })
      setRoles(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const loadUser = async (id) => {
    try {
      const response = await peticionesAxios.find(`/user/${id}`, {
        headers: AxiosCabeceras.getHeader()
      })
      const user = response.data
      setValue('nombre', user.nombre)
      setValue('usuario', user.usuario)
      setValue('rol', {
        value: user?.rol?.id,
        label: user?.rol?.nombre
      })
      if (user.medico) {
        setValue('doctor', {
          value: user.medico.id,
          label: `${user.medico.nombres} ${user.medico.apellidos}`
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const filterRoles = (inputValue) => {
    if (!inputValue.trim())
      return roles.map((role) => ({
        value: role.id,
        label: role.nombre
      }))

    return roles
      ?.filter((role) => role.nombre.toLowerCase().includes(inputValue.toLowerCase()))
      .map((role) => ({
        value: role.id,
        label: role.nombre
      }))
  }

  const loadRoleOptions = (inputValue, callback) => {
    return callback(filterRoles(inputValue))
  }

  const filterDoctors = (inputValue) => {
    return doctors
      ?.filter((doctor) =>
        `${doctor.nombres} ${doctor.apellidos}`.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((doctor) => ({
        value: doctor.id,
        label: `${doctor.nombres} ${doctor.apellidos}`
      }))
  }

  const loadDoctorOptions = (inputValue, callback) => {
    setSearch(inputValue)
    if (isSuccess) {
      callback(filterDoctors(inputValue))
    }
  }

  return (
    <Fragment>
      <LayoutForm
        tools={
          <Link className="btn btn-sm btn-light text-primary" to={'/usuarios'}>
            <i className="fa fa-arrow-left" />
            Regresar a la lista de usuarios
          </Link>
        }
        title={id ? 'Editar usuarios' : 'Administracion de usuarios'}
      >
        <div className="card mb-4 shadow-none">
          <div className="card-header">Detalles de la cuenta</div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row gx-3 mb-3">
                <div className="col-md-3">
                  <label className="small mb-1">Rol:</label>
                  <Controller
                    name="rol"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <AsyncSelectCustom
                        isClearable
                        {...field}
                        loadOptions={loadRoleOptions}
                        defaultOptions={filterRoles('')}
                      />
                    )}
                  />
                  {errors.rol && <b style={{ color: '#D14A3F' }}>Rol es requerido</b>}
                </div>

                <div className="col-md-3">
                  <label className="small mb-1" htmlFor="nombre">
                    Nombre de usuario:
                  </label>
                  <Controller
                    name="nombre"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="text"
                        placeholder="Escriba su nombre de usuario"
                      />
                    )}
                  />
                  {errors.nombre && <b style={{ color: '#D14A3F' }}>El nombre es requerido</b>}
                </div>

                <div className="col-md-3">
                  <label className="small mb-1" htmlFor="usuario">
                    Puedes llamarme:
                  </label>
                  <Controller
                    name="usuario"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="text"
                        placeholder="Nombre en el sistema"
                      />
                    )}
                  />
                  {errors.usuario && <b style={{ color: '#D14A3F' }}>El usuario es requerido</b>}
                </div>

                <div className="col-md-3">
                  <label htmlFor="doctor">Seleccione un medico:</label>
                  <Controller
                    name="doctor"
                    control={control}
                    render={({ field }) => (
                      <AsyncSelectCustom
                        isClearable
                        {...field}
                        loadOptions={loadDoctorOptions}
                        isLoading={isFetching}
                        defaultOptions={filterDoctors('')}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="card-footer text-center">
                <button
                  className="btn btn-secondary btn-lg mr-2"
                  type="button"
                  onClick={() => navigate('/usuarios')}
                  style={{ marginRight: '10px' }}
                >
                  <i className="fa fa-arrow-circle-left"></i> SALIR
                </button>
                <button className="btn btn-primary btn-lg" type="submit">
                  <i className="fa fa-save"></i> Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
