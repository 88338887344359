import React, { useEffect } from 'react'
import { Button, Form, Stack } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ModalGenerico } from '../../../../components/ui'
import { ACTIONS } from '../../../../constants'
import { useSelector } from 'react-redux'
import { AsyncSelectCustom } from '../../../../components/selects/AsyncSelect'
import { useGetPathologicalDiseasesQuery } from '../../../../services/rtk-query'
import { filterItems, loadOptions } from '../../../../utils/selects.utils'

export const ModalPathologicalFeature = ({
  handleSubmit,
  handlerAction,
  action,
  reset,
  control,
  errors,
  setValue
}) => {
  const { activepathologicalFeature } = useSelector((state) => state.ui)
  const { data, isFetching, isSuccess } = useGetPathologicalDiseasesQuery()

  useEffect(() => {
    if (activepathologicalFeature) {
      setValue('nombre', activepathologicalFeature.nombre)
      setValue('id', activepathologicalFeature.id)

      if (isSuccess) {
        const mappedData = data?.map((item) => ({
          value: item.id,
          label: item.nombre
        }))
        const selected = mappedData.find(
          (item) => item.value === activepathologicalFeature.idEnfermedadPatologica
        )
        setValue('categoriaId', selected)
      }
    }
  }, [activepathologicalFeature])

  const filterOptions = (inputValue) => {
    const mappedData = data?.map((item) => ({
      value: item.id,
      label: item.nombre
    }))

    const filterData = filterItems({
      items: mappedData,
      inputValue,
      key: 'label'
    })

    return filterData
  }

  const loadOptionData = (inputValue, callback) => {
    return loadOptions({
      isSuccess,
      filterFunction: filterOptions,
      inputValue,
      callback
    })
  }

  return (
    <ModalGenerico
      title="Enfermedades Patologicas"
      buttonCustom={() => (
        <Button
          variant="primary "
          size="md"
          style={{
            minWidth: '15%'
          }}
          onClick={handleSubmit(handlerAction)}
        >
          {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
        </Button>
      )}
      style={{ height: '500px' }}
      closeButtonAction={reset}
    >
      <Stack
        style={{
          height: ' '
        }}
      >
        <Form.Group className="mb-2">
          <Form.Label>Nombre de la patologia</Form.Label>
          <Controller
            control={control}
            name="nombre"
            render={({ field }) => (
              <Form.Control type="text" placeholder="Nombre de la patologia..." {...field} />
            )}
          />
          {errors.nombre && (
            <Form.Text className="text-danger">{errors?.nombre?.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Categoria</Form.Label>
          <Controller
            control={control}
            name="categoriaId"
            render={({ field }) => (
              <AsyncSelectCustom
                {...field}
                loadOptions={loadOptionData}
                isLoading={isFetching}
                placeholder="Seleccione una categoria"
              />
            )}
          />
          {errors.categoriaId && (
            <Form.Text className="text-danger">{errors?.categoriaId?.message}</Form.Text>
          )}
        </Form.Group>
      </Stack>
    </ModalGenerico>
  )
}
