import React, { Fragment } from 'react'
import { encrypt } from '../../../services/Encriptaciones'
export default function SideFooter() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  return (
    <Fragment>
      <div className="sidenav-footer">
        <div className="sidenav-footer-content">
          <div className="sidenav-footer-subtitle">Usuario:</div>
          <div className="sidenav-footer-title">{user.nombre}</div>
        </div>
      </div>
    </Fragment>
  )
}
