import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  observations: '',
  recipes: '',
  studies: '',
  prescripcionId: null
}

export const preEscripcionSlice = createSlice({
  name: 'preEscripcion',
  initialState,
  reducers: {
    setPrescription: (state, action) => {
      state.prescripcionId = action.payload
    },
    setObservations: (state, action) => {
      state.observations = action.payload
    },
    setRecipes: (state, action) => {
      state.recipes = action.payload
    },
    setStudies: (state, action) => {
      state.studies = action.payload
    }
  }
})

export const { setPrescription, setObservations, setRecipes, setStudies } =
  preEscripcionSlice.actions

export default preEscripcionSlice.reducer
