/** @format */

import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearIncapacity,
  setEndDate,
  setIncapacityNotes,
  setInitialDate
} from '../../../store/slices/incapacidad'
import FormDiagnostico from './FormDiagnostico'
import { modules, PERMISSIONS, SLICES_NAMES } from '../../../constants'
import { toastAdapter } from '../../../plugins'
import { BsSave2 } from 'react-icons/bs'
import {
  useGetIncapacitiesQuery,
  useLazyMedicosQuery,
  useSaveIncapacityMutation,
  useUpdateIncapacityMutation
} from '../../../services/rtk-query'
import { useParams } from 'react-router-dom'
import { formatPayload } from './utils'
import { OptionsColumn } from './components/OptionsColumn'
import { AiOutlineClear } from 'react-icons/ai'
import { TablePlugin } from '../../../plugins/components/TablePlugin'
import { SanitizeRichText } from '../../../components/ui/SanitizeRichText'
import { calculateDays, isDateValid } from '../../../utils'
import { format } from 'date-fns'
import { ModalPreviewReport } from '../../../components/modals/ModalPreviewReport'
import { PermissionsContext } from '../../../context/permissionsContext'
import { handlerCheckDoctor } from '../../../helpers/checkDoctor'

const textParcer = ({ row: { original } }) => {
  return <SanitizeRichText html={original?.Notas?.slice(0, 80)} />
}

export default function FormIncapacidad() {
  const [medicosFN] = useLazyMedicosQuery()
  const { hasPermission } = useContext(PermissionsContext)
  const [saveIncapacity] = useSaveIncapacityMutation()
  const [updateIncapacity] = useUpdateIncapacityMutation()
  const { id = null } = useParams()
  const { data, isFetching } = useGetIncapacitiesQuery({ id })

  const { notas, fechaFinal, activeIncapacity } = useSelector((state) => state.incapacidad)
  const dispatch = useDispatch()
  const columns = useMemo(
    () => [
      { header: 'Feha Inicial', accessorKey: 'fechaInicio', sort: true },
      { header: 'Feha Final', accessorKey: 'fechaFin', sort: true },
      {
        header: 'Notas',
        accessorKey: 'Notas',
        sort: true,
        cell: textParcer
      },
      {
        header: 'acciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => <OptionsColumn cell={original.id} medico />
      }
    ],
    []
  )

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    watch
  } = useForm({
    defaultValues: {
      fechaInicio: null,
      fechaFinal: null,
      calcFechaFinal: 1
    }
  })
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!activeIncapacity) {
      setValue('fechaInicio', format(new Date(), 'yyyy-MM-dd'))
      setInitialDate(format(new Date(), 'yyyy-MM-dd'))
      return
    }
    setValue('fechaInicio', activeIncapacity?.fechaInicio)
    setValue('fechaFinal', activeIncapacity?.fechaFin)
    dispatch(setInitialDate(activeIncapacity?.fechaInicio))
    dispatch(setEndDate(activeIncapacity?.fechaFin))
    dispatch(setIncapacityNotes(activeIncapacity?.Notas))
    setContent(activeIncapacity?.Notas)
  }, [activeIncapacity, dispatch, setValue])

  const [content, setContent] = useState('')
  const fechaInicio = watch('fechaInicio')
  const calcFechaFinal = watch('calcFechaFinal')
  const calculateAndUpdateFechaFinal = () => {
    // Verificar si la fecha de inicio es válida

    if (+calcFechaFinal <= 0) return

    if (!isDateValid(fechaInicio)) {
      // Si la fecha de inicio no es válida, establecerla en la fecha actual
      setValue('fechaInicio', format(new Date(), 'yyyy-MM-dd'))
      return
    }
    // Calcular la fecha final
    const dta = calculateDays(fechaInicio, +calcFechaFinal ?? 1)

    if (!dta) return
    // Formatear la fecha final
    const finalDate = format(dta, 'yyyy-MM-dd')

    // Actualizar el valor de fechaFinal
    setValue('fechaFinal', finalDate)

    // Dispatch para actualizar la fecha final en algún estado global (si es necesario)
    dispatch(setEndDate(finalDate))
  }

  useEffect(() => {
    if (!isLoading && notas) {
      setContent(notas)
      setValue('fechaInicio', fechaInicio)
      setValue('fechaFinal', fechaFinal)
      setIsLoading(true)
    }
  }, [dispatch, fechaFinal, fechaInicio, isLoading, notas, setValue])

  const onChangeInitialDate = (e) => {
    dispatch(setInitialDate(e.target.value))
  }
  const onChangeEndDate = (e) => {
    dispatch(setEndDate(e.target.value))
  }

  const handleSave = async (data) => {
    if (activeIncapacity) {
      return toastAdapter.promise({
        promise: updateIncapacity({
          ...formatPayload({ ...data, notas }),
          expedienteId: id,
          id: activeIncapacity.id,
          medicoId: activeIncapacity.idMedico
        }).unwrap(),
        loadingMessage: 'Guardando incapacidad...',
        successMessage: () => {
          dispatch(clearIncapacity())
          handleClean()

          return 'Incapacidad guardada'
        },
        errorMessage: 'Error al guardar la incapacidad'
      })
    }

    const medicoId = await handlerCheckDoctor(medicosFN)
    if (!medicoId) return

    return toastAdapter.promise({
      promise: saveIncapacity({
        ...formatPayload({ ...data, notas }),
        expedienteId: id,
        medicoId
      }).unwrap(),
      loadingMessage: 'Guardando incapacidad...',
      successMessage: () => {
        dispatch(clearIncapacity())
        handleClean()
        // setValue('fechaInicio', '');
        // setValue('fechaFinal', '');
        return 'Incapacidad guardada'
      },
      errorMessage: (error) => {
        if (error.data) {
          return toastAdapter.error(error.data.message.join(', ') ?? error.data.message)
        } else {
          return 'Error al guardar la incapacidad'
        }
      }
    })
  }
  const handleClean = () => {
    reset()
    setValue('fechaFinal', '')
    setValue('fechaInicio', '')
    setContent('')
    dispatch(clearIncapacity())
    dispatch(setEndDate(''))
    dispatch(setInitialDate(''))
    dispatch(setIncapacityNotes(null))
  }

  return (
    <Container fluid className="my-3">
      <Row>
        <Col md={6} lg={6} sm={12} className="order-xs-last order-sm-last order-md-first">
          <TablePlugin data={data} columns={columns} isFetching={isFetching} />
        </Col>
        <Col md={6} lg={6} sm={12} className="order-xs-first order-sm-first  order-md-last">
          <Row>
            <Col md={6} sm={12} className="mb-3 ">
              <Form.Group className="mb-3">
                <label className="form-label" htmlFor="inhability_start_date">
                  Fecha de inicio
                </label>
                <Controller
                  name="fechaInicio"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Fecha Inicio Obligatoria' }
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        onChangeInitialDate(e)
                      }}
                      type="date"
                      className="form-control"
                      data-model="inhability_start_date"
                      id="inhability_start_date"
                    />
                  )}
                />
                {errors.fechaInicio?.type && (
                  <Form.Text className="text-danger">{errors.fechaInicio?.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={12} className="mb-3 ">
              <Form.Group className="mb-3">
                <label className="form-label" htmlFor="inhability_start_date">
                  Dias de incapacidad
                </label>
                <Controller
                  name="calcFechaFinal"
                  control={control}
                  rules={{
                    max: {
                      message: 'Maximo 30 dias',
                      value: 30
                    },
                    minLength: {
                      message: 'Minimo 1 dia',
                      value: 1
                    }
                  }}
                  render={({ field }) => (
                    <InputGroup className="mb-3">
                      <Form.Control
                        {...field}
                        aria-label="Example text with button addon"
                        aria-describedby="basic-addon1"
                      />
                      <Button
                        variant="outline-primary"
                        id="button-addon1"
                        onClick={calculateAndUpdateFechaFinal}
                      >
                        Calcular
                      </Button>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={12} sm={12} className="mb-3 ">
              <Form.Group className="mb-3">
                <label className="form-label" htmlFor="inhability_start_date">
                  Fecha de Expiracion
                </label>
                <Controller
                  name="fechaFinal"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Fecha Final Obligatoria' }
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        onChangeEndDate(e)
                      }}
                      type="date"
                      className="form-control"
                      data-model="inhability_start_date"
                      id="inhability_end_date"
                    />
                  )}
                />
                {errors.fechaFinal?.type && (
                  <Form.Text className="text-danger">{errors.fechaFinal?.message}</Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={12} sm={12} className="my-4 ">
              <FormDiagnostico sliceName={SLICES_NAMES.INCAPACIDAD} title="Notas" trans={content} />
            </Col>
            <Col lg={12} sm={12} className="my-4 d-flex justify-content-around flex-wrap gap-2">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={handleSubmit(handleSave)}
                className=""
                disabled={!hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
              >
                <BsSave2 /> Guardar
              </Button>
              <Button
                variant="outline-primary"
                size="lg"
                onClick={handleClean}
                disabled={!hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
              >
                <AiOutlineClear /> Limpiar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalPreviewReport />
    </Container>
  )
}
