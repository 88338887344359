import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { encrypt } from '../../../../../services/Encriptaciones'
import { VscSettings } from 'react-icons/vsc'
import { RiUserSettingsLine } from 'react-icons/ri'
import { useResetStore } from '../../../../../hooks/useResetStore'
import { UUIDS } from '../../../../../constants'

export const Componentes = () => {
  const permisos = encrypt.getSession('modulos')
  const resetStore = useResetStore()

  return (
    <Fragment>
      {permisos.includes(UUIDS.administracion) && (
        <>
          <NavLink
            className={'nav-link collapsed'}
            to={"'javascript:void(0);'"}
            data-bs-toggle="collapse"
            data-bs-target="#collapseComponents"
            aria-expanded="false"
            aria-controls="collapseComponents"
          >
            <div className="nav-link-icon">
              <VscSettings size={28} />
            </div>
            Administración
            <div className="sidenav-collapse-arrow">
              <i className="fas fa-angle-down" />
            </div>
          </NavLink>
          <div className="collapse" id="collapseComponents" data-bs-parent="#accordionSidenav">
            <nav className="sidenav-menu-nested nav">
              {permisos.includes(UUIDS.personas) && (
                <NavLink className="nav-link" to={'/persona/'} onClick={resetStore}>
                  <div className={'nav-link-icon'}>
                    <RiUserSettingsLine size={24} />
                  </div>
                  Persona
                </NavLink>
              )}
              {permisos.includes(UUIDS.sucursales) && (
                <NavLink className="nav-link" to={'/empresas/sucursales'} onClick={resetStore}>
                  <div className={'nav-link-icon'}>
                    <i className="fa fa-city" />
                  </div>
                  Sucursales
                </NavLink>
              )}
              {permisos.includes(UUIDS.empresas) && (
                <NavLink className="nav-link" to={'/administracion/empresas'} onClick={resetStore}>
                  <div className="nav-link-icon">
                    <i className="fa fa-building" />
                  </div>
                  Empresas
                </NavLink>
              )}
            </nav>
          </div>
        </>
      )}
    </Fragment>
  )
}
