import React, { useCallback, useEffect, useState } from 'react'
import { Button, CardFooter } from 'react-bootstrap'
import { ACTIONS } from '../../../../constants'
import FileUpload from '../../../../components/ui/FileUpload'
import classname from 'classnames'
import DocumentCard from '../../../../components/ui/DocumentCard'
import { HeaderButton } from '../../../../components/Containers/HeaderButton'
export const ModalFileUpload = ({
  handleSubmit,
  handlerAction,
  action,
  reset,
  setValue,
  handlerDropdown,
  dropdownItems,
  isFetching = false,
  resetComponent = false
}) => {
  const [file, setFile] = useState([])
  // const { activeAllergicSymptom } = useSelector((state) => state.ui);

  const resetComponents = useCallback(() => {
    reset()
    setFile([])
    setValue('file', null)
  }, [reset, setValue])

  useEffect(() => {
    if (resetComponent) {
      resetComponents()
    }
  }, [resetComponent])

  const handleChangeFile = (document) => {
    setFile(document)
    setValue('file', document)
  }
  return (
    <>
      <article className="row">
        <div
          className={classname(
            {
              'col-md-6': file.length > 0,
              'col-md-12': file.length === 0,
              'd-flex justify-content-center': file.length === 0
            },
            'text-break'
          )}
        >
          <FileUpload handler={handleChangeFile} />
        </div>
        {file.length > 0 && (
          <div className="col-md-6">
            <DocumentCard documentNameDefault={file[0]?.name} />
          </div>
        )}
      </article>
      <CardFooter
        as={'footer'}
        className="d-flex justify-content-lg-between justify-content-center flex-wrap "
      >
        <HeaderButton
          buttonTitle={'Tipo Reporte'}
          handler={handlerDropdown}
          isDropdown={true}
          dropdownItems={dropdownItems}
        />

        <div className="d-flex flex-wrap">
          <Button
            variant="primary "
            size="lg"
            style={{
              minWidth: '15%'
            }}
            className="mx-2 my-1 w-sm-100"
            onClick={handleSubmit(handlerAction)}
            disabled={isFetching}
          >
            {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
          </Button>
          <Button
            variant="outline-secondary"
            size="lg"
            style={{
              minWidth: '15%'
            }}
            className="my-1 w-sm-100"
            onClick={resetComponents}
          >
            Cancelar
          </Button>
        </div>
      </CardFooter>
    </>
  )
}
