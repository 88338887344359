import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Link } from 'react-router-dom'
import peticionesAxios from '../../../services/peticionesAxios'
import Cabecera from '../../../services/AxiosCabeceras'
import { RegistroEmpresa } from './RegistroEmpresas'
import { UbicacionEmpresa } from './UbicacionEmpresa'
import { DatosEmpresas } from './DatosEmpresas'
import { CiSaveDown2 } from 'react-icons/ci'
import { useForm } from 'react-hook-form'
import {
  useLazyEmpresaByIdQuery,
  useSaveEmpresaMutation,
  useUpdateEmpresaMutation
} from '../../../services/rtk-query/clinicalApi'
import { toastAdapter } from '../../../plugins'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { ACTIONS } from '../../../constants'
import { Button } from 'react-bootstrap'

const defaultValues = {
  nombre: '',
  sucursal: null,
  pais: null,
  departamento: null,
  municipio: null,
  nrc: '',
  nit: '',
  razonSocial: '',
  actividadEconomica: null,
  patronoActual: '',
  agentePercepcion: false
}

export default function FormEmpresas() {
  const { id } = useParams()
  const navigator = useNavigate()
  const [saveEmpresa] = useSaveEmpresaMutation()
  const [empresaById] = useLazyEmpresaByIdQuery(id)
  const [updateEmpresa] = useUpdateEmpresaMutation()
  const { handleSubmit, control, reset, getValues, watch, setValue } = useForm({
    defaultValues
  })
  const [action, setAction] = useState(ACTIONS.SAVE)

  const [empresa, setEmpresa] = useState([])
  const [sucursal, setSucursal] = useState([])
  const [select, setSelect] = useState()
  const [departamento, setDepartamento] = useState([])
  const [loadDepartamento, setLoadDepartamento] = useState([])
  const [municipio, setMunicipio] = useState([])
  const [loadMunicipio, setLoadMunicipio] = useState([])
  const [cantones, setCantones] = useState([])
  const [loadCantones, setLoadCantones] = useState([])

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (id) {
        setAction(ACTIONS.EDIT)
        const { data } = await empresaById(id)
        setValue('nombre', data.nombre)
        setValue('nrc', data.nrc)
        setValue('nit', data.nit)
        setValue('razonSocial', data.razonSocial)
        setValue('patronoActual', data.patronoActual)
        setValue('agentePercepcion', data.agentePercepcion)
        setValue('pais', {
          value: data.pais?.id,
          label: data.pais?.descripcion
        })
        setValue('sucursal', {
          value: data.sucursal?.id,
          label: data.sucursal?.nombre
        })
        setValue(
          'departamento',
          data.departamento
            ? {
                value: data.departamento.id,
                label: data.departamento.descripcion
              }
            : null
        )
        setValue(
          'municipio',
          data.municipio
            ? {
                value: data.municipio.id,
                label: data.municipio.descripcion
              }
            : null
        )
        setValue('actividadEconomica', data.actividadEconomicaId)
      } else {
        setAction(ACTIONS.SAVE)
      }
    }
    fetchData()
  }, [empresaById, id, setValue])

  useEffect(() => {
    sucursales()
    apiDepartamento()
  }, [])

  const loadSelect = async (value) => {
    setSelect(value)
  }

  const departamentoLoad = async (value) => {
    setLoadDepartamento(value)
    try {
      const municipio = await peticionesAxios.find(
        `/generales/municipios/by_depto/${value.value}`,
        { headers: Cabecera.getHeader() }
      )
      const data = municipio.data

      const opciones = data.map((i) => ({
        value: i.municipio_id,
        label: i.nombre
      }))
      setMunicipio(opciones)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangesMunicipios = async (value) => {
    setLoadMunicipio(value)
    try {
      const apiCanton = await peticionesAxios.find(`generales/cantones/by_muni/${value.value}`, {
        headers: Cabecera.getHeader()
      })
      const data = apiCanton.data
      const opciones = data.map((e) => ({
        label: e.nombre,
        value: e.canton_id
      }))
      setCantones(opciones)
    } catch (error) {
      console.log(error)
    }
  }

  const sucursales = async () => {
    try {
      const { data: sucursales } = await peticionesAxios.find('sucursales', {
        headers: Cabecera.getHeader()
      })

      const opciones = sucursales?.data?.map((item) => ({
        value: item.id,
        label: item.nombre
      }))
      setSucursal(opciones)
    } catch (error) {
      return error
    }
  }

  const handleEmpresa = (e) => {
    setEmpresa({
      ...empresa,
      [e.target.id]: e.target.value
    })
  }

  const apiDepartamento = async () => {
    try {
      setDepartamento([])
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const saveAction = (data) => {
    const {
      departamento,
      pais,
      actividadEconomica,
      municipio,
      sucursal,
      razon,
      agentePercepcion,
      ...rest
    } = data
    const payload = {
      ...rest,
      paisId: pais?.value ?? null,
      sucursalId: sucursal?.value ?? null,
      municipioId: municipio?.value ?? null,
      departamentoId: departamento?.value ?? null,
      actividadEconomicaId: actividadEconomica?.value ?? null,
      agentePercecpcion: agentePercepcion,
      razonSocial: razon
    }

    if (action === ACTIONS.EDIT) {
      toastAdapter.promise({
        promise: updateEmpresa({ id: id, ...payload }).unwrap(),
        successMessage: (data) => {
          navigator('/administracion/empresas ')
          return 'Empresa actualizada con exito'
        },
        errorMessage: (error) => {
          console.log(error)
          return 'Error al actualizar la empresa'
        }
      })
    } else {
      toastAdapter.promise({
        promise: saveEmpresa(payload).unwrap(),
        successMessage: (data) => {
          navigator('/administracion/empresas ')
          return 'Empresa guardada con exito'
        },
        errorMessage: (error) => {
          console.log(error)
          return 'Error al actualizar la empresa'
        }
      })
    }
  }

  return (
    <Fragment>
      <LayoutForm
        tools={
          <Link className="btn btn-sm btn-light text-primary" to={'/administracion/empresas/'}>
            <i className="fa fa-arrow-left" />
            Regresar a la lista de empresas
          </Link>
        }
        title={'Administracion de Empresas'}
      >
        <dev className="card mb-4 shadow-none">
          <div className="card-header border">Registro de empresa</div>
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}
            >
              <div className="row gx mb-3">
                <div className="border mt-h-75">
                  <RegistroEmpresa
                    control={control}
                    sucursal={sucursal}
                    select={select}
                    loadSelect={loadSelect}
                    handleEmpresa={handleEmpresa}
                  />
                </div>
                <div className="card-header mt-5 border">Ubicacion de empresa</div>
                <div className="border mt-h-75 ">
                  <UbicacionEmpresa
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                  />
                </div>
                <div className="card-header border mt-5">Datos de la empresa</div>
                <div className="border">
                  <DatosEmpresas control={control} setValue={setValue} getValues={getValues} />
                </div>
              </div>
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  size="lg"
                  style={{
                    fontSize: '1.5rem'
                  }}
                  onClick={handleSubmit(saveAction)}
                >
                  <CiSaveDown2 /> Guardar
                </Button>
                <Button
                  variant="outline-secondary"
                  size="lg"
                  onClick={() => navigator('/administracion/empresas')}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </dev>
      </LayoutForm>
    </Fragment>
  )
}
