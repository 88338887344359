import { encrypt } from './Encriptaciones'

class HeaderAxios {
  getHeader() {
    const header = {
      //Se obtiene el token de la session
      Authorization: 'Bearer ' + encrypt.getSession('token')
      // ruta_o: window.location.pathname,
    }
    return header
  }
}

export default new HeaderAxios()
