/** @format */

import React, { Fragment, useState, useEffect } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import ExportarYAgregar from '../../../components/botones/ExportarYAgregar'
import { TablePlugin } from '../../../plugins/components/TablePlugin'
import { alertConfirm, toastAdapter } from '../../../plugins'
import { useDeleteEmpresaMutation, useEmpresasQuery } from '../../../services/rtk-query/clinicalApi'
import { Badge } from 'react-bootstrap'
import ButtonsACtionsFactories from '../../../components/botones/ButtonsActionsFactories'
import { usePermissions } from '../../../hooks'
import { modules, PERMISSIONS } from '../../../constants'

export default function Empresas() {
  const { hasPermission } = usePermissions()
  const [deleteEmpresa] = useDeleteEmpresaMutation()
  const { data: empresas, isFetching } = useEmpresasQuery()
  //state inicial

  //eliminar una comercial
  const handleDelete = async ({ id, isActive }) => {
    const respo = await alertConfirm({
      title: '¿Estas seguro?',
      text: isActive ? 'Inhabilitar Empresa' : 'Habilitar Empresa',
      icon: 'question',
      confirmButtonText: isActive ? 'Inhabilitar' : 'Habilitar',
      cancelButtonText: 'Cancelar'
    })
    if (respo) {
      toastAdapter.promise({
        promise: deleteEmpresa({ id, activo: !isActive }).unwrap(),
        errorMessage: (error) => {
          return error.message
        },
        successMessage: 'Empresa eliminada con exito'
      })
    }
  }

  //columnas
  const columnDefs = [
    { header: 'Id', accessorKey: 'id' },
    { header: 'Nombre', accessorKey: 'nombre' },
    { header: 'Nit', accessorKey: 'nit' },
    { header: 'NRC', accessorKey: 'nrc' },
    {
      header: 'Pais',
      accessorKey: 'pais',
      cell: ({ row: { original } }) => {
        return original?.pais?.descripcion ?? null
      }
    },
    {
      header: 'Estado',
      accessorKey: 'activo',
      cell: ({ row: { original } }) => {
        return original.activo ? (
          <Badge bg="primary" pill>
            Activo
          </Badge>
        ) : (
          <Badge bg="danger" fill>
            Inactivo
          </Badge>
        )
      }
    },
    {
      header: 'Opciones',
      accessorKey: 'id',
      cell: rankFormatter
    }
  ]

  function rankFormatter({ row: { original } }) {
    return (
      <ButtonsACtionsFactories
        hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.empresas)}
        ruta={`/administracion/empresas/${original.id}`}
        handler={() => handleDelete({ id: original.id, isActive: original.activo })}
        cell={original}
      />
    )
  }

  return (
    <Fragment>
      <LayoutForm title="Empresas">
        <div className="card shadow-none mb-4">
          <div className="card-header">Lista de Empresas</div>
          <div className="card-body">
            <ExportarYAgregar
              hasPermission={!hasPermission([PERMISSIONS.WRITE], modules.empresas)}
              nombre={'Agregar nueva empresa'}
              ruta={'/administracion/empresas/nuevo'}
            />
            <TablePlugin data={empresas ?? []} columns={columnDefs} isFetching={isFetching} />
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
