/** @format */

import React, { useEffect, useMemo, useState, Suspense, useContext } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import BtnModal from '../../../components/botones/BtnModal'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Button, Col, Form, FormGroup, Row, Badge } from 'react-bootstrap'
import { Loader } from '../../../components/ui/Loader'
import {
  useConsultaDiagnosticosQuery,
  useCreateDiagnosticoMutation,
  useDiagnosticosQuery,
  useSeveritiesQuery,
  useUpdateDiagnosticoMutation
} from '../../../services/rtk-query/clinicalApi'
import Select from 'react-select'
import { clearDiagnosticoActivo, toggleModal } from '../../../store/slices/uiSlice'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { useParams } from 'react-router-dom'
import { NestedConsultasDetails } from '../NestedConsultasDetails'
import { TablePlugin } from '../../../plugins/components/TablePlugin'
import { OptionsEnfermedades } from './components/OptionsEnfermedades'
import { format } from 'date-fns'
import { AsyncSelectCreate } from '../../../components/selects/AsyncSelectCreate'
import { PermissionsContext } from '../../../context/permissionsContext'
import { modules, PERMISSIONS } from '../../../constants'

const rules = {
  required: {
    value: true,
    message: 'Este campo es requerido'
  }
}
export default function ListEnfermedades() {
  const { hasPermission } = useContext(PermissionsContext)

  const { consultaId } = useParams()
  const [filtering, setFiltering] = useState('')
  const { diagnosticoActivo } = useSelector((state) => state.ui)
  const { data: consultas, isFetching } = useConsultaDiagnosticosQuery(consultaId)
  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id'
      },
      {
        header: 'Diagnóstico',
        accessorKey: 'diagnostico'
      },
      {
        header: 'Fecha de Inicio',
        accessorKey: 'fechaInicio',
        cell: ({ row: { original } }) => <p>{original.fechaInicio?.split('T').shift()}</p>
      },

      {
        header: 'Severidad',
        accessorKey: 'severidades.nombre',
        cell: ({ row: { original } }) => {
          if (!original?.severidades) return null

          return (
            <Badge
              bg={
                original?.severidades?.nombre === 'Severa'
                  ? 'danger'
                  : original.severidades.nombre === 'Leve'
                    ? 'secondary'
                    : 'primary'
              }
            >
              {original.severidades.nombre}
            </Badge>
          )
        }
      },
      {
        header: 'En Tratamiento',
        accessorKey: 'actualmenteTratamiento',
        cell: ({ row: { original } }) => (
          <Badge bg={original.actualmenteTratamiento ? 'primary' : 'secondary'}>
            {original.actualmenteTratamiento ? 'SI' : 'NO'}
          </Badge>
        )
      },
      {
        header: 'Acciones',
        accessorkey: 'id',
        cell: ({ row: { original } }) => <OptionsEnfermedades cell={original.id} />
      }
    ],
    []
  )
  const [updateDiagnostico] = useUpdateDiagnosticoMutation()
  const [createDiagnostico] = useCreateDiagnosticoMutation()
  const [, setCustomDisease] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(false)
  const { isError, isLoading, data, isSuccess } = useSeveritiesQuery()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')
  const {
    data: enfermedades,
    isSuccess: okEnfermedades,
    isFetching: fetchingEnfermedades
  } = useDiagnosticosQuery(filter)
  const createOption = (label) => {
    return {
      label,
      value: label,
      id: label
    }
  }

  const filterFn = (inputValue) => {
    return enfermedades
      ?.filter((i) => {
        return i?.nombres?.toLowerCase().includes(inputValue?.toLowerCase())
      })
      .map((item) => ({
        value: item.id,
        label: item.nombres,
        ...item
      }))
  }

  const loadOptions = (inputValue, callback) => {
    setFilter(inputValue)
    if (okEnfermedades) {
      callback(filterFn(inputValue))
    }
  }
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      diagnostico: '',
      estadoEnfermedad: '',
      enfermedadActiva: false,
      enfermedadInfecciosa: false,
      curada: false,
      severidad: '',
      fechaDiagnostico: '',
      edadAlMomentodelDiagnostico: '',
      enfermedad: '',
      contraidoSemanaEmbarazo: '',
      advertenciaEmbarazo: false,
      actualmenteEnTratamiento: false,
      diagnosticoId: null
    }
  })

  useEffect(() => {
    if (diagnosticoActivo) {
      const fechaHora = diagnosticoActivo?.fechaInicio
        ? new Date(diagnosticoActivo?.fechaInicio)
        : null
      setValue('fechaDiagnostico', !fechaHora ? '' : format(fechaHora, 'yyyy-MM-dd'))
      setValue('estadoEnfermedad', diagnosticoActivo?.estadoEnfermedad)
      setValue('enfermedadActiva', diagnosticoActivo?.enfermedadActiva)
      setValue('enfermedadInfecciosa', diagnosticoActivo.enfermedadInfecciosa)
      setValue('actualmenteEnTratamiento', diagnosticoActivo.actualmenteTratamiento)
      setValue('advertenciaEmbarazo', diagnosticoActivo.advertenciaEmbarazo)

      setValue('contraidoSemanaEmbarazo', diagnosticoActivo?.contraidoSemanaEmbarazo)

      setValue('curada', diagnosticoActivo?.curada)

      setValue('edadAlMomentodelDiagnostico', diagnosticoActivo?.edadEnfermedadDiagnostico)
      if (diagnosticoActivo?.diagnosticoId) {
        setValue('diagnostico', {
          label: diagnosticoActivo?.diagnostico,
          value: diagnosticoActivo?.diagnosticoId
        })
      } else {
        setValue('diagnostico', createOption(diagnosticoActivo?.diagnostico))
      }

      setValue(
        'severidad',
        data
          ?.map((severity) => ({ label: severity.nombre, value: severity.id }))
          .find((severity) => severity.value === diagnosticoActivo?.severidadId)
      )
    }
  }, [diagnosticoActivo])

  const onSaveDiagnostico = (data) => {
    setIsDisableButton(true)
    const {
      diagnostico,
      estadoEnfermedad,
      enfermedadActiva,
      enfermedadInfecciosa,
      curada,
      severidad,
      fechaDiagnostico,
      edadAlMomentodelDiagnostico,
      enfermedad,
      contraidoSemanaEmbarazo,
      advertenciaEmbarazo,
      actualmenteEnTratamiento,
      diagnosticoId
    } = data
    const diagnostic = diagnostico?.label ? diagnostico?.label : diagnostico

    const payload = {
      consultaId: consultaId,
      diagnosticoId,
      diagnostico: diagnostic,
      severidad: severidad?.value,
      enfermedadActiva,
      enfermedadInfecciosa,
      fechaDiagnostico: fechaDiagnostico ?? null,
      edadDiagnostico: edadAlMomentodelDiagnostico,
      curada,
      enfermedadAlergia: enfermedad,
      advertenciaEmbarazo,
      enTratamiento: actualmenteEnTratamiento,
      contraidoSemanaEmbarazo,
      estadoEnfermedad
    }

    toastAdapter
      .promise({
        promise: createDiagnostico(payload).unwrap(),
        successMessage: () => {
          resetForm()
          return 'diagnostico creado exitosamente'
        },
        errorMessage: (error) => {
          let errorMessage = 'Error al intentar guardar el diagnostico'
          if (error.status === 400) {
            if (Array.isArray(error.data?.message)) {
              errorMessage = error.data.message.join(', ')
            } else if (typeof error.data?.message === 'string') {
              errorMessage = error.data.message
            } else if (error.data?.error) {
              errorMessage = error.data.error
            }
          }
          console.error('Error details:', error) // Log the full error for debugging
          return errorMessage
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsDisableButton(false))
  }
  const onUpdateDiagnostico = (data) => {
    setIsDisableButton(true)
    const {
      diagnostico,
      estadoEnfermedad,
      enfermedadActiva,
      enfermedadInfecciosa,
      curada,
      severidad,
      fechaDiagnostico,
      edadAlMomentodelDiagnostico,
      enfermedad,
      contraidoSemanaEmbarazo,
      advertenciaEmbarazo,
      actualmenteEnTratamiento,
      diagnosticoId
    } = data
    let diagnostic = diagnostico?.label ? diagnostico?.label : diagnostico
    if (diagnostic?.label === '' || !diagnostic) {
      diagnostic = undefined
    }

    console.log({ diagnostic })
    const payload = {
      consultaId: consultaId,
      diagnosticoId,
      diagnostico: diagnostic,
      severidad: severidad?.value,
      enfermedadActiva,
      enfermedadInfecciosa,
      fechaDiagnostico: fechaDiagnostico ?? null,
      edadDiagnostico: edadAlMomentodelDiagnostico,
      curada,
      enfermedadAlergia: enfermedad,
      advertenciaEmbarazo,
      enTratamiento: actualmenteEnTratamiento,
      contraidoSemanaEmbarazo,
      estadoEnfermedad
    }

    toastAdapter
      .promise({
        promise: updateDiagnostico({
          body: payload,
          id: diagnosticoActivo.id
        }).unwrap(),
        loadingMessage: 'actualizando...',
        successMessage: () => {
          resetForm()
          return 'diagnostico actualizado'
        },
        errorMessage: (error) => {
          return 'Error al intentar actualizar el diagnostico'
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setIsDisableButton(false))
  }

  const resetForm = () => {
    dispatch(clearDiagnosticoActivo())
    setCustomDisease(false)
    reset()
    dispatch(toggleModal())
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="card mb-4 shadow-none">
        <div className="card-title p-2">
          <h3>Listado de Enfermedades </h3>
        </div>
        <div className="card-body">
          <BtnModal
            isDisabled={!hasPermission([PERMISSIONS.WRITE], modules.consulta_enfermedad_cie)}
            agregar={() => {
              dispatch(clearDiagnosticoActivo())
              dispatch(toggleModal())
            }}
            nombre="Crear Diagnóstico"
          />
          <TablePlugin
            columns={columns}
            data={consultas}
            filtering={filtering}
            onFilteringChange={(data) => setFiltering(data)}
            isFetching={isFetching}
            isInternalFiltering={false}
          />
        </div>
      </div>
      <NestedConsultasDetails
        title={'DETALLE DE ENFERMEDADES'}
        ButtonComponent={() => {
          if (!diagnosticoActivo) {
            return (
              <Button
                className="w-25 display-6"
                size="lg"
                disabled={
                  isDisableButton ||
                  !hasPermission([PERMISSIONS.WRITE], modules.consulta_enfermedad_cie)
                }
                onClick={handleSubmit(onSaveDiagnostico)}
              >
                <small>Guardar Diagnostico</small>
              </Button>
            )
          } else {
            return (
              <Button
                className="w-50 display-6"
                size="lg"
                disabled={
                  isDisableButton ||
                  !hasPermission([PERMISSIONS.WRITE], modules.consulta_enfermedad_cie)
                }
                onClick={handleSubmit(onUpdateDiagnostico)}
              >
                <small>Actualizar Diagnostico</small>
              </Button>
            )
          }
        }}
        resetFunction={() => {
          setCustomDisease(false)
          dispatch(clearDiagnosticoActivo())
          reset()
          resetForm()
        }}
      >
        <Row>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <Form.Group>
              <Form.Label>Enfermedad:</Form.Label>

              <Controller
                control={control}
                name="diagnostico"
                rules={rules}
                render={({ field }) => (
                  <AsyncSelectCreate
                    {...field}
                    loadOptions={loadOptions}
                    isLoading={fetchingEnfermedades}
                    placeholder="Seleccione una enfermedad o cree una nueva"
                  />
                )}
              />
              {errors?.diagnostico && (
                <Form.Text className="text-danger">{errors?.diagnostico?.message}</Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <Form.Group>
              <Form.Label>Estado Enfermedad:</Form.Label>
              <Controller
                control={control}
                name="estadoEnfermedad"
                rules={rules}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Escriba el estado de la enfermedad"
                    {...field}
                  />
                )}
              />

              {errors?.estadoEnfermedad && (
                <Form.Text className="text-danger">{errors?.estadoEnfermedad?.message}</Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <div>
              <Form.Group
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20
                }}
              >
                <Controller
                  control={control}
                  name="enfermedadActiva"
                  render={({ field }) => (
                    <Form.Check
                      checked={field.value}
                      {...field}
                      type="switch"
                      id="enfermedadActiva"
                      label="Enfermedad Activa (opcional)"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="enfermedadInfecciosa"
                  render={({ field }) => (
                    <Form.Check
                      checked={field.value}
                      {...field}
                      type="switch"
                      id="enfermedadInfecciosa"
                      label="Enfermedad Infecciosa (opcional)"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="curada"
                  render={({ field }) => (
                    <Form.Check
                      checked={field.value}
                      {...field}
                      type="switch"
                      id="curada"
                      label="Curada? (opcional)"
                    />
                  )}
                />
              </Form.Group>
            </div>
          </Col>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <Form.Group>
              {isError && <Alert variant="danger">Ocurrio un error al cargar</Alert>}
              {isLoading && <Loader />}
              <Form.Label>Severidad (opcional):</Form.Label>
              <Controller
                control={control}
                name="severidad"
                render={({ field }) => (
                  <Select
                    isClearable
                    {...field}
                    options={
                      isSuccess &&
                      data?.map((severity) => ({
                        value: severity.id,
                        label: severity.nombre
                      }))
                    }
                  />
                )}
              />
              {errors?.severidad && (
                <Form.Text className="text-danger">{errors?.severidad?.message}</Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <Form.Group>
              <Form.Label>Fecha Diagnóstico (opcional): </Form.Label>
              <Controller
                control={control}
                name="fechaDiagnostico"
                rules={rules}
                render={({ field }) => (
                  <Form.Control type="date" placeholder="2024-03-11" {...field} />
                )}
              />
              {errors?.fechaDiagnostico && (
                <Form.Text className="text-danger">{errors?.fechaDiagnostico?.message}</Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={12} sm={12} lg={12} className="mb-3">
            <Form.Group>
              <Form.Label>Edad al momento del diagnóstico (opcional): </Form.Label>
              <Controller
                control={control}
                name="edadAlMomentodelDiagnostico"
                render={({ field }) => <Form.Control type="number" placeholder="0" {...field} />}
              />
              {errors?.edadAlMomentodelDiagnostico && (
                <Form.Text className="text-danger">
                  {errors?.edadAlMomentodelDiagnostico?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md={12} lg={12} className="mb-3">
            <FormGroup>
              <Form.Label>Contraido en semana de embarazo numero? (opcional)</Form.Label>
              <Controller
                control={control}
                name="contraidoSemanaEmbarazo"
                render={({ field }) => <Form.Control type="number" placeholder="0" {...field} />}
              />
            </FormGroup>
          </Col>
          <Col md={12} lg={12} className="mb-3">
            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20
              }}
            >
              <Controller
                control={control}
                name="advertenciaEmbarazo"
                render={({ field }) => (
                  <Form.Check
                    checked={field.value}
                    {...field}
                    type="switch"
                    id="advertenciaEmbarazo"
                    label="Advertencia durante el embarazo (opcional)"
                  />
                )}
              />
              <Controller
                control={control}
                name="actualmenteEnTratamiento"
                render={({ field }) => (
                  <Form.Check
                    checked={field.value}
                    {...field}
                    type="switch"
                    size={20}
                    id="actualmenteEnTratamiento"
                    label="Actualmente en tratamiento (opcional)"
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </NestedConsultasDetails>
    </Suspense>
  )
}
