const filterItems = ({ items, inputValue, key }) => {
  return items?.filter((i) => i?.[key]?.toLowerCase().includes(inputValue?.toLowerCase()))
}

const loadOptions = ({ isSuccess, filterFunction, inputValue, callback }) => {
  if (isSuccess) {
    return callback(filterFunction(inputValue))
  }
}

export { filterItems, loadOptions }
