import { loadingAlert, selectAlert } from '../plugins'
import { encrypt } from '../services/Encriptaciones'
export const handlerFetcherDoctor = async (queryFn) => {
  if (!queryFn) throw new Error('queryFn is required')

  loadingAlert({
    flag: true,
    text: 'Cargando Medicos',
    title: 'Cargando...'
  })
  const { data, isSuccess, isLoading } = await queryFn()

  if (!isLoading) {
    loadingAlert({ flag: false })
  }
  if (!isSuccess) return

  const inputOptions = data
    ?.map((medico) => ({
      [medico.id]: `${medico.nombres} ${medico.apellidos}`
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const response = await selectAlert({
    title: 'Seleccione un medico',
    text: 'Seleccione un Medico para continuar',
    inputPlaceHolder: 'Seleccione un medico',
    inputOptions
  })
  if (!response.isConfirmed) return

  return response.value
}
export const handlerCheckDoctor = async (queryFn) => {
  if (!queryFn) throw new Error('queryFn is required')

  const doctor = JSON.parse(encrypt.getSession('medico'))
  if (!doctor?.medico?.id) {
    return await handlerFetcherDoctor(queryFn)
  }
  return doctor?.medico?.id
}
