import React, { useContext, useState } from 'react'
import AnalisisForm from './analisis/AnalisisForm'
import FormCardio from './cardio/cardioVascular'
import FormDigestivo from './digestivo/FormDigestivo'
import FormEndocrino from './endocrino/FormEndocrino'
import FormEsqueletico from './esqueletico/FormEsqueletico'
import FormGenerales from './generales/FormGenerales'
import FormHemoli from './hemoli/FormHemoli'
import FormMamas from './mamas/FormMamas'
import PanelInterrogatorio from './PanelInterrogatorio'
import FormPriel from './piel/FormPriel'
import FormReproductivo from './reproductivo/FormReproductivo'
import FormRespiratorio from './respiratorio/FormRespiratorio'
import FormNervioso from './sistemaNervioso/FormNervioso'
import FormUrinario from './urinario/FormUrinario'
import {
  useLazyMedicosQuery,
  useSaveInterrogationMutation,
  useUpdateConsultaMutation
} from '../../../services/rtk-query'
import { Button } from 'react-bootstrap'
import { BsSave } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { formatData } from './utils'
import { toastAdapter } from '../../../plugins'
import { useParams } from 'react-router-dom'
import { setConsultaTabFalse, setInterrogatorioTab } from '../../../store/slices/uiSlice'
import { useNavigate } from 'react-router-dom'
import { PermissionsContext } from '../../../context'
import { modules, PERMISSIONS } from '../../../constants'
import { handlerCheckDoctor } from '../../../helpers/checkDoctor'

const FormPrincipalInterrogatorio = () => {
  const { hasPermission } = useContext(PermissionsContext)
  const { id: expedienteId, consultaId } = useParams()
  const data = useSelector((state) => state.interrogatorio)
  const [updateConsulta] = useUpdateConsultaMutation()
  const [saveInterrogationMutation] = useSaveInterrogationMutation()
  const [doctorFn] = useLazyMedicosQuery()
  const dispatch = useDispatch()
  const navigator = useNavigate()
  // const { data: consulta } =
  //   clinicalApi.endpoints.consultaById.useQuery(consultaId);

  const [interrogatorio, setInterrogatorio] = useState({
    cardioVascular: false,
    digestivo: false,
    endocrino: false,
    hemoli: false,
    mamas: false,
    esqueletico: false,
    piel: false,
    Reproductivo: false,
    respiratorio: false,
    nervioso: false,
    generales: false,
    urinario: false,
    analisis: true
  })

  const cardioVascular = () => {
    setInterrogatorio({
      cardioVascular: true,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }

  const handleDigestivo = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: true,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleEndocrino = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: true,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleHemoli = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: true,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleMamas = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: true,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleEsqueletico = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: true,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handlepiel = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: true,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleReproductivo = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: true,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleRespiratorio = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: true,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleNervioso = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: true,
      generales: false,
      urinario: false,
      analisis: false
    })
  }
  const handleGenerales = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: true,
      urinario: false,
      analisis: false
    })
  }
  const handleUrinario = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: true,
      analisis: false
    })
  }
  const analisis = () => {
    setInterrogatorio({
      cardioVascular: false,
      digestivo: false,
      endocrino: false,
      hemoli: false,
      mamas: false,
      esqueletico: false,
      piel: false,
      Reproductivo: false,
      respiratorio: false,
      nervioso: false,
      generales: false,
      urinario: false,
      analisis: true
    })
  }

  const handleSave = async () => {
    const dataFormatted = formatData(data)

    if (!consultaId) {
      return await save(dataFormatted)
    } else {
      toastAdapter
        .promise({
          promise: updateConsulta({
            body: {
              ...dataFormatted,
              idExpediente: expedienteId
            },
            consultaId
          }).unwrap(),
          loadingMessage: 'Guardando Interrogatorio...',
          successMessage: 'Interrogatorio guardado correctamente',
          errorMessage: 'Error al guardar el interrogatorio'
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const save = async (dataFormatted) => {
    try {
      const doctor = await handlerCheckDoctor(doctorFn)
      if (!doctor) return

      handleSave1(doctor, dataFormatted)
    } catch (error) {
      console.error('ERROR AL CREAR LA CONSULTA')
    }
  }
  const handleSave1 = async (medico, dataFormatted) => {
    if (dataFormatted) {
      toastAdapter.promise({
        promise: saveInterrogationMutation({
          idExpediente: expedienteId,
          medicoId: medico,
          ...dataFormatted
        }).unwrap(),
        successMessage: (data) => {
          dispatch(setInterrogatorioTab())
          dispatch(setConsultaTabFalse())
          navigator(
            `/expedientes-medicos/${expedienteId}/consultas/${data?.id}/?activeTab=consulta`,
            {
              replace: true
            }
          )
          return 'Consulta Creada'
        },
        errorMessage: (error) => {
          return 'Error al crear consulta'
        }
      })
    } else {
      toastAdapter.promise({
        promise: saveInterrogationMutation({
          idExpediente: expedienteId,
          medicoId: medico
        }).unwrap(),
        successMessage: (data) => {
          dispatch(setInterrogatorioTab())
          dispatch(setConsultaTabFalse())
          navigator(`/expedientes-medicos/${expedienteId}?activeTab=consulta`, {
            replace: true
          })
          return 'Consulta Creada'
        },
        errorMessage: (error) => {
          return 'Error al crear consulta'
        }
      })
    }
  }
  return (
    <>
      <PanelInterrogatorio
        hemoli={handleHemoli}
        mamas={handleMamas}
        esqueletico={handleEsqueletico}
        piel={handlepiel}
        reproductivo={handleReproductivo}
        respiratorio={handleRespiratorio}
        nervioso={handleNervioso}
        generales={handleGenerales}
        urinario={handleUrinario}
        endocrino={handleEndocrino}
        digestivo={handleDigestivo}
        analisis={analisis}
        cardioVascular={cardioVascular}
      >
        {interrogatorio.cardioVascular ? (
          <FormCardio />
        ) : interrogatorio.digestivo ? (
          <FormDigestivo />
        ) : interrogatorio.endocrino ? (
          <FormEndocrino />
        ) : interrogatorio.hemoli ? (
          <FormHemoli />
        ) : interrogatorio.mamas ? (
          <FormMamas />
        ) : interrogatorio.esqueletico ? (
          <FormEsqueletico />
        ) : interrogatorio.piel ? (
          <FormPriel />
        ) : interrogatorio.Reproductivo ? (
          <FormReproductivo />
        ) : interrogatorio.respiratorio ? (
          <FormRespiratorio />
        ) : interrogatorio.nervioso ? (
          <FormNervioso />
        ) : interrogatorio.generales ? (
          <FormGenerales />
        ) : interrogatorio.urinario ? (
          <FormUrinario />
        ) : interrogatorio.analisis ? (
          <AnalisisForm />
        ) : null}
      </PanelInterrogatorio>
      <Button
        variant="primary"
        size="lg"
        onClick={handleSave}
        disabled={!hasPermission([PERMISSIONS.WRITE], modules.consulta_interrogatorio)}
      >
        <BsSave size={24} className="mr-2" /> Guardar Interrogatorio
      </Button>
    </>
  )
}

export default FormPrincipalInterrogatorio
