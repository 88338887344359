import React from 'react'

import AsyncSelect from 'react-select/async'

export const AsyncSelectCustom = ({
  loadOptions,
  isLoading,
  placeholder = 'Seleccione...',
  defaultOptions,
  isclearable = false,
  isDisabled = false,
  ...rest
}) => {
  return (
    <AsyncSelect
      isClearable={isclearable}
      {...rest}
      loadingMessage={() => 'Cargando...'}
      noOptionsMessage={() => 'No hay opciones'}
      isLoading={isLoading}
      loadOptions={loadOptions}
      cacheOptions
      placeholder={placeholder}
      isDisabled={isDisabled}
      createOptionPosition="last"
      defaultOptions={defaultOptions}
      {...rest}
    />
  )
}
