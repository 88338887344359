import { IoCreateOutline } from 'react-icons/io5'
import { BsTrash3 } from 'react-icons/bs'
import { RiMenuFill } from 'react-icons/ri'
import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setActiveSubSpeciality, showSubEspecialidadModal } from '../../../store/slices/uiSlice'
import classNames from 'classnames'
import { alertConfirm } from '../../../plugins/sweetAlert.plugin'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { useDeleteSubEspecialidadMutation } from '../../../services/rtk-query/clinicalApi'
import { PermissionsContext } from '../../../context'
import { modules, PERMISSIONS } from '../../../constants'

export const ModalSubOptions = ({ cell }) => {
  const { hasPermission } = useContext(PermissionsContext)
  const dispatch = useDispatch()
  const [deleteEspecialiad] = useDeleteSubEspecialidadMutation()
  const edit = () => {
    dispatch(setActiveSubSpeciality(cell))
    dispatch(showSubEspecialidadModal())
  }
  const onDelete = async () => {
    const response = await alertConfirm({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Si, eliminar',
      text: 'No podras revertir esta acción',
      title: '¿Estás seguro?'
    })
    if (response) {
      toastAdapter.promise({
        promise: deleteEspecialiad(cell.id).unwrap(),
        loadingMessage: 'Eliminando subbv especialidad',
        successMessage: 'Sub Especialidad eliminada con éxito',
        errorMessage: 'No se pudo eliminar la sub  especialidad'
      })
    }
  }
  return (
    <Dropdown drop="start">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <RiMenuFill />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={edit}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.medicos)}
        >
          <IoCreateOutline /> Editar
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onDelete}
          className={classNames('text-danger', {
            opacity: !hasPermission([PERMISSIONS.WRITE], modules.medicos)
          })}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.medicos)}
        >
          <BsTrash3 /> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
