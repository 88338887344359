import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toastAdapter } from '../plugins/hot-toast.plugin'

export const useNavigateFolder = (folderByIdFn) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = () => setIsLoading((prev) => !prev)
  const handleFetchAndNavigate = useCallback(
    async (date) => {
      if (!date.pacienteId) return

      toggleLoading()
      const { data, isError } = await folderByIdFn(date.pacienteId)
      toggleLoading()

      if (isError) return toastAdapter.error({ message: 'Error al obtener el expediente' })

      if (!data.length) return toastAdapter.info({ message: 'El paciente no tiene expediente' })

      navigate(`/expedientes-medicos/${data[0].id}?activeTab=consulta`)
    },
    [folderByIdFn, navigate]
  )

  return { handleFetchAndNavigate, isLoading }
}
