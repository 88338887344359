import React from 'react'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const NoPermissions = ({ showReturnButton = true }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1) // Navegar a la página anterior
  }

  return (
    <Container className="text-center py-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <FaCheckCircle size={48} color="blue" />
          <h2 className="mt-4 mb-3">No tienes permisos para ver estas secciones</h2>
          <Alert variant="secondary">
            <p>Por favor, contacta al administrador si crees que esto es un error.</p>
            {showReturnButton && <Button onClick={handleBack}>Volver</Button>}
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}

export default NoPermissions
