import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import classNames from 'classnames'
import { useDeleteEtsMutation, useGetEtsByIdQuery } from '../../../../services/rtk-query'
import { useDispatch } from 'react-redux'
import { activarModalGenerico, setActiveEts } from '../../../../store/slices/uiSlice'
import { ACTIONS } from '../../../../constants'
import { alertConfirm, toastAdapter } from '../../../../plugins'

export const Option = ({ cell, setAction, activo, ...rest }) => {
  const [deleteEtsMutation] = useDeleteEtsMutation()
  const dispatch = useDispatch()
  const [skip, setSkip] = useState(true)

  const { data, isSuccess, isFetching } = useGetEtsByIdQuery(cell, {
    skip,
    refetchOnMountOrArgChange: true
  })
  const onEvent = () => {
    setSkip(false)
  }

  const onDelete = () => {
    alertConfirm({
      title: 'Habilitar o Desabilitar registro',
      text: '¿Estas seguro de Habilitar o Desabilitar este registro?',
      icon: 'warning',
      confirmButtonText: 'Si, Habilitar o Desabilitar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result) {
        return toastAdapter
          .promise({
            promise: deleteEtsMutation({ id: cell, activo }).unwrap(),
            success: 'Registro Habilitado o Inhabilitado con éxito',
            error: (error) => {
              if (error.data.message) return error.data.message

              return 'Error al habilitar o deshabilitar el registro'
            }
          })
          .catch((err) => {
            // console.log(err);
          })
      }
    })
  }

  // Actualizar el estado con los datos de la consulta cuando la consulta tenga éxito
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setAction(ACTIONS.UPDATE)
        dispatch(setActiveEts(data))
        dispatch(activarModalGenerico())

        setSkip(true)
      }, 300)
    }
  }, [isSuccess, data, dispatch, isFetching])
  return (
    <Dropdown flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onEvent} disabled={rest.hasPermission}>
          <FiEdit /> Actualizar
        </Dropdown.Item>
        <Dropdown.Item
          className={classNames('text-danger', {
            opacity: rest.hasPermission
          })}
          onClick={onDelete}
          disabled={rest.hasPermission}
        >
          Habilitar / Deshabilitar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
