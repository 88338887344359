import 'react-phone-input-2/lib/style.css'
import React, { Fragment, useState, useEffect } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Link } from 'react-router-dom'
import peticionesAxios from '../../../services/peticionesAxios'
import Cabeceras from '../../../services/AxiosCabeceras'
import { useNavigate, useParams } from 'react-router-dom'
import BotonesFooter from '../../../components/botones/BotonesFooter'
import { toastAdapter } from '../../../plugins/hot-toast.plugin'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
const validForm = yup.object().shape({
  codigo: yup.string().required('El codigo es requerido'),
  nombre: yup.string().required('El nombre es requerido'),
  direccion: yup.string(),
  telefono: yup.string(),
  email: yup.string().email('El email debe ser válido')
})

export default function FormAdministracion() {
  const navigate = useNavigate()
  const params = useParams()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    resolver: yupResolver(validForm),
    defaultValues: {
      codigo: '',
      nombre: '',
      direccion: '',
      telefono: '',
      email: ''
    }
  })

  //estado para Surtudira del form
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (params.id) {
      sucursal(params.id)
    }
  }, [params.id])

  const POSTSucursales = async (data) => {
    try {
      const newSucursal = {
        codigo: data.codigo,
        nombre: data.nombre,
        direccion: data.direccion,
        telefono: data.telefono,
        email: data.email
      }
      const sucursal = await peticionesAxios.POST('/sucursales', newSucursal, {
        headers: Cabeceras.getHeader()
      })
      if (sucursal.status === 201) {
        toastAdapter.success({
          message: 'Sucursal registrada'
        })
        navigate('/empresas/sucursales')
      } else {
        toastAdapter.error({
          message: 'Hubo un error'
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const PUTSucursales = async (data) => {
    try {
      const editSucursales = {
        codigo: data.codigo,
        nombre: data.nombre,
        direccion: data.direccion,
        telefono: data.telefono,
        email: data.email ?? '',
        surtidora: data.surtidora,
        compra: data.compra
      }
      const updateSucursal = await peticionesAxios.update(
        'sucursales/' + params.id,
        editSucursales,
        { headers: Cabeceras.getHeader() }
      )
      if (updateSucursal.status === 200) {
        toastAdapter.success({ message: 'Sucursal modificada' })
        navigate('/empresas/sucursales')
      } else {
        toastAdapter.error({ message: 'hubo un error' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //hademos peticon a get id sucursal
  const sucursal = async (id) => {
    try {
      const { data } = await peticionesAxios.find(`/sucursales/${id}`)
      setValue('codigo', data.codigo)
      setValue('nombre', data.nombre)
      setValue('direccion', data.direccion)
      setValue('telefono', data.telefono)
      setValue('email', data.email)
      setEdit(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSave = (data) => {
    if (!params.id) {
      return POSTSucursales(data)
    }

    return PUTSucursales(data)
  }

  return (
    <Fragment>
      <LayoutForm
        tools={
          <Link className="btn btn-sm btn-light text-primary" to={'/empresas/sucursales'}>
            <i className="fa fa-arrow-left" />
            Regresar a la lista de sucursales
          </Link>
        }
        title={edit ? 'Modificacion de sucursal' : 'Administracion de sucursales'}
      >
        <div className="card mb-4 shadow-none">
          <div className="card-header">Registro de Sucursal</div>
          <div className="card-body">
            <div className="row gx mb-3">
              <div className="col-md-12">
                <label htmlFor="" className="small mb-1">
                  Codigo:
                </label>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text  "
                      className={`form-control `}
                      placeholder="Ingrese un codigo"
                      {...field}
                    />
                  )}
                />
                {errors.codigo && (
                  <Form.Text className="text-danger">{errors.codigo.message}</Form.Text>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="" className="small mb-1">
                  Nombre:
                </label>

                <Controller
                  control={control}
                  name="nombre"
                  render={({ field }) => (
                    <input
                      type="text  "
                      className={`form-control `}
                      placeholder="Ingrese un codigo"
                      {...field}
                    />
                  )}
                />
                {errors.nombre && (
                  <Form.Text className="text-danger">{errors.nombre.message}</Form.Text>
                )}
              </div>

              <div className="row gx-3 mb-3">
                <div className="col-md-12 mt-4">
                  <label htmlFor="" className="small mb-1">
                    Direccion:
                  </label>
                  <Controller
                    control={control}
                    name="direccion"
                    render={({ field }) => <Form.Control as="textarea" rows={3} {...field} />}
                  />
                  {errors.direccion && (
                    <Form.Text className="text-danger">{errors.direccion.message}</Form.Text>
                  )}
                </div>
                <div className="col-md-12 mt-4">
                  <label htmlFor="" className="small mb-1">
                    Telefono:
                  </label>
                  <Controller
                    control={control}
                    name="telefono"
                    render={({ field }) => (
                      <PhoneInput
                        country={'sv'}
                        enableSearch={true}
                        inputStyle={{
                          width: '100%'
                        }}
                        defaultCountry={'sv'}
                        {...field}
                      />
                    )}
                  />
                  {errors.telefono && (
                    <Form.Text className="text-danger">{errors.telefono.message}</Form.Text>
                  )}
                </div>

                <div className="col-md-12 mt-4">
                  <label htmlFor="" className="small mb-1">
                    E-mail:
                  </label>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Form.Control // prettier-ignore
                        {...field}
                        type="email"
                      />
                    )}
                  />
                  {errors.email && (
                    <Form.Text className="text-danger">{errors.email.message}</Form.Text>
                  )}
                </div>
              </div>
            </div>
            <BotonesFooter ruta={'/empresas/sucursales'} saveAction={handleSubmit(handleSave)} />
          </div>
        </div>
      </LayoutForm>
    </Fragment>
  )
}
