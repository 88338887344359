/** @format */

import React, { Fragment } from 'react'
import { Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAntecedentesTab,
  setConsultaTab,
  setIncapacidadTab,
  setPacienteTab
} from '../../../store/slices/uiSlice'
import { FaRegUser } from 'react-icons/fa'

import { VscHistory } from 'react-icons/vsc'
import { PiSyringeLight } from 'react-icons/pi'
import { MdTravelExplore } from 'react-icons/md'
import { expienteTabs } from '../../../constants'
import { useSearchParams } from 'react-router-dom'

export default function HeaderExpediente() {
  const { informacionBasicaPaciente, ui } = useSelector((state) => state)
  const { tabsExpediente } = ui
  const [, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  const { isDisableTabs } = informacionBasicaPaciente

  const handleChangeTab = (tab, tabName) => {
    if (!isDisableTabs) {
      dispatch(tab)
      setSearchParams({ activeTab: tabName })
    }
  }
  return (
    <Fragment>
      <Nav variant="pills">
        <Nav.Item onClick={() => handleChangeTab(setPacienteTab(), expienteTabs.PACIENTE)}>
          <Nav.Link active={tabsExpediente.paciente}>
            <FaRegUser /> Paciente
          </Nav.Link>
        </Nav.Item>

        <Nav.Item onClick={() => handleChangeTab(setAntecedentesTab(), expienteTabs.ANTECEDENTES)}>
          <Nav.Link disabled={isDisableTabs} active={tabsExpediente.antecedentes}>
            <VscHistory /> Antecedentes
          </Nav.Link>
        </Nav.Item>

        <Nav.Item onClick={() => handleChangeTab(setIncapacidadTab(), expienteTabs.INCAPACIDAD)}>
          <Nav.Link disabled={isDisableTabs} active={tabsExpediente.incapacidad}>
            <PiSyringeLight /> Incapacidad
          </Nav.Link>
        </Nav.Item>

        <Nav.Item onClick={() => handleChangeTab(setConsultaTab(), expienteTabs.CONSULTA)}>
          <Nav.Link disabled={isDisableTabs} active={tabsExpediente.consulta}>
            <MdTravelExplore />
            Consulta
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Fragment>
  )
}
