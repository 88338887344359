import React, { useMemo, useState } from 'react'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { Alert, Card, CardBody } from 'react-bootstrap'
import { Header } from '../../../components/Containers/Header'
import { Lista, Option, ModalPathologicalFeature } from './components'
import { activarModalGenerico, clearActivepathologicalFeature } from '../../../store/slices/uiSlice'
import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../constants'
import { toastAdapter } from '../../../plugins'
import {
  useAddPathologicalDiseaseFeatureMutation,
  useUpdatePathologicalDiseaseFeatureMutation
} from '../../../services/rtk-query'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { genericFormSchema } from '../../../utils'

export const EnfermedadesPatologicasPage = () => {
  const dispatch = useDispatch()
  const [action, setAction] = useState(ACTIONS.SAVE)
  const [addPathologicalDiasease] = useAddPathologicalDiseaseFeatureMutation()
  const [updatePathologicalDisease] = useUpdatePathologicalDiseaseFeatureMutation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(genericFormSchema),
    defaultValues: {
      nombre: '',
      id: 0,
      categoriaId: 0
    }
  })
  const columns = useMemo(
    () => [
      {
        header: 'id',
        accessorKey: 'id'
      },
      {
        header: 'nombre',
        accessorKey: 'nombre'
      },
      {
        header: 'Activo',
        accessorKey: 'activo',
        cell: ({ row: { original } }) =>
          original.activo ? (
            <Alert
              variant="primary"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              Si
            </Alert>
          ) : (
            <Alert
              variant="danger"
              className="text-center d-flex justify-content-center align-items-center"
              style={{
                width: '5rem',
                height: '2rem'
              }}
            >
              No
            </Alert>
          )
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        cell: ({ row: { original } }) => (
          <Option cell={original.id} setAction={setAction} activo={original.activo} />
        )
      }
    ],
    []
  )
  const handler = () => {
    dispatch(activarModalGenerico())
  }

  const handlerAction = (data) => {
    if (action === ACTIONS.SAVE) return handleSave(data)

    return handleUpdate(data)
  }

  const handleSave = (data) => {
    const { id, categoriaId, ...rest } = data
    toastAdapter.promise({
      promise: addPathologicalDiasease({
        ...rest,
        idEnfermedadPatologica: +categoriaId?.value
      }).unwrap(),
      successMessage: () => {
        reset()

        return 'Patologia guardado con éxito'
      },
      errorMessage: (err) => {
        console.log(err)
        return 'Error al guardar la Patologia '
      },
      loadingMessage: 'Guardando Patologia ...'
    })
  }

  const handleUpdate = (data) => {
    const { id, categoriaId, ...rest } = data
    toastAdapter.promise({
      promise: updatePathologicalDisease({
        ...rest,
        id: +getValues('id'),
        idEnfermedadPatologica: +data.categoriaId?.value
      }).unwrap(),
      successMessage: () => {
        handleReset()
        return 'Patologia  guardado con éxito'
      },
      errorMessage: 'Error al guardar la Patologia ',
      loadingMessage: 'Guardando Patologia ...'
    })
  }
  const handleReset = () => {
    reset()
    setAction(ACTIONS.SAVE)
    dispatch(clearActivepathologicalFeature())
  }

  return (
    <LayoutForm title="Enfermedades Patologicas">
      <Card className="shadow-none">
        <Header
          title="Enfermedades Patologicas"
          subtitle="Listado de enfermedades patologicas."
          buttonTitle="Nueva Patologia "
          handler={handler}
        />
        <CardBody>
          <Lista columns={columns} />
        </CardBody>
      </Card>
      <ModalPathologicalFeature
        action={action}
        control={control}
        errors={errors}
        handlerAction={handlerAction}
        handleSubmit={handleSubmit}
        reset={handleReset}
        setValue={setValue}
      />
    </LayoutForm>
  )
}
