import React from 'react'
import { HereditaryCell } from './HereditaryCell'
import { HereditaryDescription } from './HereditaryDescription'

export const HereditaryRow = ({ enfermedadHereditaria, onChangeCheckboxes, onChangeInput }) => (
  <tr>
    <td>{enfermedadHereditaria.nombre}</td>
    {enfermedadHereditaria.familiares.map((relationShip) => (
      <HereditaryCell
        key={relationShip.id}
        relationShip={relationShip}
        onChangeCheckbox={(e) => onChangeCheckboxes(e, enfermedadHereditaria, relationShip)}
      />
    ))}
    <HereditaryDescription
      enfermedadId={enfermedadHereditaria.id}
      description={enfermedadHereditaria.descripcion}
      onChangeInput={onChangeInput}
    />
  </tr>
)
