import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Image } from 'react-bootstrap'
import { toastAdapter } from '../../plugins'
const FiveMB = 5 * 1024 * 1024
export const ProfilePictureUploader = ({ imageUrl, handler }) => {
  const [profileImage, setProfileImage] = useState(
    imageUrl ?? process.env.PUBLIC_URL + '/assets/img/illustrations/profiles/profile-1.png'
  )
  useEffect(() => {
    if (imageUrl) setProfileImage(imageUrl)
  }, [imageUrl])

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size <= FiveMB && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfileImage(reader.result)
      }
      reader.readAsDataURL(file)
      handler(file)
    } else {
      toastAdapter.info({
        message: 'El archivo debe ser JPG o PNG y no mayor a 5 MB'
      })
    }
  }

  return (
    <Card className="shadow-none mb-4 mb-xl-0">
      <h4 className="card-subtitle p-3">Foto de perfil</h4>
      <hr />
      <Card.Body className="d-flex justify-content-center flex-wrap">
        <div>
          <figure className="mb-4">
            <Image
              className="img-account-profile rounded-circle mb-2"
              src={profileImage}
              alt="profile"
              roundedCircle
            />
            <figcaption className="small font-italic text-muted">
              JPG o PNG no mayor a 5 MB
            </figcaption>
          </figure>
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="upload-button"
          />
          <Button
            variant="primary"
            onClick={() => document.getElementById('upload-button').click()}
          >
            Subir nueva imagen
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
ProfilePictureUploader.propTypes = {
  imageUrl: PropTypes.string,
  handler: PropTypes.func.isRequired
}
