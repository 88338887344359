import React, { Fragment, useEffect } from 'react'
import LayoutForm from '../../containers/layouts/LayoutForm'
import { CalendarScreen } from './components/CalendarScreen'
import { SelectDoctor } from './components/SelectDoctor'
import { useDatesQuery } from '../../services/rtk-query/clinicalApi'
import { Loader } from '../../components/ui/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'react-bootstrap'
import { eventSetActive } from '../../store/slices/uiSlice'
import { PermissionsProvider } from '../../context'
import { encrypt } from '../../services/Encriptaciones'
import { ALL_PERMISSIONS } from '../../constants'

export default function AgendaCitas() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  const { doctorFilter } = useSelector((state) => state.ui)
  const dispatch = useDispatch()
  const { data, isLoading, isError } = useDatesQuery({ medicoId: doctorFilter })
  useEffect(() => {
    dispatch(eventSetActive(null))
  }, [])

  return (
    <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
      <LayoutForm
        title={
          <>
            <h2>Administracion de Citas</h2>
          </>
        }
      >
        <>
          <SelectDoctor />
          {isLoading && <Loader />}
          {isError && <p>Error al cargar los datos</p>}
          <Badge bg="secondary" className="m-2">
            Doble click para ver detalles de cita
          </Badge>
          <CalendarScreen events={data} isLoadingEvents={isLoading} />
        </>
      </LayoutForm>
    </PermissionsProvider>
  )
}
