import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { CiSquarePlus } from 'react-icons/ci'

export const HeaderButton = ({
  buttonTitle,
  handler,
  isDropdown = false,
  dropdownItems = [],
  ...rest
}) => {
  if (isDropdown) {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="outline-primary">
          <CiSquarePlus size={24} />
          <span className="ml-auto px-1">{buttonTitle}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dropdownItems.length &&
            dropdownItems.map((item, index) => (
              <Dropdown.Item key={index} onClick={item.handler}>
                {item.title}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Button onClick={handler} disabled={rest.hasPermission}>
      <CiSquarePlus size={24} />
      <span className="ml-auto px-1">{buttonTitle}</span>
    </Button>
  )
}
