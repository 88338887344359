import React from 'react'
import { DownloadButton } from './DownloadButton'

export const ReportsDefault = () => {
  return (
    <>
      <h3 className="mb-3">Documentos por defecto del sistema:</h3>
      <article className="d-flex flex-wrap gap-3">
        <DownloadButton fileName={'incapacidad.odt'} filePath={'/assets/docs/incapacidad.doc'} />
        <DownloadButton fileName={'expediente.odt'} filePath={'/assets/docs/expediente.odt'} />
        <DownloadButton fileName={'prescripcion.odt'} filePath={'/assets/docs/preescripcion.odt'} />
      </article>
    </>
  )
}
