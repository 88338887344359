import React from 'react'
import { TablePlugin } from '../../../../plugins/components/TablePlugin'
import { useGetAllergicsQuery } from '../../../../services/rtk-query/clinicalApi'

export const Lista = ({ columns }) => {
  const { data, isFetching } = useGetAllergicsQuery()
  return (
    <>
      <TablePlugin data={data ?? []} columns={columns} isFetching={isFetching} />
    </>
  )
}
