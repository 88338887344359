import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { encrypt } from '../../../../../services/Encriptaciones'
import { AiOutlineSecurityScan } from 'react-icons/ai'
import { LiaCubesSolid } from 'react-icons/lia'
import { LuUsers } from 'react-icons/lu'
import { PiUsersThree } from 'react-icons/pi'
import { useResetStore } from '../../../../../hooks/useResetStore'
import { UUIDS } from '../../../../../constants'
export function Seguridad() {
  const resetStore = useResetStore()
  const permisos = encrypt.getSession('modulos')

  return (
    <>
      {permisos.includes(UUIDS.seguridad) && (
        <>
          <NavLink
            className={({ isActive }) => (isActive ? '' : 'nav-link collapsed')}
            to={"'javascript:void(0);'"}
            data-bs-toggle="collapse"
            data-bs-target="#collapseLayouts"
            aria-expanded="false"
            aria-controls="collapseLayouts"
          >
            <div className={'nav-link-icon'}>
              <AiOutlineSecurityScan size={28} />
            </div>
            Seguridad
            <div className="sidenav-collapse-arrow">
              <i className="fas fa-angle-down" />
            </div>
          </NavLink>
          <div className="collapse" id="collapseLayouts" data-bs-parent="#accordionSidenav">
            <nav className="sidenav-menu-nested nav">
              {permisos.includes(UUIDS.modulos) && (
                <NavLink className={'nav-link'} to={'/modulos'} onClick={resetStore}>
                  <div className={'nav-link-icon'}>
                    <LiaCubesSolid size={24} />
                  </div>
                  Módulos
                </NavLink>
              )}
              {permisos.includes(UUIDS.usuarios) && (
                <NavLink className={'nav-link'} to={'/usuarios'} onClick={resetStore}>
                  <div className={'nav-link-icon'}>
                    <LuUsers size={24} />
                  </div>
                  Usuarios
                </NavLink>
              )}
              {permisos.includes(UUIDS.roles) && (
                <NavLink className={'nav-link'} to={'/roles'} onClick={resetStore}>
                  <div className={'nav-link-icon'}>
                    <PiUsersThree size={24} />
                  </div>
                  Roles
                </NavLink>
              )}
            </nav>
          </div>
        </>
      )}
    </>
  )
}
