/** @format */

import React, { useState } from 'react'
import ExportarYAgregar from '../../../components/botones/ExportarYAgregar'
import LayoutForm from '../../../containers/layouts/LayoutForm'
import { useGetClinicalRecordsQuery } from '../../../services/rtk-query/clinicalApi'
import { OptionsColumns } from './components/OptionsColumns'
import { ModalPreviewReport } from '../../../components/modals/ModalPreviewReport'
import { TablePlugin } from '../../../plugins/components/TablePlugin'
import { PermissionsProvider } from '../../../context'
import { encrypt } from '../../../services/Encriptaciones'
import { ALL_PERMISSIONS, modules } from '../../../constants'
import WithPermissions from '../../../HOCs/WithPermissions'

const activado = ({ row: { original } }) => {
  return (
    <div>
      {!original.activo ? (
        <span className="badge bg-green-soft text-green">Activado</span>
      ) : (
        <span className="badge bg-red-soft text-red">Desactivado</span>
      )}
    </div>
  )
}
const columns = [
  { header: 'Id', accessorKey: 'id' },
  { header: 'Paciente', accessorKey: 'nombre' },
  { header: 'Expediente Valido', accessorKey: 'activo', cell: activado },
  {
    header: 'Opciones',
    accessorKey: 'id',
    cell: ({ row: { original } }) => (
      <OptionsColumns id={original.id} medicoId={original.medicoId} />
    )
  }
]
export default function TablaExpedienteClinico() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  const [filtering, setFiltering] = useState('')
  const { data, isFetching } = useGetClinicalRecordsQuery(filtering)

  const onFiltering = (data) => setFiltering(data)

  return (
    <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
      <LayoutForm title="Expedientes Clinicos">
        <div className="card shadow-none mb-4">
          <div className="card-body">
            <div className="card-title display-6">Listado Expedientes clinicos</div>
            <hr />
            <WithPermissions
              requiredPermissions={ALL_PERMISSIONS}
              module={modules.expediente_medicos}
            >
              <ExportarYAgregar
                ruta={'/expedientes-medicos/pacientes/nuevo'}
                nombre={'Nuevo Expediente'}
              />
            </WithPermissions>
            <TablePlugin
              data={data}
              isFetching={isFetching}
              columns={columns}
              onFilteringChange={onFiltering}
              isInternalFiltering={false}
              filtering={filtering}
            />
          </div>
        </div>
      </LayoutForm>
      <ModalPreviewReport />
    </PermissionsProvider>
  )
}
