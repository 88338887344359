import React from 'react'
import { TablePlugin } from '../../../../plugins/components/TablePlugin'
import { useHereditariesQuery } from '../../../../services/rtk-query'

export const EnfermedadesHereditariasLista = ({ columns }) => {
  const { data, isFetching } = useHereditariesQuery()

  return (
    <>
      <TablePlugin data={data ?? []} columns={columns} isFetching={isFetching} />
    </>
  )
}
