/** @format */
import 'react-phone-number-input/style.css'
import './index.css'
import './themes/theme-light.css'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Authorizate from './services/Authorizate'

import LayoutTheme from './containers/layouts/LayoutTheme'
import PrivateRouter from './libs/Router/PrivateRouter'
import Login from './pages/acceso/Login'
import Home from './pages/home/Home'

import RecoveryPassword from './pages/acceso/RecoveryPassword'
import AddUsers from './pages/usuarios/AddUsers'
import Roles from './pages/roles/RolesList'
import AddRoles from './pages/roles/AddRoles.jsx'
import Usuarios from './pages/usuarios/UsuariosList'
import Perfil from './pages/acceso/Perfil'
import AddModulos from './pages/modulos/AddModulos'
import ModulosList from './pages/modulos/ModulosList'
import SucursalesList from './pages/Administracion/sucursales/Sucursales'
import FormSucursales from './pages/Administracion/sucursales/FormSucursales.jsx'
import Empresas from './pages/Administracion/empresas/Empresas'
import FormEmpresas from './pages/Administracion/empresas/FormEmpresas'
import { Sesiones } from './pages/usuarios/Sesiones'
import { SesionesBloqueadas } from './pages/usuarios/SesionesBloqueadas'
import { Personas } from './pages/Administracion/personas/Personas'
import { PersonasForm } from './pages/Administracion/personas/PersonasForm'
// import TipoSangre from "./pages/registroMedicos/tipoSangre/TipoSangre"
import Paciente from './pages/registroMedicos/expedienteClinico/Paciente'
import TablaExpedienteClinico from './pages/registroMedicos/expedienteClinico/TablaExpedienteClinico'
import Alergias from './pages/persona/InformacionGeneral'
import ConsultaPanel from './pages/consultas/headerConsultas/ConsultaPanel'
import ErrorNotFount from './pages/error/404'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { MedicosPage } from './pages/medicos/MedicosPage.jsx'
import AgendaCitas from './pages/consultas/AgendaCitas.jsx'
import FavIcon from 'react-favicon'
import { ALL_PERMISSIONS, UUIDS } from './constants/index.js'
import { Especialidades } from './pages/medicos/Especialidades.jsx'
import { SubEspecialidades } from './pages/medicos/SubEspecialidades.jsx'
import { EnfermedadesHereditarias } from './pages/generales/enfermedadesHereditarias/index.js'
import { Suspense } from 'react'
import { Loader } from './components/ui/Loader.jsx'
import { AlergiasPage } from './pages/generales/alergias/AlergiasPage.jsx'
import { ComplicacionesPage } from './pages/generales/complicaciones/ComplicacionesPage.jsx'
import { AlteraicionMenstrualesPage } from './pages/generales/menstruacion/AlteracionMenstrualPage.jsx'
import { EnfermedadesEtsPage } from './pages/generales/enfermedades-de-transmicion-sexual/EnfermedadesTransmicionSexual.jsx'
import { SintomasAlergiasPage } from './pages/generales/sintomas-alergias/SintomasAlergiasPages.jsx'
import { PatologiasPage } from './pages/generales/patologias/PatologiasPage.jsx'
import { EnfermedadesPatologicasPage } from './pages/generales/enfermedades-patologicas/EnfermedadesPatologicas.jsx'
import { ReportsPage } from './pages/generales/reportes/ReportsPage.jsx'
import { AnticoncepcionsPage } from './pages/generales/anticonceptiones/AnticoncepcionPage.jsx'
import { RoleManagement } from './pages/roles/RoleManagement.jsx'
import { RoleManagementChange } from './pages/roles/RoleManageMentChange.jsx'
import { PermissionsProvider } from './context/PermissionsProvider.jsx'
import { encrypt } from './services/Encriptaciones.js'

const favIcon = process.env.REACT_APP_FAVICON ?? 'https://jcimcr.org/public/img/case-reports.png'
function App() {
  const user = JSON.parse(encrypt.getSession('dataUser'))
  document.title = process.env.REACT_APP_TITLE ?? 'CLINICA DEFAULT'

  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <FavIcon url={favIcon} />
        <Router>
          <LayoutTheme>
            <Routes>
              <Route exact path="/" element={<PrivateRouter />}>
                <Route exact path="/" element={<Home />} />
              </Route>

              {/* rutas relacionadas a usuarios*/}
              <Route exact path="/" element={<PrivateRouter />}>
                <Route
                  exact
                  path="/usuarios"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <Usuarios />
                      </PermissionsProvider>
                    ),
                    UUIDS.usuarios
                  )}
                />
                <Route exact path="/usuarios/:id" element={Authorizate(AddUsers, UUIDS.usuarios)} />
                <Route
                  exact
                  path="/usuarios/nuevo"
                  element={Authorizate(AddUsers, UUIDS.usuarios)}
                />
                <Route exact path="/sesiones" element={<Sesiones />} />
                <Route exact path="/usuarios/bloqueados" element={<SesionesBloqueadas />} />
                <Route
                  exact
                  path="/persona/"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <Personas />
                      </PermissionsProvider>
                    ),
                    UUIDS.personas
                  )}
                />
                <Route
                  exact
                  path="/persona/nuevo/"
                  element={Authorizate(PersonasForm, UUIDS.personas)}
                />
                <Route
                  exact
                  path="/persona/update/:id"
                  element={Authorizate(PersonasForm, UUIDS.personas)}
                />
                <Route exact path="/usuarios/update/:id" element={<AddUsers />} />
                <Route exact path="/configuraciones/perfil" element={<Perfil />} />
              </Route>

              {/* rutas para modulos y permisos */}
              <Route exact path="/" element={<PrivateRouter />}>
                {/* administracion de roles */}
                <Route exact path="/roles" element={Authorizate(Roles, UUIDS.roles)} />
                <Route exact path="/roles/nuevo" element={Authorizate(AddRoles, UUIDS.roles)} />
                <Route
                  exact
                  path="/roles/asignar-permisos/:id"
                  element={Authorizate(RoleManagement, UUIDS.roles)}
                />
                <Route
                  exact
                  path="/roles/asignar-permisos/change/:id"
                  element={Authorizate(RoleManagementChange, UUIDS.roles)}
                />
                <Route
                  exact
                  path="/roles/asignar-modulos/:id"
                  element={Authorizate(AddRoles, UUIDS.roles)}
                />
                {/* administracion de modulo */}
                <Route
                  exact
                  path="/modulos"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <ModulosList />
                      </PermissionsProvider>
                    ),
                    UUIDS.modulos
                  )}
                />
                <Route
                  exact
                  path="/modulos/nuevo"
                  element={Authorizate(AddModulos, UUIDS.modulos)}
                />
                <Route
                  exact
                  path="/modulos/update/:id"
                  element={Authorizate(AddModulos, UUIDS.modulos)}
                />
              </Route>

              {/* Rutas para administracion de empresas */}
              <Route exact path="/" element={<PrivateRouter />}>
                {/* Sucursales formulario */}
                <Route exact path="/empresas/sucursales/nuevo" element={<FormSucursales />} />
                {/* Sucursales tabla */}
                <Route
                  exact
                  path="/empresas/sucursales"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <SucursalesList />
                      </PermissionsProvider>
                    ),
                    UUIDS.sucursales
                  )}
                />
                {/* Sucursales formulario */}
                <Route
                  exact
                  path="/empresas/sucursales/update/:id"
                  element={Authorizate(FormSucursales, UUIDS.sucursales)}
                />
                {/* EMPRESAS */}
                <Route
                  exact
                  path="/administracion/empresas"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <Empresas />
                      </PermissionsProvider>
                    ),
                    UUIDS.empresas
                  )}
                />
                <Route
                  exact
                  path="/administracion/empresas/nuevo"
                  element={Authorizate(FormEmpresas, UUIDS.empresas)}
                />
                <Route
                  exact
                  path="/administracion/empresas/:id"
                  element={Authorizate(FormEmpresas, UUIDS.empresas)}
                />
              </Route>

              {/*  rutas relacionadas al login*/}
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/recovery" element={<RecoveryPassword />} />
              <Route exact path="/not-found" element={<ErrorNotFount />} />
              <Route exact path="*" element={<Navigate to="/not-found" />} />
              <Route exact path="/" element={<PrivateRouter />}></Route>

              <Route exact path="/" element={<PrivateRouter />}>
                {/* TIPO DE SANGRE */}

                <Route
                  exact
                  path="/expedientes-medicos"
                  element={Authorizate(TablaExpedienteClinico, UUIDS.listaExpediente)}
                />
                <Route
                  exact
                  path="/expedientes-medicos/:id"
                  element={Authorizate(Paciente, UUIDS.listaExpediente)}
                />
                <Route
                  exact
                  path="/expedientes-medicos/pacientes/nuevo"
                  element={Authorizate(Paciente, UUIDS.listaExpediente)}
                />
                <Route
                  exact
                  path="/expedientes-medicos/:id/consultas/nueva"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider permissions={ALL_PERMISSIONS} user={user}>
                        <ConsultaPanel />
                      </PermissionsProvider>
                    ),
                    UUIDS.listaExpediente
                  )}
                />
                <Route
                  exact
                  path="/expedientes-medicos/:id/consultas/:consultaId"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider permissions={ALL_PERMISSIONS} user={user}>
                        <ConsultaPanel />
                      </PermissionsProvider>
                    ),
                    UUIDS.listaExpediente
                  )}
                />
                <Route
                  exact
                  path="/expedientes-medicos/agenda-citas"
                  element={Authorizate(AgendaCitas, UUIDS.agendaCitas)}
                />

                <Route
                  exact
                  path="/medicos"
                  element={Authorizate(MedicosPage, UUIDS.listaMedicos)}
                />
                <Route
                  exact
                  path="/medicos/especialidades"
                  element={Authorizate(Especialidades, UUIDS.especialidades)}
                />
                <Route
                  exact
                  path="/medicos/sub-especialidades"
                  element={Authorizate(SubEspecialidades, UUIDS.subEspecialidads)}
                />

                <Route
                  exact
                  path="/medicos/reportes"
                  element={Authorizate(ReportsPage, UUIDS.reportes)}
                />

                <Route
                  exact
                  path="/registros/enfermedades-hereditarias"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <EnfermedadesHereditarias />
                      </PermissionsProvider>
                    ),
                    UUIDS.hereditarios
                  )}
                />

                <Route
                  exact
                  path="/registros/alergias"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <AlergiasPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.alergias
                  )}
                />

                <Route
                  exact
                  path="/registros/complicaciones"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <ComplicacionesPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.complicaciones
                  )}
                />

                <Route
                  exact
                  path="/registros/alteraciones-menstruales"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <AlteraicionMenstrualesPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.menstruacion
                  )}
                />

                <Route
                  exact
                  path="/registros/enfermedades-de-transmision-sexual"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <EnfermedadesEtsPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.enfermedadesTransmisionSexual
                  )}
                />

                <Route
                  exact
                  path="/registros/anticonceptivos"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <AnticoncepcionsPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.anticonceptivos
                  )}
                />

                <Route
                  exact
                  path="/registros/sintomas-de-alergias"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <SintomasAlergiasPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.sintomasAlergias
                  )}
                />
                <Route
                  exact
                  path="/registros/patologias"
                  element={Authorizate(
                    () => (
                      <PermissionsProvider user={user} permissions={ALL_PERMISSIONS}>
                        <PatologiasPage />
                      </PermissionsProvider>
                    ),
                    UUIDS.patologias
                  )}
                />
                <Route
                  exact
                  path="/registros/enfermedades-patologicas"
                  element={Authorizate(EnfermedadesPatologicasPage, UUIDS.enfermedadesPatologicas)}
                />

                <Route exact path="/alergias" element={<Alergias />} />
              </Route>
            </Routes>
          </LayoutTheme>
        </Router>
      </Provider>
    </Suspense>
  )
}

export default App
