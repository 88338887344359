import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ModalGenerico } from '../../../../components/ui'
import { ACTIONS } from '../../../../constants'
import { useSelector } from 'react-redux'

export const ModalPathological = ({
  handleSubmit,
  handlerAction,
  action,
  reset,
  control,
  errors,
  setValue
}) => {
  const { activePathological } = useSelector((state) => state.ui)

  useEffect(() => {
    if (activePathological) {
      setValue('nombre', activePathological.nombre)
      setValue('id', activePathological.id)
    }
  }, [activePathological])

  return (
    <ModalGenerico
      title="Patologias"
      buttonCustom={() => (
        <Button
          variant="primary "
          size="md"
          style={{
            minWidth: '15%'
          }}
          onClick={handleSubmit(handlerAction)}
        >
          {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
        </Button>
      )}
      closeButtonAction={reset}
    >
      <Form>
        <Form.Group className="mb-2">
          <Form.Label>Nombre de la patologia</Form.Label>
          <Controller
            control={control}
            name="nombre"
            render={({ field }) => (
              <Form.Control type="text" placeholder="Nombre de la patologia..." {...field} />
            )}
          />
          {errors.nombre && (
            <Form.Text className="text-danger">{errors?.nombre?.message}</Form.Text>
          )}
        </Form.Group>
      </Form>
    </ModalGenerico>
  )
}
