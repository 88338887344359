import React from 'react'
import { FormCheck, Table } from 'react-bootstrap'
import { Controller } from 'react-hook-form'

export const TablePermissions = ({ modules = [], control }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Modulo</th>
          <th>Ver</th>
          <th>Leer</th>
          <th>Escribir</th>
        </tr>
      </thead>
      <tbody>
        {modules.length &&
          modules.map((module, index) => (
            <tr key={module.moduleId}>
              <td>{module.moduleId}</td>
              <td>{module.nombre}</td>
              <td>
                <Controller
                  name={`rows[${index}].ver`}
                  control={control}
                  render={({ field }) => (
                    <FormCheck type="switch" {...field} checked={field.value} />
                  )}
                />
              </td>
              <td>
                <Controller
                  name={`rows[${index}].leer`}
                  control={control}
                  render={({ field }) => (
                    <FormCheck type="switch" {...field} checked={field.value} />
                  )}
                />
              </td>
              <td>
                <Controller
                  name={`rows[${index}].escribir`}
                  control={control}
                  render={({ field }) => (
                    <FormCheck type="switch" {...field} checked={field.value} />
                  )}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}
