import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ModalGenerico } from '../../../../components/ui'
import { ACTIONS } from '../../../../constants'
import { useSelector } from 'react-redux'

export const ModalAlergias = ({
  handleSubmit,
  handlerAction,
  action,
  reset,
  control,
  errors,
  setValue
}) => {
  const { activeAllergic } = useSelector((state) => state.ui)

  useEffect(() => {
    if (activeAllergic) {
      setValue('nombre', activeAllergic.nombre)
      setValue('descripcion', activeAllergic.descripcion)
      setValue('id', activeAllergic.id)
    }
  }, [activeAllergic])

  return (
    <ModalGenerico
      title="Alérgias"
      buttonCustom={() => (
        <Button
          variant="primary "
          size="md"
          style={{
            minWidth: '15%'
          }}
          onClick={handleSubmit(handlerAction)}
        >
          {action === ACTIONS.SAVE ? 'Guardar' : 'Actualizar'}
        </Button>
      )}
      closeButtonAction={reset}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Form.Group className="mb-2">
          <Form.Label>Nombre de la Alergia</Form.Label>
          <Controller
            control={control}
            name="nombre"
            render={({ field }) => (
              <Form.Control type="text" placeholder="Nombre de la Alergia..." {...field} />
            )}
          />
          {errors.nombre && (
            <Form.Text className="text-danger">{errors?.nombre?.message}</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Descripcion de la Alergia</Form.Label>
          <Controller
            control={control}
            name="descripcion"
            render={({ field }) => (
              <Form.Control as={'textarea'} placeholder="Alergia..." {...field} />
            )}
          />
          {errors.descripcion && (
            <Form.Text className="text-danger">{errors?.descripcion?.message}</Form.Text>
          )}
        </Form.Group>
      </Form>
    </ModalGenerico>
  )
}
