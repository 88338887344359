import React, { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { encrypt } from '../../../../../services/Encriptaciones'
import { FaRegUser } from 'react-icons/fa'
import { VscFiles } from 'react-icons/vsc'
import { useResetStore } from '../../../../../hooks/useResetStore'
import { UUIDS } from '../../../../../constants'
import { AiOutlineMedicineBox } from 'react-icons/ai'

export default function MenuMedicos() {
  const permisos = encrypt.getSession('modulos')

  const resetStore = useResetStore()

  return (
    <Fragment>
      {permisos.includes(UUIDS.medicos) && (
        <>
          <NavLink
            className={'nav-link collapsed'}
            to={'"javascript:void(0);"'}
            data-bs-toggle="collapse"
            data-bs-target="#collapseMedicos"
            aria-expanded="false"
            aria-controls="collapseMedicos"
          >
            <div className="nav-link-icon">
              <FaRegUser size={28} />
            </div>
            Medicos
            <div className="sidenav-collapse-arrow">
              <i className="fas fa-angle-down" />
            </div>
          </NavLink>
          <div className={'collapse'} id="collapseMedicos" data-bs-parent="#accordionSidenav">
            <nav className="sidenav-menu-nested nav">
              {permisos.includes(UUIDS.medicos) && (
                <>
                  {permisos.includes(UUIDS.listaMedicos) && (
                    <Link className="nav-link" to="/medicos" onClick={resetStore}>
                      <VscFiles size={24} className="mx-1" />
                      Lista
                    </Link>
                  )}

                  {permisos.includes(UUIDS.especialidades) && (
                    <Link className="nav-link" to="/medicos/especialidades" onClick={resetStore}>
                      <AiOutlineMedicineBox size={24} className=" mx-1" />
                      Especialidades
                    </Link>
                  )}
                  {permisos.includes(UUIDS.subEspecialidads) && (
                    <Link
                      className="nav-link"
                      to="/medicos/sub-especialidades"
                      onClick={resetStore}
                    >
                      <AiOutlineMedicineBox size={24} className=" mx-1" />
                      Sub Especialidades
                    </Link>
                  )}
                  {permisos.includes(UUIDS.reportes) && (
                    <Link className="nav-link" to="/medicos/reportes" onClick={resetStore}>
                      Reportes
                    </Link>
                  )}
                </>
              )}
            </nav>
          </div>
        </>
      )}
    </Fragment>
  )
}
