/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CgMenuLeft } from 'react-icons/cg'
import { FiEdit } from 'react-icons/fi'
import { PiTrashSimple } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import {
  setConsulta,
  setConsultaTabFalse,
  setShowToastLoader,
  setUri,
  setUser
} from '../../../../store/slices/uiSlice'
import {
  useConsultasPorExpedienteQuery,
  useDeleteConsultaMutation,
  useLazyMedicosQuery,
  useUpdateMedicoByConsultaMutation
} from '../../../../services/rtk-query'
import { toastAdapter } from '../../../../plugins/hot-toast.plugin'
import { useNavigate, useParams } from 'react-router-dom'
import { obtenerURLBlob } from '../../../../utils'
import { TfiExchangeVertical } from 'react-icons/tfi'
import { LuFileClock } from 'react-icons/lu'
import { alertConfirm, loadingAlert, selectAlert } from '../../../../plugins/sweetAlert.plugin'
import { useReports } from '../../../../hooks/useReports'
import { PermissionsContext } from '../../../../context'
import { modules, PERMISSIONS } from '../../../../constants'
import { encrypt } from '../../../../services/Encriptaciones'
export const OptionsColumnConsultas = ({ cell }) => {
  const { hasPermission } = useContext(PermissionsContext)
  const { id = null } = useParams()
  const [loadMedicos] = useLazyMedicosQuery()
  const [changeDoctorMutation] = useUpdateMedicoByConsultaMutation()
  const { data, isFetching, isSuccess } = useConsultasPorExpedienteQuery(id, {})
  const navigator = useNavigate()
  const [deleteConsulta, { isLoading }] = useDeleteConsultaMutation()
  const dispatch = useDispatch()

  const { handleFetch } = useReports()

  const onEvent = () => {
    dispatch(setConsulta(cell))
    dispatch(setConsultaTabFalse())
    // dispatch(setInterrogatorioTab());
    const data = encrypt.getSession('medico')
    dispatch(setUser(data))
    navigator({
      pathname: `/expedientes-medicos/${id}/consultas/${cell}`
    })
  }
  const onDelete = () => {
    alertConfirm({
      title: '¿Estas seguro?',
      text: 'No podras revertir esto',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result) {
        toastAdapter.promise({
          promise: deleteConsulta({ id: cell }).unwrap(),
          successMessage: 'Consulta Eliminada',
          errorMessage: 'Error al intentar eliminar la consulta',
          loadingMessage: 'Eliminando...'
        })
      }
    })
  }
  const changeDoctor = async () => {
    loadingAlert({
      flag: true,
      text: 'Cargando Medicos',
      title: 'Cargando...'
    })
    const { data, isSuccess } = await loadMedicos()
    if (!isLoading) {
      loadingAlert({
        flag: false,
        text: 'Cargando Medicos',
        title: 'Cargando...'
      })
    }
    if (!isSuccess) return
    const response = await selectAlert({
      title: 'Seleccione un medico',
      text: 'Seleccione un Medico para continuar',
      inputOptions: data
        ?.map((medico) => ({ [medico.id]: medico.nombres }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    })
    if (response.isConfirmed) {
      toastAdapter.promise({
        promise: changeDoctorMutation({
          consultaId: cell,
          medicoId: +response.value
        }).unwrap(),
        successMessage: 'Medico Actualizado',
        errorMessage: (error) => {
          console.log(error)
          return 'Error al intentar actualizar el medico'
        },
        loadingMessage: 'Actualizando...'
      })
    }
  }

  const onReports = () => {
    if (isFetching) return

    if (isSuccess) {
      const consulta = data?.find((item) => item.id === cell)
      if (!consulta) return

      toastAdapter.promise({
        promise: handleFetch({
          url: `consultas/prescripcion/${id}/medico/${consulta.medicoId}`
        }),
        successMessage: (data) => {
          const uriBlog = obtenerURLBlob(data)
          dispatch(setUri(uriBlog))
          dispatch(setShowToastLoader())
          return 'Reporte generado'
        },
        errorMessage: 'Error al generar reporte...',
        loadingMessage: 'Generando Reporte...'
      })
    }
  }

  return (
    <Dropdown className="text-center" flip drop="left">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <CgMenuLeft />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={changeDoctor}
          className={classNames('text-center ', {
            'opacity ': !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)
          })}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
        >
          <TfiExchangeVertical /> <small className="mx-1">Cambiar Medico</small>
        </Dropdown.Item>
        <Dropdown.Item className="text-center   " onClick={onReports}>
          <LuFileClock /> Generar Prescripcion
        </Dropdown.Item>
        <Dropdown.Item onClick={onEvent} className="">
          <FiEdit /> <small className="mx-1">Ver Detalles</small>
        </Dropdown.Item>
        <Dropdown.Item
          className={classNames('text-center text-danger', {
            'opacity ': !hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)
          })}
          onClick={onDelete}
          disabled={!hasPermission([PERMISSIONS.WRITE], modules.expediente_medicos)}
        >
          <PiTrashSimple /> Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
