import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import LayoutForm from '../../../containers/layouts/LayoutForm'
import axios from '../../../services/peticionesAxios'
import headers from '../../../services/AxiosCabeceras'
import CalcularEdad from '../../../controller/CalcularEdad'
import Responsable from './Responsable'
import Alertas from '../../../services/Alertas'
import { clinicalApi, useLazyGetIdentityCardQuery } from '../../../services/rtk-query/clinicalApi'
import { toastAdapter } from '../../../plugins'
import { Loader } from '../../../components/ui/Loader'
import { ProfilePictureUploader } from '../../../components/ui/ProfilePictureUploader'
import { PROVIDES_AND_INVALIDATE_TAGS } from '../../../constants'
import { useDispatch } from 'react-redux'
import { appendIfDefined } from './utils'

const Cliente = lazy(() => import('./Cliente'))
const PersonaContactos = lazy(() =>
  import('./PersonaContactos').then((module) => ({
    default: module.PersonaContactos
  }))
)
const PersonasResidencia = lazy(() =>
  import('./PersonasResidencia').then((module) => ({
    default: module.PersonasResidencia
  }))
)
const PersonasDatosPersonales = lazy(() =>
  import('./PersonasDatosPersonales').then((module) => ({
    default: module.PersonasDatosPersonales
  }))
)
const PersonasDatosNaci = lazy(() =>
  import('./PersonasDatosNaci').then((module) => ({
    default: module.PersonasDatosNaci
  }))
)

export const PersonasForm = () => {
  const [getIdentityCard] = useLazyGetIdentityCardQuery()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      cantonNacimiento: undefined,
      idTipoDocumentoIdentidad: { label: 'DUI', value: 1 },
      gruposCliente: undefined
    }
  })

  const [persona, setPersona] = useState({
    nombreCompleto: '',
    primerNombre: '',
    primerApellido: '',
    fechaNacimiento: '',
    telefonoPrincipal: '',
    telefonoCasa: '',
    telefonoAdicional: '',
    dui: '',
    responsable: '',
    parentesco: '',
    tieneWhatsapp: false,
    celular: '',
    sexo: '',
    estadoCivil: '',
    idTipoDocumentoIdentidad: { label: 'DUI', value: 1 },
    paisResidencia: { label: 'EL SALVAODR', value: 61 },
    departamentoResidencia: { label: 'SAN MIGUEL', value: 12 },
    ciudadResidencia: { label: 'SAN MIGUEL', value: 199 },
    cantonResidencia: { label: 'NO CANTON', value: 870 },
    direccionPersona: '',
    direccion: '',
    lugarTrabajo: '',
    direccionTrabajo: '',
    profesion: '',
    telefonoTrabajo: '',
    razonSocial: '',
    gruposCliente: '',
    gruposPersona: '',
    tipoCliente: '',
    sucursal: '',
    email: '',
    nrc: '',
    nit: '',
    giro: '',
    paisNacimiento: { label: 'EL SALVAODR', value: 61 },
    departamentoNacimiento: { label: 'SAN MIGUEL', value: 12 },
    ciudadNacimiento: { label: 'SAN MIGUEL', value: 199 },
    cantonNacimiento: { label: 'NO CANTON', value: 870 },
    direccionDomicilio1: '',
    direccionDomicilio2: '',
    codigo: 69,
    imagen: null,
    idTipoDocumentoResponsable: { label: 'DUI', value: 1 }
  })
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFiles] = useState()
  const [estado, setEstado] = useState([])
  const [sexo, setSexo] = useState([])
  const [dui, setDui] = useState([])
  const [personaResponsable, setPersonaResponsable] = useState([])
  const [updateFoto, setUpdateFoto] = useState(false)

  const [apiPais, setApiPais] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [municipios, setMunicipios] = useState([])
  const [canton, setCanton] = useState([])

  const [profesion, setProfesion] = useState([])
  const [grupoPersonas, setGruposPersonas] = useState([])
  const [grupoCliente, setGruposCliente] = useState([])
  const [naturalezaPersonas, setNaturalezaPersona] = useState([])
  const [sucursal, setSucursal] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorDui, setErrorDui] = useState(false)

  const handleResetData = () => {
    setPersona({
      nombreCompleto: '',
      primerNombre: '',
      primerApellido: '',
      fechaNacimiento: '',
      telefonoPrincipal: '',
      telefonoCasa: '',
      telefonoAdicional: '',
      dui: '',
      responsable: '',
      parentesco: '',
      tieneWhatsapp: false,
      celular: '',
      sexo: '',
      estadoCivil: '',
      idTipoDocumentoIdentidad: { label: 'DUI', value: 1 },
      paisResidencia: { label: 'EL SALVADOR', value: 61 },
      departamentoResidencia: { label: 'SAN MIGUEL', value: 12 },
      ciudadResidencia: { label: 'SAN MIGUEL', value: 199 },
      cantonResidencia: { label: 'NO CANTON', value: 870 },
      direccionPersona: '',
      direccion: '',
      lugarTrabajo: '',
      direccionTrabajo: '',
      profesion: '',
      telefonoTrabajo: '',
      razonSocial: '',
      gruposCliente: '',
      gruposPersona: '',
      tipoCliente: '',
      sucursal: '',
      email: '',
      nrc: '',
      nit: '',
      giro: '',
      paisNacimiento: { label: 'EL SALVADOR', value: 61 },
      departamentoNacimiento: { label: 'SAN MIGUEL', value: 12 },
      ciudadNacimiento: { label: 'SAN MIGUEL', value: 199 },
      cantonNacimiento: { label: 'NO CANTON', value: 870 },
      direccionDomicilio1: '',
      direccionDomicilio2: '',
      codigo: 69,
      imagen: null,
      idTipoDocumentoResponsable: { label: 'DUI', value: 1 }
    })

    setSelectedFiles(null)
    setEstado([])
    setSexo([])
    setDui([])
    setPersonaResponsable([])
    setUpdateFoto(false)

    setApiPais([])
    setDepartamentos([])
    setMunicipios([])
    setCanton([])

    setProfesion([])
    setGruposPersonas([])
    setGruposCliente([])
    setNaturalezaPersona([])
    setSucursal([])

    setErrorDui(false)
  }
  const toggleLoading = () => {
    setIsLoading(!isLoading)
  }
  useEffect(() => {
    const fetchAsync = async () => {
      toggleLoading()

      try {
        // Fetch all data in parallel
        await Promise.all([
          apiGrupos(),
          apiSexo(),
          apiEstadoCivil(),
          apiDocumentoIdentidad(),
          apiPersonaResponsable(),
          apiPaises(),
          apiDepartamentos(),
          apiProfesion(),
          apiMunicipios(),
          apiCanton()
        ])

        // Check if id is present and load personas
        if (id) {
          await loadPersonas(id)
        }
      } finally {
        // Set loading state to false regardless of the presence of id
        setIsLoading(false)
      }
    }

    fetchAsync()

    // Free memory when this component is unmounted
    return () => {
      handleResetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiEstadoCivil = async () => {
    try {
      const civil = await axios.find2('/generales/estado-civil', {
        headers: headers.getHeader()
      })
      const data = civil.data
      const opciones = data?.map((civil) => ({
        value: civil.id,
        label: civil.value
      }))
      setEstado(opciones)
    } catch (error) {
      return error
    }
  }

  const apiDocumentoIdentidad = async () => {
    try {
      const dui = await axios.find2('/generales/documentos-identidad', {
        header: headers.getHeader()
      })

      const result = dui.data?.map((documento) => ({
        value: documento.id,
        label: documento.value
      }))
      setDui(result)
    } catch (error) {
      return error
    }
  }

  const apiSexo = async () => {
    try {
      const sexo = await axios.find2('/generales/sexo', {
        header: headers.getHeader()
      })
      const result = sexo.data?.map((genero) => ({
        value: genero.id,
        label: genero.value
      }))
      setSexo(result)
    } catch (error) {
      return error
    }
  }

  const apiPersonaResponsable = async () => {
    try {
      const responsable = await axios.find2('/generales/parentesco', {
        header: headers.getHeader()
      })
      const result = responsable.data?.map((persona) => ({
        value: persona.id,
        label: persona.value
      }))
      setPersonaResponsable(result)
    } catch (error) {
      return error
    }
  }

  const apiPaises = async () => {
    try {
      const pais = await axios.find2('/generales/paises', {
        header: headers.getHeader()
      })
      const result = pais.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setApiPais(result)
      return persona
    } catch (error) {
      return error
    }
  }

  const apiDepartamentos = async (value) => {
    try {
      const departamento = await axios.find2(
        `/generales/departamentos?paisId=${value ? value : 61}`,
        {
          header: headers.getHeader()
        }
      )
      const result = departamento.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))

      setDepartamentos(result)
    } catch (error) {
      return error
    }
  }

  const apiMunicipios = async (value) => {
    try {
      const municipio = await axios.find2(
        `/generales/ciudades?departamentoId=${value ? value : 12}`,
        {
          header: headers.getHeader()
        }
      )
      const result = municipio.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setMunicipios(result)
    } catch (error) {
      return error
    }
  }

  const apiCanton = async (value) => {
    try {
      const canton = await axios.find2(`/generales/cantones?ciudadId=${value ? value : 199}`, {
        header: headers.getHeader()
      })
      const result = canton.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setCanton(result)
    } catch (error) {
      return error
    }
  }

  const apiProfesion = async () => {
    try {
      const profesion = await axios.find2('/generales/profesion', {
        header: headers.getHeader()
      })
      const result = profesion.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setProfesion(result)
    } catch (error) {
      return error
    }
  }

  const apiGrupos = async () => {
    try {
      const [grupoPersona, grupoCliente, { data: tipoCliente }, { data: sucursales }] =
        await Promise.all([
          axios.find2('/generales/grupo-personas', {
            header: headers.getHeader()
          }),
          axios.find2('/generales/grupo-clientes', {
            header: headers.getHeader()
          }),
          axios.find2('/generales/naturaleza-personas', {
            header: headers.getHeader()
          }),
          axios.find2('/generales/sucursales', {
            header: headers.getHeader()
          })
        ])
      const personas = grupoPersona.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setGruposPersonas(personas)

      setNaturalezaPersona(
        tipoCliente?.map((data) => ({
          value: data.id,
          label: data.value
        }))
      )

      setSucursal(
        sucursales?.map((data) => ({
          value: data.id,
          label: data.value
        }))
      )
      const clientes = grupoCliente.data?.map((data) => ({
        value: data.id,
        label: data.value
      }))
      setGruposCliente(clientes)
    } catch (error) {
      return error
    }
  }

  const loadPersonas = async (id) => {
    try {
      const persona = await axios.find2(`/personas/${id}`, {
        header: headers.getHeader()
      })

      // Obtener datos del documento de identidad
      const { data } = await getIdentityCard()
      setSelectedFiles(persona.data.imagen)
      // Encontrar los valores correspondientes en los arrays
      const grupoClientes = grupoCliente.find(
        (option) => +option.value === +persona.data.gruposCliente?.value
      )
      const grupoPersona = grupoPersonas.find(
        (option) => option.value === persona.data.gruposPersona?.value
      )
      const tipoClienteP = naturalezaPersonas.find(
        (option) => option.value === persona.data.tipoCliente?.value
      )
      const sucursalI = sucursal.find((option) => option.value === persona.data.sucursal?.value)
      // Establecer valores en el estado
      setPersona(persona.data)

      if (grupoClientes) {
        setValue('gruposCliente', grupoClientes ?? undefined)
      }
      if (grupoPersona) {
        setValue('gruposPersona', grupoPersona)
      }

      setValue('sucursal', sucursalI ?? persona.data.sucursal)
      setValue('tipoCliente', tipoClienteP ?? persona.data.tipoCliente)

      // Otros valores
      setValue('razonSocial', persona.data.razonSocial)
      setValue('giro', persona.data.giro)
      setValue('nrc', persona.data.nrc)

      setValue('nombreCompleto', persona.data.nombreCompleto)
      setValue('primerNombre', persona.data.primerNombre)
      setValue('primerApellido', persona.data.primerApellido)
      setValue('fechaNacimiento', persona.data.fechaNacimiento)
      setValue('direccion', persona.data.direccion)
      setValue('telefonoPrincipal', persona.data.telefono)
      setValue('tieneWhatsapp', persona.data.tieneWhatsapp)
      setValue('telefonoCasa', persona.data.telefonoCasa)
      setValue('telefonoTrabajo', persona.data.telefonoTrabajo)
      setValue('telefonoAdicional', persona.data.telefonoAdicional)
      setValue('email', persona.data.email)
      setValue('dui', persona.data.dui)
      setValue('direccionDomicilio1', persona.data.direccionDomicilio1)
      setValue('direccionDomicilio2', persona.data.direccionDomicilio2)
      setValue('profesion', persona.data.profesion)
      setValue('lugarTrabajo', persona.data.lugarTrabajo)
      setValue('direccionTrabajo', persona.data.direccionTrabajo)
      setValue('nit', persona.data.nit)
      setValue('responsable', persona.data.responsable)
      setValue('gruposPersona', persona.data.gruposPersona)
      setValue('gruposCliente', persona.data.gruposCliente)
      setValue('sexo', persona.data.sexo)
      setValue('parentesco', persona.data.parentesco?.parentesco)
      setValue('estadoCivil', persona.data.estadoCivil?.estadoCivil)
      setValue('cantonNacimiento', persona.data.nacionalidad?.cantonNacimiento)
      setValue('paisNacimiento', persona.data.nacionalidad?.paisNacimiento)
      setValue('ciudadNacimiento', persona.data.ciudadNacimiento?.ciudadNacimiento)
      setValue('departamentoNacimiento', persona.data.nacionalidad?.departamentoNacimiento)
      setValue(
        'tipoDocumentoIdentidad',
        persona.data.tipoDocumentoIdentidad?.tipoDocumentoIdentidad
      )
      setValue('paisResidencia', persona.data.paisResidencia?.paisResidencia)
      setValue(
        'departamentoResidencia',
        persona.data.departamentoResidencia?.departamentoResidencia
      )
      setValue('ciudadResidencia', persona.data.ciudadResidencia?.ciudadResidencia)
      setValue('cantonResidencia', persona.data.cantonResidencia?.cantonResidencia)
      setValue('telefonoResponsable', persona.data.telefonoParentesco)
      setValue('duiParentesco', persona.data.numeroDeDocumentoParentesco)
      setValue('idTipoDocumentoIdentidad', objectValue(persona?.data))
      // Establecer el tipo de documento responsable
      const tipoDocumento = data.find((item) => item.id === persona.data.tipoDocumentoParentesco)
      if (tipoDocumento) {
        setValue('idTipoDocumentoResponsable', {
          label: tipoDocumento.value,
          value: tipoDocumento.id
        })
      }
    } catch (error) {
      console.error('Error loading persona:', error)
    }
  }

  const handleChangesPersona = (e) => {
    setPersona({
      ...persona,
      [e.target.id]: e.target.value
    })
  }

  const handlePais = (value) => {
    setPersona({ ...persona, paisResidencia: value })
    apiDepartamentos(value.value)
  }

  const handleDepartamento = (value) => {
    setPersona({ ...persona, departamentoResidencia: value })
    apiMunicipios(value.value)
  }

  const handleMunicipio = (value) => {
    setPersona({ ...persona, ciudadResidencia: value })
    apiCanton(value.value)
  }

  const handleCanton = (value) => {
    setPersona({ ...persona, cantonResidencia: value })
  }

  const onChangesNacePais = (value) => {
    setPersona({ ...persona, paisNacimiento: value })
    apiDepartamentos(persona.paisNacimiento.value)
  }

  const onChangesNaceDepartamento = (value) => {
    setPersona({ ...persona, departamentoNacimiento: value })
    apiMunicipios(value.value)
  }

  const onChangesNaceMunicipio = (value) => {
    setPersona({ ...persona, ciudadNacimiento: value })
    apiCanton(value.value)
  }

  const onChangesNaceCanton = (value) => {
    setPersona({ ...persona, cantonNacimiento: value })
  }

  const onSubmit = async (personas) => {
    if (id && updateFoto) {
      const formData = new FormData()
      Alertas.loading_reload(true, 'Guardando persona')
      formData.append('imagen', selectedFile)

      axios.PATCH(`personas/update/imagen/${id}`, formData).then((result) => {
        Alertas.loading_reload(false)

        if (result !== false) {
          if (result.status === 200) {
            toastAdapter.success({
              message: 'Persona actualizada con éxito'
            })

            dispatch(
              clinicalApi.util.invalidateTags([PROVIDES_AND_INVALIDATE_TAGS.CLINICAL_RECORDS])
            )
            return navigate('/persona')
          } else {
            toastAdapter.error({
              message: 'Error al guardar registro, verifique los datos'
            })
          }
        } else {
          toastAdapter.error({
            message: 'Error al actualizar registro'
          })
        }
      })
    } else if (id) {
      const personaData = {
        codigo: 1,
        nombreCompleto: persona.primerNombre + ' ' + persona.primerApellido,
        primerNombre: persona.primerNombre,
        primerApellido: persona.primerApellido,
        fechaNacimiento: persona.fechaNacimiento,
        dui: personas?.dui,
        responsable: persona.responsable,
        tieneWhatsapp: Number(persona.tieneWhatsapp),
        telefonoPrincipal: persona.telefonoPrincipal,
        idSexo: personas?.sexo?.value ?? null,
        idEstadoCivil: persona?.estadoCivil?.value ?? null,
        idTipoDocumentoIdentidad: personas.idTipoDocumentoIdentidad?.value,
        telefonoCasa: persona.telefonoCasa,
        paisResidencia: persona?.paisResidencia?.value,
        departamentoResidencia: persona?.departamentoResidencia?.value,
        ciudadResidencia: persona?.ciudadResidencia?.value,
        cantonResidencia: persona.cantonResidencia?.value,
        paisNacimiento: persona?.paisNacimiento?.value,
        departamentoNacimiento: persona?.departamentoNacimiento?.value,
        ciudadNacimiento: persona?.ciudadNacimiento?.value,
        cantonNacimiento: persona.cantonNacimiento?.value,
        idGrupoCliente: personas?.gruposCliente?.value,
        nombreGrupoCliente: personas?.gruposCliente?.label ?? null,
        idGrupo: personas?.gruposPersona?.value,
        nombreGrupo: personas?.gruposPersona?.label ?? null,
        idTipoCliente: personas?.tipoCliente?.value,
        idSucursal: personas?.sucursal?.value,
        razonSocial: personas.razonSocial ?? null,
        giro: personas?.giro ?? null,
        nrc: personas?.nrc ?? null,
        direccion: persona.direccion,
        telefonoAdicional: persona.telefonoAdicional,
        email: persona?.email,
        profesion: persona.profesion,
        lugarTrabajo: persona.lugarTrabajo,
        direccionTrabajo: persona.direccionTrabajo,
        telefonoTrabajo: persona.telefonoTrabajo,
        direccionDomicilio1: persona.direccionDomicilio1,
        direccionDomicilio2: persona.direccionDomicilio2,
        nit: personas?.nit ?? null,
        idParentescoResponsable: persona?.parentesco?.value ?? null,
        telefonoResponsable: personas?.telefonoResponsable ?? null,
        duiParentesco: personas?.duiParentesco ?? null,
        idTipoDocumentoResponsable: personas?.idTipoDocumentoResponsable?.value ?? null
      }

      Alertas.loading_reload(true, 'Guardando persona')
      axios
        .update2(`personas/${id}`, personaData)
        .then((result) => {
          Alertas.loading_reload(false)

          if (result !== false) {
            if (result.status === 200) {
              toastAdapter.success({
                message: 'Persona actualizada con éxito'
              })
              dispatch(
                clinicalApi.util.invalidateTags([PROVIDES_AND_INVALIDATE_TAGS.CLINICAL_RECORDS])
              )

              return navigate('/persona')
            }
          } else {
            toastAdapter.error({
              message: 'Error al guardar registro, verifique los datos'
            })
          }
        })
        .catch((error) => {
          toastAdapter.error({
            message: 'Error al guardar registro, verifique los datos'
          })
        })
        .finally(() => {
          Alertas.loading_reload(false)
          dispatch(clinicalApi.util.invalidateTags([PROVIDES_AND_INVALIDATE_TAGS.CLINICAL_RECORDS]))
        })
    } else if (id !== undefined && updateFoto) {
      const data = {
        codigo: 1,
        idGrupo: personas.gruposPersona.value,
        nombreGrupo: personas?.gruposPersona?.label ?? null,
        idGrupoCliente: personas.gruposCliente.value,
        nombreGrupoCliente: personas?.gruposCliente?.label ?? null,
        primerNombre: persona.primerNombre,
        primerApellido: persona.primerApellido,
        nombreCompleto: persona.primerNombre + ' ' + persona.primerApellido,
        razonSocial: personas.razonSocial ?? null,
        giro: personas?.giro ?? null,
        nrc: personas?.nrc ?? null,
        direccion: persona.direccion,
        telefonoPrincipal: persona.telefonoPrincipal,
        tieneWhatsapp: persona.tieneWhatsapp,
        idTipoCliente: personas.tipoCliente.value,
        dui: personas?.dui,
        idSexo: personas?.sexo?.value ?? null,
        idEstadoCivil: persona?.estadoCivil?.value ?? null,
        fechaNacimiento: persona.fechaNacimiento,
        departamentoNacimiento: persona.departamentoNacimiento.value,
        ciudadNacimiento: persona?.ciudadNacimiento?.value ?? undefined,
        cantonNacimiento: persona?.cantonNacimiento?.value ?? undefined,
        paisNacimiento: persona?.paisResidencia?.value ?? undefined,
        // numeroDocumento: "12345678-9",
        idTipoDocumentoIdentidad: personas?.idTipoDocumentoIdentidad?.value ?? null,
        // otroNumeroDocumento: "string",
        direccionDomicilio1: persona.direccionDomicilio1,
        direccionDomicilio2: persona.direccionDomicilio2,
        telefonoCasa: persona.telefonoCasa,
        telefonoTrabajo: persona.telefonoTrabajo,
        telefonoAdicional: persona.telefonoAdicional,
        email: persona.email,
        profesion: persona.profesion,
        lugarTrabajo: persona.lugarTrabajo,
        direccionTrabajo: persona.direccionTrabajo,
        nit: personas?.nit ?? null,
        departamentoResidencia: persona.departamentoResidencia.value,
        ciudadResidencia: persona.ciudadResidencia.value,
        cantonResidencia: persona.cantonResidencia.value,
        paisResidencia: persona.paisResidencia.value,
        idSucursal: persona.sucursal.value,
        responsable: persona.responsable,
        idParentescoResponsable: persona?.parentesco?.value ?? null,

        idTipoDocumentoResponsable: personas?.idTipoDocumentoResponsable?.value ?? null,
        telefonoResponsable: personas?.telefonoResponsable ?? null,
        duiParentesco: personas?.duiParentesco ?? null
      }
      const formData = new FormData()
      // Alertas.loading_reload(true, "Guardando persona");
      formData.append('imagen', selectedFile)
      await axios.PATCH(`personas/update/imagen/${id}`, formData)
      Alertas.loading_reload(true, 'Guardando persona')
      axios
        .update(`personas/${id}`, data)
        .then((result) => {
          Alertas.loading_reload(false)

          if (result !== false) {
            if (result.status === 200) {
              Alertas.alertEmpty(
                'persona actualizada con éxito!',
                'Administración de Suyanet',
                'success'
              )
              dispatch(
                clinicalApi.util.invalidateTags([PROVIDES_AND_INVALIDATE_TAGS.CLINICAL_RECORDS])
              )
              return navigate('/persona')
            } else {
              toastAdapter.error({
                message: 'Error al guardar registro'
              })
            }
          } else {
            toastAdapter.error({
              message: 'Error al guardar registro'
            })
          }
        })
        .catch((error) => {
          toastAdapter.error({
            message: 'Error al guardar al guardar u obtener registro'
          })
        })
    } else {
      const data = new FormData()
      appendIfDefined({
        key: 'nombreCompleto',
        value: persona.primerNombre + ' ' + persona.primerApellido,
        formData: data
      })
      appendIfDefined({ key: 'primerNombre', value: persona.primerNombre, formData: data })
      appendIfDefined({ key: 'primerApellido', value: persona.primerApellido, formData: data })
      appendIfDefined({ key: 'fechaNacimiento', value: persona.fechaNacimiento, formData: data })
      appendIfDefined({ key: 'dui', value: personas?.dui, formData: data })
      appendIfDefined({ key: 'responsable', value: persona.responsable, formData: data })
      appendIfDefined({
        key: 'tieneWhatsapp',
        value: Number(persona.tieneWhatsapp),
        formData: data
      })
      if (updateFoto) {
        appendIfDefined({
          key: 'imagen',
          value: persona.imagen ?? persona.imagen?.name,
          formData: data
        })
      }
      // appendIfDefinekey:d({"imagenvalue:", persona.imagen, persona.imagen?.name ,formData:data)};
      appendIfDefined({
        key: 'telefonoPrincipal',
        value: persona.telefonoPrincipal,
        formData: data
      })
      appendIfDefined({ key: 'idSexo', value: personas?.sexo?.value, formData: data })
      appendIfDefined({ key: 'idEstadoCivil', value: persona?.estadoCivil?.value, formData: data })
      appendIfDefined({
        key: 'idTipoDocumentoIdentidad',
        value: personas?.idTipoDocumentoIdentidad?.value ?? null,
        formData: data
      })
      appendIfDefined({ key: 'telefonoCasa', value: persona.telefonoCasa, formData: data })
      appendIfDefined({
        key: 'paisResidencia',
        value: persona.paisResidencia.value,
        formData: data
      })
      appendIfDefined({
        key: 'departamentoResidencia',
        value: persona.departamentoResidencia.value,
        formData: data
      })
      appendIfDefined({
        key: 'ciudadResidencia',
        value: persona.ciudadResidencia.value,
        formData: data
      })
      appendIfDefined({
        key: 'cantonResidencia',
        value: persona.cantonResidencia?.value,
        formData: data
      })
      appendIfDefined({
        key: 'paisNacimiento',
        value: persona.paisNacimiento.value,
        formData: data
      })
      appendIfDefined({
        key: 'departamentoNacimiento',
        value: persona.departamentoNacimiento.value,
        formData: data
      })
      appendIfDefined({
        key: 'ciudadNacimiento',
        value: persona.ciudadNacimiento.value,
        formData: data
      })
      appendIfDefined({
        key: 'cantonNacimiento',
        value: persona.cantonNacimiento?.value,
        formData: data
      })
      if (personas?.gruposCliente?.value) {
        appendIfDefined({
          key: 'idGrupoCliente',
          value: personas.gruposCliente.value,
          formData: data
        })
      }
      appendIfDefined({
        key: 'nombreGrupoCliente',
        value: personas?.gruposCliente?.label,
        formData: data
      })

      if (personas?.gruposPersona?.value) {
        appendIfDefined({ key: 'idGrupo', value: personas.gruposPersona.value, formData: data })
      }
      appendIfDefined({ key: 'nombreGrupo', value: personas?.gruposPersona?.label, formData: data })
      if (personas?.tipoCliente?.value) {
        appendIfDefined({ key: 'idTipoCliente', value: personas.tipoCliente.value, formData: data })
      }
      appendIfDefined({ key: 'codigo', value: persona.codigo, formData: data })
      if (personas?.sucursal?.value) {
        appendIfDefined({ key: 'idSucursal', value: personas.sucursal.value, formData: data })
      }
      appendIfDefined({ key: 'razonSocial', value: personas.razonSocial, formData: data })
      appendIfDefined({ key: 'giro', value: personas?.nrc, formData: data })
      appendIfDefined({ key: 'nrc', value: personas?.nrc, formData: data })
      appendIfDefined({ key: 'direccion', value: persona.direccion, formData: data })
      appendIfDefined({
        key: 'telefonoAdicional',
        value: persona.telefonoAdicional,
        formData: data
      })
      appendIfDefined({ key: 'email', value: persona.email, formData: data })
      appendIfDefined({ key: 'profesion', value: persona.profesion, formData: data })
      appendIfDefined({ key: 'lugarTrabajo', value: persona.lugarTrabajo, formData: data })
      appendIfDefined({ key: 'direccionTrabajo', value: persona.direccionTrabajo, formData: data })
      appendIfDefined({ key: 'telefonoTrabajo', value: persona.telefonoTrabajo, formData: data })
      appendIfDefined({
        key: 'direccionDomicilio1',
        value: persona.direccionDomicilio1,
        formData: data
      })
      appendIfDefined({
        key: 'direccionDomicilio2',
        value: persona.direccionDomicilio2,
        formData: data
      })
      appendIfDefined({ key: 'nit', value: personas?.nit, formData: data })
      if (persona?.parentesco?.value) {
        appendIfDefined({
          key: 'idParentescoResponsable',
          value: persona?.parentesco?.value,
          formData: data
        })
      }
      if (personas?.idTipoDocumentoResponsable?.value) {
        appendIfDefined({
          key: 'idTipoDocumentoResponsable',
          value: personas?.idTipoDocumentoResponsable?.value,
          formData: data
        })
      }
      if (personas?.telefonoResponsable) {
        appendIfDefined({
          key: 'telefonoResponsable',
          value: personas?.telefonoResponsable,
          formData: data
        })
      }
      if (personas?.duiParentesco) {
        appendIfDefined({ key: 'duiParentesco', value: personas?.duiParentesco, formData: data })
      }
      Alertas.loading_reload(true, 'Guardando persona')
      axios
        .POST('personas', data)
        .then((result) => {
          Alertas.loading_reload(false)

          if (result !== false) {
            if (result.status === 201) {
              toastAdapter.success({
                message: 'Persona guardada con éxito'
              })
              dispatch(
                clinicalApi.util.invalidateTags([PROVIDES_AND_INVALIDATE_TAGS.CLINICAL_RECORDS])
              )

              navigate('/persona')
            }
          } else {
            toastAdapter.error({
              message: 'Error al guardar registro'
            })
          }
        })
        .catch((error) => {
          toastAdapter.error({
            message: 'Error al guardar registro'
          })
        })
    }
  }

  const handlerImage = (image) => {
    setUpdateFoto(true)
    setSelectedFiles(image)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <LayoutForm
      tools={
        <Link to={'/persona'} className="btn btn-sm btn-light text-primary">
          <i className="fa fa-arrow-left" />
          Regresar a la lista de personas
        </Link>
      }
      title={'Registro de personas'}
    >
      <Suspense fallback={<Loader />}>
        <form className="container">
          <ProfilePictureUploader handler={handlerImage} imageUrl={selectedFile} />
          <section className="my-3 card shadow-none">
            <div className="card-header ">Información Personal</div>
            <div className="card-body p-2 ">
              <PersonasDatosPersonales
                onChangesPersonas={handleChangesPersona}
                estado={estado}
                genero={sexo}
                edad={CalcularEdad.calcularEdad(persona?.fechaNacimiento) + 'Años'}
                error={errors}
                register={register}
                dui={dui}
                responsable={personaResponsable}
                personasSet={setPersona}
                personaState={persona}
                onChangesNacePais={onChangesNacePais}
                onChangesDepartamento={onChangesNaceDepartamento}
                onChangesMunicipio={onChangesNaceMunicipio}
                onChangesCanton={onChangesNaceCanton}
                datosPais={apiPais}
                datosDepartamento={departamentos}
                datosMunicipio={municipios}
                datosCanton={canton}
                errorDui={errorDui}
                control={control}
              />
            </div>
          </section>
          <section className="my-3 card shadow-none">
            <div className="card-header  ">Información de Responsable</div>
            <div className="card-body p-2">
              <Responsable
                register={register}
                errror={errors}
                onChangesPersonas={handleChangesPersona}
                responsable={personaResponsable}
                personasSet={setPersona}
                personaState={persona}
                dui={dui}
                control={control}
              />
            </div>
          </section>
          <section className="my-3 card shadow-none">
            <div className="card-header  ">Información de Contacto</div>
            <div className="card-body p-2">
              <PersonaContactos personasSet={setPersona} persona={persona} />
            </div>
          </section>
          <section className="my-3 card shadow-none ">
            <div className="card-header">Datos de Residencia</div>
            <div className="card-body p-2">
              <PersonasResidencia
                datosPais={apiPais}
                onChangesPais={handlePais}
                // defaultValues={apiPais[60]}
                datosDepartamento={departamentos}
                onChangesDepartamento={handleDepartamento}
                datosMunicipio={municipios}
                onChangesMunicipio={handleMunicipio}
                datosCanton={canton}
                onChangesCanton={handleCanton}
                onChangesPersonas={handleChangesPersona}
                register={register}
                personasSet={setPersona}
                personaState={persona}
              />
            </div>
          </section>
          <section className="my-3 card shadow-none">
            <div className="card-header  ">Información Laboral</div>
            <div className="card-body p-2">
              <PersonasDatosNaci
                datosProfesion={profesion}
                onChangesPersonas={handleChangesPersona}
                personasSet={setPersona}
                persona={persona}
                register={register}
              />
            </div>
          </section>
          <section className="my-3 card shadow-none">
            <div className="card-header  ">Información Cliente</div>
            <div className="card-body p-2">
              <Cliente
                datosGrupoPersona={grupoPersonas}
                datosGrupoClientes={grupoCliente}
                datosNaturalezaCliente={naturalezaPersonas}
                onChangesPersonas={handleChangesPersona}
                personasSet={setPersona}
                persona={persona}
                register={register}
                datosSucursal={sucursal}
                control={control}
                errors={errors}
              />
            </div>
          </section>
          <footer className="card-footer text-center">
            <button
              className="btn btn-secondary btn-lg mr-2"
              onClick={() => {
                navigate('/persona')
              }}
              style={{ marginRight: '10px' }}
            >
              <i className="fa fa-arrow-circle-left"></i> SALIR
            </button>
            <button className="btn btn-primary btn-lg" onClick={handleSubmit(onSubmit)}>
              <i className="fa fa-save"></i> GUARDAR
            </button>
          </footer>
        </form>
      </Suspense>
    </LayoutForm>
  )
}
function objectValue(data, key = 'tipoDocumentoIdentidad') {
  return Object.entries(data?.tipoDocumentoIdentidad).length ? data?.[key] : null
}
